import { ArrowExitIcon, HeartIcon, PhoneIcon, EmailIcon, ThreePeopleIcon } from "../../img";
import { useLocation, useNavigate } from "react-router-dom";
import { useState } from "react";
import DialogContainer from "../../components/ui/dialog/DialogContainer";
import Dropdown from "../../components/ui/form/Dropdown/Dropdown";

interface IEmployeesContent {
    id: number,
    name: string,
    text: string,
    position: string,
    image: string,
    isLiked: boolean,
    number: string,
    email: string,
    birth: string,
    skills: any,
    leader: any,
    replacer: any,
}

export const EmployeesPage = () => {
    const isMobile = window.screen.width < 1020;
    const isMobileSecond = window.screen.width < 570
    const location = useLocation();
    const navigate = useNavigate();

    const [ dialog, setDialog ] = useState(false)
    const [ activePerson, setActivePerson ] = useState<IEmployeesContent>()

    const [ employeesContent, setEmployeesContent ] = useState<IEmployeesContent[]>([
        {
            id: 1,
            name: 'Воробьёв Илья Романович',
            text: 'Департамент испытания и сертификации',
            position: 'Руководитель отдела',
            image: '/testImages/people/Image-1.png',
            isLiked: true,
            birth: '15 мая, 2024',
            number: '+7 925 353-01-10',
            email: 'ivanov@itelma.ru',
            skills: [ 'Анализирует требования заказчика', 'Разрабатывает тесты', 'Коммуникация с проектной командой' ],
            leader: {
                image: '/testImages/people/Image-2.png',
                name: 'Иванов Иван Иванович',
                number: '+7 925 353-01-10',
                email: 'ivanov@itelma.ru',
            },
            replacer: {
                image: '/testImages/people/Image-3.png',
                name: 'Иванов Иван Иванович',
                number: '+7 925 353-01-10',
                email: 'ivanov@itelma.ru',
            }
        },
        {
            id: 2,
            name: 'Кузьмин Дмитрий Константинович',
            text: 'Департамент испытания и сертификации',
            position: 'Менеджер по продажам',
            image: '/testImages/people/Image-2.png',
            isLiked: false,
            birth: '15 мая, 2024',
            number: '+7 925 353-01-10',
            email: 'ivanov@itelma.ru',
            skills: [ 'Анализирует требования заказчика', 'Разрабатывает тесты', 'Коммуникация с проектной командой' ],
            leader: {
                image: '/testImages/people/Image-2.png',
                name: 'Иванов Иван Иванович',
                number: '+7 925 353-01-10',
                email: 'ivanov@itelma.ru',
            },
            replacer: {
                image: '/testImages/people/Image-3.png',
                name: 'Иванов Иван Иванович',
                number: '+7 925 353-01-10',
                email: 'ivanov@itelma.ru',
            }
        },
        {
            id: 3,
            name: 'Белов Дмитрий Константинович',
            text: 'Департамент испытания и сертификации',
            position: 'Менеджер по продажам',
            image: '/testImages/people/Image-3.png',
            isLiked: false,
            birth: '15 мая, 2024',
            number: '+7 925 353-01-10',
            email: 'ivanov@itelma.ru',
            skills: [ 'Анализирует требования заказчика', 'Разрабатывает тесты', 'Коммуникация с проектной командой' ],
            leader: {
                image: '/testImages/people/Image-2.png',
                name: 'Иванов Иван Иванович',
                number: '+7 925 353-01-10',
                email: 'ivanov@itelma.ru',
            },
            replacer: {
                image: '/testImages/people/Image-3.png',
                name: 'Иванов Иван Иванович',
                number: '+7 925 353-01-10',
                email: 'ivanov@itelma.ru',
            }
        },
        {
            id: 4,
            name: 'Воробьёв Евгений Петрович',
            text: 'Департамент испытания и сертификации',
            position: 'Менеджер по продажам',
            image: '/testImages/people/Image-4.png',
            isLiked: false,
            birth: '15 мая, 2024',
            number: '+7 925 353-01-10',
            email: 'ivanov@itelma.ru',
            skills: [ 'Анализирует требования заказчика', 'Разрабатывает тесты', 'Коммуникация с проектной командой' ],
            leader: {
                image: '/testImages/people/Image-2.png',
                name: 'Иванов Иван Иванович',
                number: '+7 925 353-01-10',
                email: 'ivanov@itelma.ru',
            },
            replacer: {
                image: '/testImages/people/Image-3.png',
                name: 'Иванов Иван Иванович',
                number: '+7 925 353-01-10',
                email: 'ivanov@itelma.ru',
            }
        },
        {
            id: 5,
            name: 'Сергеев Борис Антонович',
            text: 'Департамент испытания и сертификации',
            position: 'Менеджер по продажам',
            image: '/testImages/people/Image-5.png',
            isLiked: false,
            birth: '15 мая, 2024',
            number: '+7 925 353-01-10',
            email: 'ivanov@itelma.ru',
            skills: [ 'Анализирует требования заказчика', 'Разрабатывает тесты', 'Коммуникация с проектной командой' ],
            leader: {
                image: '/testImages/people/Image-2.png',
                name: 'Иванов Иван Иванович',
                number: '+7 925 353-01-10',
                email: 'ivanov@itelma.ru',
            },
            replacer: {
                image: '/testImages/people/Image-3.png',
                name: 'Иванов Иван Иванович',
                number: '+7 925 353-01-10',
                email: 'ivanov@itelma.ru',
            }
        }
    ])

    const handleLike = (id: number) => {
        const updatedList: any = employeesContent.map((item) => {
            if (item.id === id) {
                return {
                    ...item,
                    isLiked: !item.isLiked
                }
            } else {
                return item
            }
        })

        const getActivePerson = updatedList.find((item: IEmployeesContent) => item.id === id)

        setEmployeesContent(updatedList)
        setActivePerson(getActivePerson)
    };

    const handleNavigation = () => {
        const pathArray = location.pathname.split('/');
        if (pathArray.length > 1) {
            pathArray.pop();
            const newPath = pathArray.join('/') || '/';
            navigate(newPath);
        } else {
            navigate('/');
        }
    };

    return (
        <div className='employees'>
            <div className='layout-inner__header birthdays-header'>
                { !isMobile && (
                    <div className='layout-inner__header__title'>
                        <div className='header__exitIcon' onClick={ handleNavigation }>
                            <ArrowExitIcon />
                        </div>
                        <h1>Новые сотрудники</h1>
                    </div>
                ) }

                <div className='employees-header__right'>
                    <Dropdown
                        // className='workspace-select'
                        placeholder={ 'Показать все' }
                        options={ [ {value: 1, label: 'fdsfds'}, {value: 2, label: 'fdsfgdgfsd'} ] }
                        onChange={ () => '' }
                    />

                    { !isMobileSecond && (
                        <div className='employees-header__structure'>
                            <ThreePeopleIcon />
                            <p>Структура компании</p>
                        </div>
                    ) }
                </div>
            </div>

            <div className='employees-content'>
                { employeesContent.map((
                    item,
                    idx
                ) => (
                    <div className='employees-block' key={ idx } >
                        <div className='employees-block__top'>
                            <img className='employees-block__image' src={ item?.image } alt="" />

                            <div
                                onClick={ () => {
                                    handleLike(item.id)
                                } }
                                className={ `employees-block__likeIcon ${ item.isLiked ? 'active' : '' }` }
                            >
                                <HeartIcon />
                            </div>
                        </div>

                        <div className='employees-block__content' onClick={ () => {
                            setDialog(true)
                            setActivePerson({...item})
                        } }>
                            <h1 className='employees-block__title'>{ item?.name }</h1>

                            <p className='employees-block__text'>{ item?.text }</p>

                            <p className='employees-block__position'>{ item?.position }</p>
                        </div>
                    </div>
                )) }
            </div>

            { isMobileSecond && (
                <div className='employees-header__structure'>
                    <ThreePeopleIcon />
                    <p>Структура компанииasd</p>
                </div>
            ) }

            { activePerson && (
                <DialogContainer
                    isOpen={ dialog }
                    closeModal={ () => setDialog(false) }
                >
                    <div className='employees-modal'>
                        <div className='employees-modal__top'>
                            <img src={ activePerson?.image } alt="" />

                            <h1>
                                { activePerson?.name }
                            </h1>
                        </div>

                        <div className='employees-modal__contacts'>
                            <div className='employees-modal__contacts__block'>
                                <PhoneIcon />
                                <p>{ activePerson?.number }</p>
                            </div>

                            <div className='employees-modal__contacts__block'>
                                <EmailIcon />
                                <p>{ activePerson?.email }</p>
                            </div>
                        </div>

                        <div className='employees-modal__content'>
                            <div className='employees-modal__content__block'>
                                <p className='employees-modal__content__placeholder'>Департамент</p>
                                <h1 className='employees-modal__content__value'>{ activePerson?.text }</h1>
                            </div>

                            <div className='employees-modal__content__block'>
                                <p className='employees-modal__content__placeholder'>Должность</p>
                                <h1 className='employees-modal__content__value'>{ activePerson?.position }</h1>
                            </div>

                            <div className='employees-modal__content__block'>
                                <p className='employees-modal__content__placeholder'>Дата рождения</p>
                                <h1 className='employees-modal__content__value'>{ activePerson?.birth }</h1>
                            </div>

                            <div className='employees-modal__content__block'>
                                <p className='employees-modal__content__placeholder'>Навыки</p>

                                <div className={ 'colleagues-modal__content__list' }>
                                    { activePerson?.skills.map((item: string) => (
                                        <div className='employees-modal__content__list__block'>
                                            <span></span>
                                            <p>{ item }</p>
                                        </div>
                                    )) }
                                </div>
                            </div>
                        </div>

                        <div
                            onClick={ () => handleLike(activePerson?.id) }
                            className={ `employees-modal__button ${ activePerson?.isLiked ? 'active' : '' }` }>
                            <div
                                className={ `employees-block__likeIcon 
                                ${ activePerson?.isLiked ? 'active' : '' }`
                                }
                            >
                                <HeartIcon />
                            </div>

                            { activePerson?.isLiked ? (
                                <p>
                                    В избранном
                                </p>
                            ) : (
                                <p>
                                    Добавить в избранные
                                </p>
                            ) }
                        </div>

                        <div className='employees-modal__people'>
                            <div className='employees-modal__people__content'>
                                <p className='employees-modal__content__placeholder'>Руководитель</p>

                                <div className='employees-modal__people__block'>
                                    <img src={ activePerson.leader.image } alt="" />

                                    <div className='employees-modal__people__block__content'>
                                        <h1>{ activePerson.leader.name }</h1>

                                        <div>
                                            <PhoneIcon />

                                            <p>{ activePerson.leader.number }</p>
                                        </div>

                                        <div>
                                            <EmailIcon />

                                            <p>{ activePerson.leader.email }</p>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className='employees-modal__people__content'>
                                <p className='employees-modal__content__placeholder'>Замещающий</p>

                                <div className='employees-modal__people__block'>
                                    <img src={ activePerson.replacer.image } alt="" />

                                    <div className='employees-modal__people__block__content'>
                                        <h1>{ activePerson.replacer.name }</h1>

                                        <div>
                                            <PhoneIcon />

                                            <p>{ activePerson.replacer.number }</p>
                                        </div>

                                        <div>
                                            <EmailIcon />

                                            <p>{ activePerson.replacer.email }</p>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </DialogContainer>
            ) }
        </div>
    )
}