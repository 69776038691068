import api from '../../../api'
import { IApiHandlerParams } from '../../interface'

export const $getVacationUsers = (
    isAdmin: boolean,
    data: string,
    handler?: IApiHandlerParams
): Promise<any> => api
    .get(isAdmin ? `backend/v1/vacations/booking/users${data}` : `api/v1/booking/vacations/users${data}`, {
        mock: require('./mocks/vacation.users.json'),
        handler,
    })

export const $getVacationBooking = (
    isAdmin: boolean,
    data: string,
    handler?: IApiHandlerParams
): Promise<any> => api
    .get(isAdmin ? `backend/v1/vacations/booking${data}` : `api/v1/booking/vacations${data}`, {
        mock: require('./mocks/vacation.booking.json'),
        handler,
    })

export const $getVacationBookingUsers = (
    data: string,
    handler?: IApiHandlerParams
): Promise<any> => api
    .get("backend/v1/vacations/booking/users", {
        mock: require('./mocks/vacation.search.users.json'),
        request: { params: data },
        handler,
    })

export const $getBookingSettings = (
    handler?: IApiHandlerParams
): Promise<any> => api
    .get("backend/v1/booking/settings", {
        mock: require('./mocks/vacation.settings.booking.json'),
        handler,
    })

export const $getVacationsSettings = (
    handler?: IApiHandlerParams
): Promise<any> => api
    .get("backend/v1/vacations/settings", {
        mock: require('./mocks/vacation.settings.vacation.json'),
        handler,
    })

export const $bookingCreate = (
    isAdmin: boolean,
    isSpecial: boolean,
    data: any,
    handler?: IApiHandlerParams
): Promise<any> => api
.post(isAdmin ? 'backend/v1/vacations/booking' : `api/v1/booking/vacations${isSpecial ? '/special' : ''}`, data,{
    // mock: require('./mocks/vacation.booking.json'),
    handler,
})

export const $bookingEdit = (
    id: string,
    data: any,
    handler?: IApiHandlerParams
): Promise<any> => api
.put(`backend/v1/vacations/booking/${id}`, data,{
    // mock: require('./mocks/vacation.booking.json'),
    handler,
})

export const $bookingCancel = (
    id: string,
    handler?: IApiHandlerParams
): Promise<any> => api
.put(`api/v1/booking/vacations/${id}/cancel`, null,{
    // mock: require('./mocks/vacation.booking.json'),
    handler,
})

export const $bookingDelete = (
    id: string,
    handler?: IApiHandlerParams
): Promise<any> => api
.delete(`backend/v1/vacations/booking/${id}`,{
    // mock: require('./mocks/vacation.booking.json'),
    handler,
})

export const $bookingConciliate = (
    isAdmin: boolean,
    id: string,
    data: any,
    handler?: IApiHandlerParams
): Promise<any> => api
.put(isAdmin ? `backend/v1/vacations/booking/${id}/conciliate` : `api/v1/booking/vacations/${id}/conciliate`, data,{
    // mock: require('./mocks/vacation.booking.json'),
    handler,
})

export const $additionalGet = (
    data: string,
    handler?: IApiHandlerParams
): Promise<any> => api
.get(`api/v1/booking/vacations/additional-days?${data}`,{
    // mock: require('./mocks/vacation.booking.json'),
    handler,
})

export const $staffGet = (
    handler?: IApiHandlerParams
): Promise<any> => api
.get("api/v1/staff",{
    // mock: require('./mocks/vacation.booking.json'),
    handler,
})

export const $bookingGet = (
    url: string,
    data: string,
    handler?: IApiHandlerParams
): Promise<any> => api
.get(url + "?" + data,{
    // mock: require('./mocks/vacation.booking.json'),
    handler,
})

export const $usersGet = (
    url: string,
    data: string,
    handler?: IApiHandlerParams
): Promise<any> => api
.get(url + "?" + data,{
    // mock: require('./mocks/vacation.booking.json'),
    handler,
})

export const $conciliatorsGet = (
    id: string,
    handler?: IApiHandlerParams
): Promise<any> => api
.get(`api/v1/booking/vacations/${id}/conciliators`,{
    // mock: require('./mocks/vacation.booking.json'),
    handler,
})