import { useDispatch } from "react-redux";
import { useEffect, useState } from "react";
import { updateRouteTitle } from "../../../redux";
import { $getBlogs } from "../../../api/requests/blogs";
import { useLocation, useNavigate } from "react-router-dom";
import { ArrowExitIcon, CommentIcon, EyeIcon, HeartIcon, LikeIcon, ShareIcon } from "../../../img";
import { ClockIcon } from "@mui/x-date-pickers";
import { BlogsShowPageCard } from "../../../components/blogs";
import { IBlogsRes } from "../../../api/requests/blogs/interface";
import { CommentMessenger } from "../../../components/comment-messenger";

export const BlogsShowPage = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);

    const isMobile = window.screen.width < 1020;

    const dispatch = useDispatch();

    const blogs_id = searchParams.get('blogs_id');

    const [blogsList, setBlogsList] = useState<IBlogsRes[]>([])
    const [ blogData, setBlogData ] = useState<IBlogsRes>()

    const rightData = blogsList.filter((item: any) => item.id != blogs_id)

    const handleNavigation = () => {
        const pathArray = location.pathname.split('/');
        if (pathArray.length > 1) {
            pathArray.pop();
            const newPath = pathArray.join('/') || '/';
            navigate(newPath);
        } else {
            navigate('/');
        }
    }

    useEffect(() => {
        $getBlogs().then((res) => {
            if (!res.data) return;
            const getBlogItem = res.data.find((item: any) => item.id == blogs_id)

            setBlogsList(res.data)
            setBlogData(getBlogItem)
        })
    }, [blogs_id])

    useEffect(() => {
        dispatch(updateRouteTitle({key: 'blogs_show', title: blogData?.title}));
    }, [ blogData ])

    return (
        <div className='blogs-show'>
            <div className='layout-inner__header profile-header'>
                { !isMobile && (
                    <div className='layout-inner__header__title'>
                        <div className='header__exitIcon' onClick={ handleNavigation }>
                            <ArrowExitIcon />
                        </div>
                        <h1>{ blogData?.title }</h1>
                    </div>
                ) }
            </div>

            {blogData ? (
                <div className='blogs-show-content'>
                    <div className='news-show-content__left'>
                        <div className='news-show-info'>
                            <div className='news-show-info__image'
                                 style={ {backgroundImage: `url(${ blogData.image })`} } />

                            <div className='blogs-show-info__content'>
                                <div className='blogs-show-info__header'>
                                    <div className='news-show-info__header__practice'>{blogData?.category}</div>

                                    <div className={ `blogs-card-block-content__likeIcon ${ blogData.is_liked ? 'active' : '' }` }
                                    >
                                        <HeartIcon />
                                    </div>
                                </div>

                                <div className='blogs-show-info__editor'>
                                    <p>
                                        {blogData?.text}
                                    </p>
                                </div>

                                <div className='blogs-show-info__date'>
                                    <ClockIcon/> <p>20 октября, 2020 12:44</p>
                                </div>

                                <div className='blogs-show-info__bottom'>
                                    <div className='blogs-show-info__buttons'>
                                        <button className='blogs-show-info__buttons__likeButton'>
                                            <LikeIcon size={24} color='#00A7B5'/> <p>Мне нравиться</p>
                                        </button>

                                        <button className='blogs-show-info__buttons__shareButton'>
                                            <ShareIcon size={24} color='#00A7B5'/> <p>Мне нравиться</p>
                                        </button>
                                    </div>

                                    <div className='blogs-show-info__stats'>
                                        <div className='blogs-show-info__stats__block'>
                                            <LikeIcon color='#000' size={20}/>

                                            <p>{blogData.likes_count}</p>
                                        </div>

                                        <div className='blogs-show-info__stats__block'>
                                            <CommentIcon color='#000' size={20}/>

                                            <p>{blogData.comments_count}</p>
                                        </div>

                                        <div className='blogs-show-info__stats__block'>
                                            <EyeIcon color='#000' size={20}/>

                                            <p>{blogData.views_count}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        {blogData?.comments_array ? <CommentMessenger comments_data={blogData?.comments_array}/> : null}
                    </div>

                    <div className='blogs-show-content__right'>
                        { rightData.slice(0, 4).map((item, idx) => (
                            <div  onClick={ () => navigate(`/blogs/show?blogs_id=${ item.id }`) } key={idx}>
                                <BlogsShowPageCard data={ item } />
                            </div>
                        )) }
                    </div>
                </div>
            ): null}
        </div>
    )
}