const CheckDropdownIcon = () => {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none"
             xmlns="http://www.w3.org/2000/svg">
            <rect
                x="1"
                y="1"
                width="22"
                height="22"
                rx="11"
                stroke="#00A7B5"
                strokeOpacity="0.2"
                strokeWidth="2"
            />
            <path
                d="M16.7992 8.3999L9.6397 15.5999L7.19922 13.1456"
                stroke="#00A7B5"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
};

export default CheckDropdownIcon;
