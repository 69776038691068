import { FC } from "react";
import NewsDateIcon from "../../img/icons/news/NewsDateIcon";
import {
    CommentIcon,
    EyeIcon,
    HeartIcon,
    LikeIcon,
    PaperClipIcon,
    ThreePeopleIcon
} from "../../img";
import TextOverflow from "../../utils/text-overflow/TextOverflow";
import { ContestItem } from "../../api/requests/contest/interface";

interface ICompetitionsCard {
    isLikeIcon?: boolean;
    data: ContestItem;
    handleOpenItem?: (data: ContestItem) => void
}

export const CompetitionsCard: FC<ICompetitionsCard> = (
    {
        isLikeIcon = false,
        data,
        handleOpenItem,
    }
) => {
    const isMobile = window.screen.width <= 600;
    const {
        title,
        image,
        category_title,
        date,
        views,
        likes_count,
        comments_count,
        isLiked,
        clip,
        status
    } = data;

    const statusList = [
        {
            key: "active",
            value: "Активный"
        },
        {
            key: "finish",
            value: "Завершенный"
        },
        {
            key: "hide",
            value: "Скрытый"
        }
    ];

    const statusFind = statusList.find(item => item.key === (status || ""));

    return (
        <div className={`feed-news-card`} onClick={() => handleOpenItem && handleOpenItem(data)}>
            <div className={"feed-news-card__img"}>
                <img src={image} />

                {clip && (
                    <div className={"feed-news-card__clip"}>
                        <PaperClipIcon color={"#FFFFFF"} />
                    </div>
                )}

                {status && (
                    <div className={`feed-news-card__active ${statusFind?.key}`}>
                        <div className={`${statusFind?.key}`}></div>

                        <p>{statusFind?.value || "Неопределен"}</p>
                    </div>
                )}
            </div>

            <div className="feed-news-card__infoBox">
                {isLikeIcon ? (
                        <div className="feed-news-card__infoBox__top">
                            <h5>{TextOverflow({ title, lines: 3 })}</h5>

                            <div
                                // onClick={ () => {
                                //     handleLike(item.id)
                                // } }
                                className={`employees-block__likeIcon ${isLiked ? "active" : ""}`}
                            >
                                <HeartIcon />
                            </div>
                        </div>) :
                    <h5>{TextOverflow({ title, lines: 3 })}</h5>
                }

                {category_title ?
                    <div className={`feed-news-card__catTitleStyle`}>{category_title}</div> : null}

                <div className="feed-news-card__content">
                    <div className="feed-news-card__dateBox">
                        <NewsDateIcon
                            size={"1.25rem"}
                            style={{
                                marginRight: isMobile ? "0.375rem" : "0.5rem",
                                minWidth: "1.25rem"
                            }}
                        />

                        <div>До {date}</div>
                    </div>

                    <div className="feed-news-card__stats">
                        <div className="feed-news-card__stats__block">
                            <EyeIcon />

                            <h1>{views}</h1>
                        </div>

                        <div className="feed-news-card__stats__block">
                            <ThreePeopleIcon />

                            <h1>{likes_count}</h1>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};