export const ClockIcon = (
    {
        color = "black"
    }
) => {
    return (
        <svg width="18" height="18" viewBox="0 0 18 18" fill="none"
             xmlns="http://www.w3.org/2000/svg">
            <g opacity="0.2">
                <path
                    d="M9 1.5C4.86675 1.5 1.5 4.86675 1.5 9C1.5 13.1333 4.86675 16.5 9 16.5C13.1333 16.5 16.5 13.1333 16.5 9C16.5 4.86675 13.1333 1.5 9 1.5ZM9 3C12.3226 3 15 5.67741 15 9C15 12.3226 12.3226 15 9 15C5.67741 15 3 12.3226 3 9C3 5.67741 5.67741 3 9 3ZM8.25 4.5V9.31055L11.4697 12.5303L12.5303 11.4697L9.75 8.68945V4.5H8.25Z"
                    fill={color}
                />
            </g>
        </svg>
    )
}