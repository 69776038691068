import { FC } from "react";
import { IPolls, IPolls2 } from "../../../api/requests/polls/interface";
import noImageBanner from "../../../img/noimage.png";
import Button from "../../ui/form/Buttons/Button";
import { ShareIcon } from "../../../img/icons/ShareIcon";
import { CoinCompetitionIcon } from "../../../img";

interface CreatePollFormSubmit {
    image: File | string | null;
    title: string;
    text: string;
    category: any;
    clip: boolean;
    date_start: string;
    date_end: string;
    desc: string;
    answers: any,
}

interface IPollsPreviewPage {
    data: IPolls2
    handleSubmit?: () => void;
    setValue: any,
}
export const PollsPageView:FC< IPollsPreviewPage> = ({
    data,
    handleSubmit,
    setValue
}) => {
    const handleChangeAnswersValue = (id: number, value: boolean) => {
        const updatedList = data.answers.map((item: any, idx: number) =>
            id === idx ? {...item, value} : {...item, value: false}
        )

        setValue('answers', updatedList)
    }

    return (
        <div className='polls-create-preview__content'>
            <div className="polls-create-preview__img">
                <img src={data?.image || noImageBanner} />
            </div>

            <div className='polls-create-preview__desc'>
                <div className={"d-flex align-items-center gap-3"}>
                    <h3 style={{fontSize: data.title ? '' : '2rem'}} className='polls-create-preview__title'>{data.title || "Название"}</h3>
                </div>

                <div className='polls-create-preview__answers'>
                    {data.answers && (
                        <>
                            {data?.answers.map((item: any, idx: number) => (
                                <div className='polls-modal-question__block' key={ idx }>
                                    <input
                                        type="checkbox"
                                        checked={ item.value }
                                        onChange={ () => handleChangeAnswersValue(idx, !item.value) }
                                    />

                                    <p>{ item.title }</p>
                                </div>
                            ))}
                        </>
                    )}
                </div>

                <div className='polls-create-preview__point'>
                    <h1 className='polls-create-preview__point__title'>Награда:</h1>

                    <div className='polls-create-preview__point__stats'>
                        <CoinCompetitionIcon/>

                        <p>{data.reward_point}</p>
                    </div>
                </div>

                <div className='polls-create-preview__button'>
                    Ответить
                </div>
            </div>
        </div>
    )
}