import { useState } from "react";
import MainLayoutContent from "../../layouts/MainLayoutContent";
import { BadgesCard, ProfileCard, StatsCard, EmployeesCard } from "../../components/layout-components";
import { PlusIcon2 } from "../../img";
import Dropdown from "../../components/ui/form/Dropdown/Dropdown";
import { useNavigate } from "react-router-dom";
import { isMd } from "../../utils";

export const CalendarPage = () => {
    const navigate = useNavigate();

    const isMobile = isMd();

    const [data, setData] = useState([
        {
            id: 1,
            title: 'Бронирование отпуска',
            image:  '/testImages/vacation/vacation_image.png',
            status: 'Отпуск',
            page: 'vacation'
        },
        {
            id: 2,
            title: 'Индивидуальные обстоятельства',
            image:  '/testImages/vacation/vacation_image.png',
            status: 'Отпуск',
            page: ''
        },
        {
            id: 3,
            title: 'Индивидуальные обстоятельства',
            image: '/testImages/vacation/vacation_image.png',
            status: 'Отпуск',
            page: ''
        },
    ])

    return (
        <MainLayoutContent
            leftComponents={
                <>
                    <ProfileCard/>
                    <StatsCard/>
                    <EmployeesCard/>
                    <BadgesCard/>
                </>
            }
        >
            <div className='vacation'>
                <div className='vacation-header'>
                    <h1>Календарь</h1>

                    <div className='vacation-buttons'>
                        <Dropdown
                            className='workspace-select'
                            placeholder={'Выбрать город'}
                            options={[]}
                            onChange={() => ''}
                        />

                        {!isMobile && (
                            <div className='vacation-addButton'>
                                <PlusIcon2 color='#5900FF'/>
                                <p>Создать</p>
                            </div>
                        )}
                    </div>
                </div>

                <div className='vacation-content'>
                    {data.map((item) => (
                        <div key={item.id} className='vacation-block' onClick={() => navigate(item.page)}>
                            <img className='vacation-image' src={item.image} alt="#" />

                            <h3>{item.title}</h3>

                            <div className='vacation-seperator'></div>

                            <div className='vacation-status'>{item.status}</div>
                        </div>
                    ))}
                </div>

                {isMobile && (
                    <div className='vacation-addButton'>
                        <PlusIcon2/>
                        <p>Создать</p>
                    </div>
                )}
            </div>

        </MainLayoutContent>
    )
}