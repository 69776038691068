import DialogContainer from "../../../components/ui/dialog/DialogContainer";
import Button from "../../../components/ui/form/Buttons/Button";
import SuccessMessage from "../../../img/success_message.png"

interface ContestsPageSuccessProps {
    isOpen: boolean;
    onClose: () => void;
}

const ContestsPageSuccess = (
    {
        isOpen,
        onClose,
    }: ContestsPageSuccessProps
) => {

    return (
        <DialogContainer
            isOpen={isOpen}
            setIsOpen={onClose}
            isShowCloseBtn={false}
            widthProps={428}
        >
            <div className={"contests__success"}>
                <img src={SuccessMessage}/>

                <div className={"contests__success__text"}>
                    <h4>Отправлено</h4>

                    <p>Ваша заявка была отправлена, ожидайте уведомление от администратора</p>
                </div>

                <Button
                    text={"Спасибо"}
                    className={"btn btn-light w-100"}
                    onClick={onClose}
                />
            </div>
        </DialogContainer>
    )
}

export default ContestsPageSuccess;