import { IApiHandlerParams } from "../../interface"
import api from '../../../api'
import { IGetNewsItem } from "./interface"

export const $getFavoritesNews = (
    handler?: IApiHandlerParams
): Promise<any> => api
    .get( `api/v1/vacations/news`, {
        mock: require('./mocks/favorites.news.json'),
        handler,
    })

export const $getNewsList = (
    data: any,
    handler?: IApiHandlerParams
): Promise<any> =>
    api.post('api/news/listnews', data, {
        handler,
    })
export const $getNewsItem = (
    id: any,
    handler?: IApiHandlerParams
): Promise<IGetNewsItem> =>
    api.post('api/news/viewnews', id, {
        handler,
    })
