import { FC, useEffect, useRef, useState } from "react";
import {
    BadgesCard,
    ProfileCard,
    StatsCard,
    VacationCard,
    CongratulatedCard,
    FavoritesCard, StatusCard, MobileVacationCard,
} from "../../components/layout-components";
import {
    DepartmentBlock,
    ServicesBlock,
    PersonalWorkBlock,
    SportBlock,
} from "../../components/profile";
import { ArrowExitIcon, ArrowRightIcon, PlusIcon2, ThreePeopleIcon } from "../../img";
import MainLayoutContent from "../../layouts/MainLayoutContent";
import { useNavigate } from "react-router-dom";

export const ProfilePage: FC = () => {
    const isAdmin = true
    const isMobile = window.screen.width < 1020
    const navigate = useNavigate()

    const scrollableRef: any = useRef(null);

    const [ departmentItems, setDepartmentItems ] = useState([
        {
            id: 1,
            image: "/testImages/people/Image-1.png",
            name: "Краюшкин Александр Витальевич",
            position: "Руководитель",
            icon: "/testImages/statuses/statuses-1.png",
            department: 'Департамент испытания и сертификации'
        },
        {
            id: 2,
            image: "/testImages/people/Image-2.png",
            name: "Смирнова Виалета Михайловна",
            position: "HR менеджер",
            icon: "/testImages/statuses/statuses-2.png",
            department: 'Департамент испытания и сертификации'
        },
        {
            id: 3,
            image: "/testImages/people/Image-3.png",
            name: "Смирнова Виалета Михайловна",
            position: "HR менеджер",
            icon: "/testImages/statuses/statuses-3.png",
            department: 'Департамент испытания и сертификации'
        },
        {
            id: 4,
            image: "/testImages/people/Image-4.png",
            name: "Смирнова Виалета Михайловна",
            position: "HR менеджер",
            icon: "/testImages/statuses/statuses-1.png",
            department: 'Департамент испытания и сертификации'
        },
        {
            id: 5,
            image: "/testImages/people/Image-5.png",
            name: "Смирнова Виалета Михайловна",
            position: "HR менеджер",
            icon: "/testImages/statuses/statuses-2.png",
            department: 'Департамент испытания и сертификации'
        },
    ]);


    const [sportData, setSportData] = useState([
        {
            id: 1,
            weekDay: 'Вторник',
            month: 'Июля',
            day: 13,
            type: 'Тренировка',
            text: 'Бег на дорожке',
            startTime: '17:30',
            endTime: '19:00',
            isMuted: true,
        },
        {
            id: 2,
            weekDay: 'Вторник',
            month: 'Июля',
            day: 13,
            type: 'Тренировка',
            text: 'Бег на дорожке',
            startTime: '17:30',
            endTime: '19:00',
            isMuted: false,
        },
        {
            id: 3,
            weekDay: 'Вторник',
            month: 'Июля',
            day: 13,
            type: 'Тренировка',
            text: 'Бег на дорожке',
            startTime: '17:30',
            endTime: '19:00',
            isMuted: true,
        },
    ])

    const handleNavigation = () => {
        const pathArray = location.pathname.split('/');
        if (pathArray.length > 1) {
            pathArray.pop();
            const newPath = pathArray.join('/') || '/';
            navigate(newPath);
        } else {
            navigate('/');
        }
    };

    return (
        <div className='profile'>
            <div className='layout-inner__header profile-header'>
                { !isMobile && (
                    <div className='layout-inner__header__title'>
                        <div className='header__exitIcon' onClick={ handleNavigation }>
                            <ArrowExitIcon />
                        </div>
                        <h1>Моя страница</h1>
                    </div>
                ) }
            </div>

            <MainLayoutContent
                contentGap={ 40 }
                leftComponents={
                    <>
                        <ProfileCard />
                        <StatsCard />
                        <StatusCard/>
                        <BadgesCard />
                        <CongratulatedCard/>

                        <div onClick={() => navigate('/favorites')}>
                            <FavoritesCard/>
                        </div>

                        {isMobile ? <MobileVacationCard data={departmentItems}/> :  <VacationCard data={departmentItems}/>}
                    </>
                }
            >
                <div className="layout-right-block">
                    <div className="layout-right-block__header">
                        <h1 className="layout-right-block__header__title"> Моё подразделение </h1>

                        <div className='layout-right-block__header__link'>
                            <h4>Показать все</h4>
                            <ArrowRightIcon color={ "#000" } />
                        </div>
                    </div>

                    <div className="profile-departments__content">
                        { departmentItems.slice(0, 5).map((
                            item,
                            idx
                        ) => (
                            <DepartmentBlock key={ idx } { ...item } isMessage />
                        )) }
                    </div>
                </div>

                <div className="layout-right-block">
                    <div className="layout-right-block__header">
                        <h1 className="layout-right-block__header__title"> Моя работа </h1>
                    </div>

                    <PersonalWorkBlock />
                </div>

                <div className='layout-right-block'>
                    <div className='layout-right-block__header'>
                        <h1 className='layout-right-block__header__title '> Мои сервисы </h1>

                        <div className='layout-right-block__header__right'>
                            {isAdmin && !isMobile && (
                                <button onClick={() => navigate('/profile/colleagues')} className='layout-right-block__header__addButton profile-services-addButton'>
                                    <ThreePeopleIcon />

                                    <p>Мои сотрудники</p>

                                    <span>
                                    3
                                </span>
                                </button>
                            )}

                            <button className='layout-right-block__header__addButton profile-services-addButton'>
                                <PlusIcon2 />

                                <p>Подать новую</p>
                            </button>
                        </div>
                    </div>

                    <ServicesBlock />
                </div>

                <div className='layout-right-block'>
                    <div className='layout-right-block__header'>
                        <h1 className='layout-right-block__header__title '> Спорт </h1>

                        <button className='layout-right-block__header__addButton'>
                            <PlusIcon2 />

                            <p>Записаться</p>
                        </button>
                    </div>

                    <div className='profile-sport'>
                        {sportData.map((item, idx) => (
                            <SportBlock key={idx} {...item}/>
                        ))}
                    </div>
                </div>
            </MainLayoutContent>
        </div>

    );
};