import { useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import { $getVacationBookingUsers } from "../../../../../../api/requests/vacation";
import { getCity, getIsAdmin } from "../../../../../../redux";
import TextField from "../../../../../ui/form/Fields/TextField";
import { SearchIcon } from "../../../../../../img";

interface MembersSearchData {
    usersData: any;
    setUsersData: (value: any) => void;
    isOpen: boolean;
    setMembers: (value: any) => void;
}

export const MembersSearch = (
    {
        usersData,
        setUsersData,
        isOpen,
        setMembers
    }: MembersSearchData
) => {
    const inputRef = useRef<any>(null);

    const city = useSelector(getCity);
    const isAdmin = useSelector(getIsAdmin);

    const [search, setSearch] = usersData("");

    useEffect(() => {
        if (isOpen) {
            inputRef?.current?.focus();
        }
    }, [isOpen]);

    useEffect(() => {
        let timeout: any;

        if (search) {
            timeout = setTimeout(() => {
                const requestData = isAdmin && city ? `city_id=${city}&search=${search}` : `search=${search}`;

                $getVacationBookingUsers(requestData).then(res => {
                    setUsersData(res);
                });
            }, 1000);
        } else {
            setMembers([]);
            setUsersData([]);
        }

        return () => clearTimeout(timeout);
    }, [search]);

    useEffect(() => {
        if (usersData.length > 0) {
            setMembers(usersData);
        }
    }, []);

    return (
        <div className={"calendar__members__search__wrapper"}>
            <div className={"calendar__members__search__field"}>
                <div className={"calendar__members__search__icon"}>
                    <SearchIcon />
                </div>

                <TextField
                    ref={inputRef}
                    value={search}
                    className={"calendar__members__search__input"}
                    placeholder="Поиск"
                    onChange={setSearch}
                />
            </div>
        </div>
    );
};
