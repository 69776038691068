export const ShareIcon = (
    {
        color = "#00A7B5"
    }
) => {
    return (
        <svg width="24" height="25" viewBox="0 0 24 25" fill="none"
             xmlns="http://www.w3.org/2000/svg">
            <path
                d="M21.0002 11.9591L11.4002 5.6001L11.4002 9.2001C3 11.0001 3 19.4001 3 19.4001C3 19.4001 6.6 14.6001 11.4002 15.2001L11.4002 18.9201L21.0002 11.9591Z"
                stroke={color}
                strokeWidth="2"
                strokeLinejoin="round"
            />
        </svg>
    )
}