import { FC, ReactNode } from "react";

interface ToggleBlockForms {
    desc: any;
    isChecked: boolean;
    handleChange: (is: boolean) => void;
    imgLeft?: ReactNode | null;
    required?: boolean;
    isDisabled?: boolean;
    className?: string;
}

const ToggleBlock: FC<ToggleBlockForms> = (
    {
        desc,
        isChecked,
        handleChange,
        imgLeft,
        required,
        className = "",
        isDisabled
    }
) => {
    return (
        <div className={`toggle ${className}`}>
            <div className="toggle__item">
                {imgLeft}
                <p
                    style={{ opacity: !isDisabled ? "" : ".5" }}
                    className="toggle__text"
                >
                    {desc}
                </p>
            </div>

            <div
                style={{ opacity: !isDisabled ? "" : "0.5" }}
                onClick={() => handleChange(!isChecked)}
                className={`toggle__btn ${isChecked ? "active" : ""}`}
            >
                <span></span>
            </div>
        </div>
    );
};

export default ToggleBlock;
