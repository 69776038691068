import { FC } from "react";
import { IServicesApplications } from "../../../api/requests/services/interface";

interface IServicesApplicationsList {
  data: IServicesApplications[]
}
export const ServicesApplicationsList: FC<IServicesApplicationsList> = ({
  data
}) => {
  return (
    <div className='services-applications'>
      <div className='services-applications__header'>
        <div className='column'>ID заявки</div>
        <div className='column colApp'>Дата обучение</div>
        <div className='column colExecutor'>Дата заявки</div>
        <div className='column colDate'>Файл</div>
        <div className='column colDate'>Статус</div>
      </div>

      <div className='services-applications__body'>
        {data?.map((item, idx) => (
          <div className='services-applications__body__block' key={idx}>
            <p className='services-applications__body__block__id width'>{item.id}</p>

            <p className='services-applications__body__block__date width'>{item.date_education}</p>

            <p className='services-applications__body__block__date width'>{item.date_application}</p>

            <p className='services-applications__body__block__file width'>Скачать</p>

            <div className='services-applications__body__block__status width'>
              <p className=''>{item.status}</p>
            </div>
          </div>
        ))}
      </div>

      <div className='services-applications__download'>
        <p>
          Показать еще
        </p>
      </div>
    </div>
  )
}