import React, { FC, ReactElement, useEffect, useState } from "react";
import Select, {
    components, ContainerProps, OptionProps,
    SingleValueProps
} from "react-select";
import Button from "../Buttons/Button";
import { selectOption } from "../Fields/AddedNewField";
import { CloseIcon } from "../../../../img";
import CheckIcon from "../../../../img/icons/vacation/CheckIcon";
import CheckDropdownIcon from "../../../../img/icons/CheckDropdownIcon";

interface DropdownProps {
    options: selectOption[] | any[];
    onChange: (value: any) => void;
    onChangeInput?: (value: any) => void;
    deleteItem?: () => void;
    placeholder?: string;
    isMulti?: boolean;
    rest?: any;
    value?: any;
    imgLeft?: ReactElement | null;
    imgRight?: ReactElement | null;
    className?: string;
    isShowBtnDelete?: boolean;
    disabled?: boolean;
    defaultValue?: any;
    required?: boolean;
    isSearchable?: boolean;
    isClearable?: boolean;
    labelList?: string;
    label?: string;
    props?: any;
}

const customStyles = {
    indicatorSeparator: () => ({}),
    control: (provided: object) => ({
        ...provided,
        border: "none"
    }),
    dropdownIndicator: (provided: object) => ({
        ...provided,
        paddingRight: "14.5px"
    }),
    valueContainer: (provided: object) => ({
        ...provided
    }),
    indicatorsContainer: (provided: object) => ({
        ...provided
    }),
    input: (provided: object) => ({
        ...provided
    })
};

const DropdownIndicator: FC = (props: any) => {
    return (
        <components.DropdownIndicator {...props}>
            <svg
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <g>
                    <path
                        d="M5 7.5L10 12.5L15 7.5"
                        stroke="#007BFF"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                    />
                </g>
            </svg>
        </components.DropdownIndicator>
    );
};

const SingleValue = ({ children, ...props }: SingleValueProps<any>) => {
    return (
        <components.SingleValue {...props}>
            {children}

            {props.data.labelSecond && (
                <span
                    className={"filter-select-type"}
                    style={{
                        background: "rgba(112, 213, 132, 0.10)"
                    }}
                >
                    {props.data.labelSecond}
                </span>
            )}
        </components.SingleValue>
    );
};

const MenuList = (props: any) => {
    const label = props.selectProps.labelList;

    return (
        <components.MenuList {...props}>
            {label && (
                <div
                    style={{
                        color: "#003166",
                        padding: "20px 10px 16px",
                        fontWeight: 600,
                        opacity: 0.53,
                        textTransform: "uppercase"
                    }}
                >
                    {label}
                </div>
            )}
            {props.children}
        </components.MenuList>
    );
};

const Option = (props: OptionProps<any>) => {
    return (
        <components.Option {...props}>
            {props.isSelected && <CheckDropdownIcon/>}{props.children}
        </components.Option>
    );
};

const Dropdown: FC<DropdownProps> = (
    {
        options,
        onChange,
        imgLeft,
        imgRight,
        className,
        isShowBtnDelete = false,
        deleteItem,
        disabled,
        defaultValue,
        isSearchable = false,
        onChangeInput,
        isClearable,
        label,
        ...props
    }
) => {
    const [isMounted, setIsMounted] = useState(false);

    useEffect(() => setIsMounted(true), []);

    return (
        <div
            className={`form-group${imgLeft ? " left" : ""}${imgRight ? " right" : ""}${disabled ? " disabled" : ""}`}
        >
            {label && (
                <label
                    className={"form-label"}
                >
                    {label}
                </label>
            )}

            {imgLeft && <div className="form-icon-left">{imgLeft}</div>}

            {isMounted && (
                <Select
                    defaultValue={defaultValue}
                    styles={customStyles}
                    className={`filter-select dropdown__block ${className ? className : ""}`}
                    classNamePrefix={"react-select-prefix"}
                    components={{ DropdownIndicator, SingleValue, MenuList, Option }}
                    options={options}
                    isSearchable={isSearchable}
                    placeholder={"Статус"}
                    onChange={(val) => onChange(val)}
                    onInputChange={(val) => onChangeInput && onChangeInput(val)}
                    // menuIsOpen={true}
                    noOptionsMessage={() => <div>Список пуст</div>}
                    isDisabled={disabled}
                    isClearable={isClearable}
                    {...props}
                />
            )}

            {isShowBtnDelete && (
                <div className={"search__block__clear"}>
                    <Button
                        text={<CloseIcon color={"#FF0000"} opacity={"1"} />}
                        onClick={() => deleteItem && deleteItem()}
                        className={"btn btn-icon"}
                    />
                </div>
            )}

            {imgRight && <div className="form-icon-right">{imgRight}</div>}
        </div>
    );
};

export default Dropdown;
