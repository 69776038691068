import DialogContainer from "../../ui/dialog/DialogContainer";
import { FC, useEffect, useState } from "react";
import { CloseIcon } from "../../../img";
import { Controller, useForm } from "react-hook-form";
import Dropdown from "../../ui/form/Dropdown/Dropdown";
import TextField from "../../ui/form/Fields/TextField";
import ToggleBlock from "../../ui/form/Toggle/ToggleBlock";
import { IServicesField } from "../../../api/requests/services/interface";

interface DropdownItem {
  label: string;
  value: number | string;
}

interface IServicesTextModal {
  isOpen: boolean;
  onClose: () => void;
  purpose: string,
  fieldsData: IServicesField[],
  editedData?: IServicesField,
  setFieldsData: any,
}

export const ServicesCreateTextModal: FC<IServicesTextModal> = ({
  isOpen,
  onClose,
  purpose,
  fieldsData,
  setFieldsData,
  editedData,
}) => {
  const [ categoryList, setCategoryList ] = useState<DropdownItem[]>([
    {
      label: "Спорт",
      value: 0
    },
    {
      label: "Инновации",
      value: 1
    },
    {
      label: "Творчество",
      value: 2
    }
  ]);

  const {
    handleSubmit,
    control,
    watch,
    register,
    reset,
    formState: {isValid},
    setValue,
  } = useForm<IServicesField>(
    (purpose === 'edit' && editedData) ? {
      values: {
        name: editedData.name,
        id: editedData.id,
        type: getCategoryData(editedData.type),
        required: editedData.required,
      }
    } : {}
  );

  const addFieldData = () => {
    if (purpose === 'edit') {
      const updatedList = [ ...fieldsData ].map((
          item,
          idx
        ) =>
          item.id === editedData?.id ? {
              ...item,
              name: watch().name,
              type: watch().type.label,
              required: watch().required
            } : item
      )

      setFieldsData(updatedList)
    } else {
      const lastId = fieldsData.length > 0 ? fieldsData[fieldsData.length - 1].id : 0

      const newFieldData = {
        id: lastId + 1,
        name: watch().name,
        type: watch()?.type?.label,
        required: watch().required
      }

      setFieldsData([ ...fieldsData, newFieldData ])
    }

    setValue('name', '')
    setValue('type', '')
    setValue('required', 0)
    onClose()
  }

  const handleDeleteData = () => {
    const deletedList = fieldsData.filter((item) => item.id !== editedData?.id)

    setFieldsData(deletedList)
    onClose()
  }

  function getCategoryData(text: string) {
    const findCategory: any = categoryList.find((item) => item.label === text)

    return findCategory
  }

  return (
    <DialogContainer
      isOpen={ isOpen }
      closeModal={ onClose }
      classNameModal='services-create-modal'
      isShowCloseBtn={ false }
    >
      <div className='services-create-modal__header'>
        <h1
          className='services-create-modal__header__title'>{ purpose === 'edit' ? 'Редактор поля' : 'Новое поле' }</h1>

        <div className='services-create-modal__header__close' onClick={ onClose }>
          <CloseIcon color='#000' />
        </div>
      </div>

      <div className='services-create-modal__content'>
        <form className="services-create-form">
          <Controller
            name="type"
            control={ control }
            rules={ {
              required: {
                value: true,
                message: ""
              }
            } }
            render={ ({field: {onChange, value}}) => (
              <Dropdown
                className={ "form-select" }
                placeholder={ "Текстовая строка" }
                label={ "Тип" }
                options={ [
                  {
                    label: "Все",
                    value: "all"
                  },
                  ...categoryList
                ] }
                value={ value }
                onChange={ onChange }
              />
            ) }
          />

          <Controller
            name="name"
            control={ control }
            rules={ {
              required: {
                value: true,
                message: ""
              }
            } }
            render={ ({field: {value, onChange}}) => (
              <TextField
                value={ value }
                className=""
                title="Название поля"
                placeholder='Ваше имя'
                name="title"
                onChange={ onChange }
              />
            ) }
          />

          {/*<Controller*/ }
          {/*  name="category"*/ }
          {/*  control={ control }*/ }
          {/*  rules={ {*/ }
          {/*    required: {*/ }
          {/*      value: true,*/ }
          {/*      message: ""*/ }
          {/*    }*/ }
          {/*  } }*/ }
          {/*  render={ ({field: {onChange, value}}) => (*/ }
          {/*    <Dropdown*/ }
          {/*      className={ "form-select" }*/ }
          {/*      placeholder={ "Имя" }*/ }
          {/*      label={ "Выбор значения" }*/ }
          {/*      options={ [*/ }
          {/*        {*/ }
          {/*          label: "Все",*/ }
          {/*          value: "all"*/ }
          {/*        },*/ }
          {/*        ...categoryList*/ }
          {/*      ] }*/ }
          {/*      value={ value }*/ }
          {/*      onChange={ onChange }*/ }
          {/*    />*/ }
          {/*  ) }*/ }
          {/*/>*/ }

          <Controller
            name="required"
            control={ control }
            rules={ {
              required: {
                value: true,
                message: ""
              }
            } }
            render={ ({field: {onChange, value,}}) => (
              <ToggleBlock
                isChecked={ value === 1 }
                desc="Обязательно для заполнения"
                handleChange={ () => setValue('required', value === 1 ? 0 : 1) }
              />
            ) }
          />
        </form>
      </div>

      <div className='services-create-modal__buttons'>
        <div onClick={ addFieldData }
             className='services-create-modal__buttons__item services-create-modal__buttons__add'>
          { purpose ? 'Сохранить' : 'Добавить' }
        </div>

        { purpose === 'edit' && (
          <div
            onClick={() => handleDeleteData()}
            className='services-create-modal__buttons__item services-create-modal__buttons__delete'>
            Удалить поле
          </div>
        ) }
      </div>
    </DialogContainer>
  )
}