import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { isMd } from "../../../utils";
import { VacationUsersCalendarList } from "./VacationUsersCalendarList";
import { VacationUsersAllList } from "./VacationUsersAllList";
import {
    getOpenPopup,
    getProfileData,
    setOpenPopup,
    setPopupData
} from "../../../redux";
import { Skeleton } from "../../../components/ui/skeleton";
import { SingleSkeleton } from "../../../components/ui/skeleton/Single";
import { SearchItem } from "../../../components/ui/form/SearchItem";
import { BookingPopup } from "../../../components/vacation/CalendarBlock/BookingPopup";

export const VacationLeftContent = () => {
    const isMobile = isMd();

    const dispatch = useDispatch();
    const currentUser = useSelector(getProfileData);
    const isOpenPopup = useSelector(getOpenPopup);
    const isAdmin = currentUser.isAdmin;
    const isOwner = currentUser.isOwner;

    const [expand, setExpand] = useState<number | boolean>(false);
    const [filter, setFilter] = useState({
        has_vacations: null,
        status: null
    });

    const [isStaffLoading, setIsStaffLoading] = useState(true);

    const handleExpand = (panel: number | boolean) => {
        setExpand(panel);
    };

    const handleClosePopup = () => {
        dispatch(setOpenPopup());

        setTimeout(() => dispatch(setPopupData(null)), 500);
    };

    useEffect(() => {
        setIsStaffLoading(false);
        // $staffGet("api/v1/staff").then(res => {
        //     setIsStaffLoading(false);
        //     dispatch(setCurrentUser(res?.data));
        //     dispatch(
        //         setIsOwner(
        //             res?.data?.staff?.department_head === 2 || res?.data?.staff?.department_head === 1
        //         )
        //     );
        // });
    }, []);

    return (
        <div className={"vacation__content__left__calendar"}>
            <div className={"vacation__content__left__calendar-header"}>
                <h2 className={"vacation__content__left__calendar-title"}>Мой департамент</h2>

                {(isAdmin || isOwner) &&
                  <SearchItem
                    params={currentUser}
                    selectItem={filter}
                    onChange={setFilter}
                  />
                }
            </div>

            {!isAdmin && !isOwner && !currentUser && <SingleSkeleton />}

            {(isAdmin || isOwner) && isStaffLoading && (
                <div className={"vacation__content__left__calendar-block"}>
                    {[1, 2, 3, 4, 5].map((_, index) => (
                        <Skeleton key={index} />
                    ))}
                </div>
            )}

            {!isStaffLoading && (
                <VacationUsersCalendarList
                    isAdmin={isAdmin}
                    isOwner={isOwner}
                    expand={expand}
                    handleExpand={handleExpand}
                    filter={filter}
                />
            )}

            {!isStaffLoading && (
                <VacationUsersAllList
                    isAdmin={isAdmin}
                    isOwner={isOwner}
                    expand={expand}
                    handleExpand={handleExpand}
                    filter={filter}
                />
            )}

            <BookingPopup isOpen={isOpenPopup} onClose={handleClosePopup} />
        </div>
    );
};
