import { FC, ReactNode } from "react";

interface ToggleBlockForms {
  desc: any;
  isCheckedFirst: boolean;
  isCheckedSecond: boolean;
  handleChangeFirst: (is: boolean) => void;
  handleChangeSecond: (is: boolean) => void;
  iconFirst?: ReactNode | null,
  iconSecond?: ReactNode | null,
  imgLeft?: ReactNode | null;
  required?: boolean;
  isDisabled?: boolean;
  className?: string;
}

export const SecondToggleBlock: FC<ToggleBlockForms> = ({
  desc,
  isCheckedFirst,
  isCheckedSecond,
  handleChangeFirst,
  handleChangeSecond,
  iconFirst,
  iconSecond,
  imgLeft,
  required,
  className = "",
  isDisabled
}) => {
  return (
    <div>
      <div className={`toggle ${className}`}>
        <div className="toggle__item">
          {imgLeft}
          <p
            style={{ opacity: !isDisabled ? "" : ".5" }}
            className="toggle__text"
          >
            {desc}
          </p>
        </div>

        <div className='toggle-second-right'>
          <div className='toggle-second-right__item'>
            {iconFirst}
            <div
              style={{ opacity: !isDisabled ? "" : "0.5" }}
              onClick={() => handleChangeFirst(!isCheckedFirst)}
              className={`toggle__btn ${isCheckedFirst ? "active" : ""}`}
            >
              <span></span>
            </div>
          </div>

          <div className='toggle-second-right__item'>
            {iconSecond}
            <div
              style={{ opacity: !isDisabled ? "" : "0.5" }}
              onClick={() => handleChangeSecond(!isCheckedSecond)}
              className={`toggle__btn ${isCheckedSecond ? "active" : ""}`}
            >
              <span></span>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}