import React, { useEffect, useMemo, useState } from "react";
import { ArrowExitIcon, CloseIcon, SearchIcon } from "../../../img";
import ContestCreateEditPreview from "../../../containers/contests/ContestsCreateEdit/ContestCreateEditPreview";
import Button from "../../ui/form/Buttons/Button";
import DialogContainer from "../../ui/dialog/DialogContainer";
import ContestsViewWinnerWin from "../../../containers/contests/ContestsView/ContestsViewWinnerWin";
import ContestsViewWinnerExclude from "../../../containers/contests/ContestsView/ContestsViewWinnerExclude";
import TextField from "../../ui/form/Fields/TextField";
import { withDebounce } from "../../../functions";

interface ContestsViewCompletedProps {
  isOpen: boolean;
  onClose: () => void;
  data?: any;
  setData: any;
}

export const ServicesEmployeesModal = ({
  data,
  isOpen,
  onClose,
  setData
}: ContestsViewCompletedProps) => {
  const [departmentItems, setDepartmentItems] = useState([
    {
      id: 1,
      image: "/testImages/people/Image-1.png",
      name: "Краюшкин Александр Витальевич",
      position: "Руководитель",
      icon: "/testImages/statuses/statuses-1.png",
      department: "Департамент испытания и сертификации"
    },
    {
      id: 2,
      image: "/testImages/people/Image-2.png",
      name: "Смирнова Виалета Михайловна",
      position: "HR менеджер",
      icon: "/testImages/statuses/statuses-2.png",
      department: "Департамент испытания и сертификации"
    },
    {
      id: 3,
      image: "/testImages/people/Image-3.png",
      name: "Смирнова Виалета Михайловна",
      position: "HR менеджер",
      icon: "/testImages/statuses/statuses-3.png",
      department: "Департамент испытания и сертификации"
    },
    {
      id: 4,
      image: "/testImages/people/Image-4.png",
      name: "Смирнова Виалета Михайловна",
      position: "HR менеджер",
      icon: "/testImages/statuses/statuses-1.png",
      department: "Департамент испытания и сертификации"
    },
    {
      id: 5,
      image: "/testImages/people/Image-5.png",
      name: "Смирнова Виалета Михайловна",
      position: "HR менеджер",
      icon: "/testImages/statuses/statuses-2.png",
      department: "Департамент испытания и сертификации"
    }
  ]);
  const [filterUserList, setFilterUserList] = useState(departmentItems);

  const [selectedReplacer, setSelectedReplacer] = useState<number[]>([]);
  const [searchValue, setSearchValue] = useState<string>("");


  function onChangeSearch(value: string) {
    setSearchValue(value);

    const updateList = [...departmentItems].filter(item => item.name.toLowerCase().includes(value.toLowerCase()));

    setFilterUserList(updateList);
  }


  function handleSlice() {
    const updateList = [...filterUserList].filter((item, idx) => !selectedReplacer.includes(idx));

    setFilterUserList(updateList);
    setSelectedReplacer([]);
  }

  function onChangeCheckbox(idx: number) {
    // const updatedList = selectedReplacer.map((item) => )
    selectedReplacer.includes(idx) ?
      setSelectedReplacer((prev) => [...prev].filter(i => i !== idx)) :
      setSelectedReplacer((prev) => [...prev, idx])
  }


  const handleAddData = () => {
    const filteredData = departmentItems.filter((item: any, idx: number) => selectedReplacer.includes(idx))

    setSelectedReplacer([])
    setData(filteredData)
    onClose()
  }

  return (
    <DialogContainer
      isOpen={isOpen}
      closeModal={handleAddData}
      isShowCloseBtn={false}
      classNameModal={"p-0"}
      widthProps={427}
    >
      <div className={"contests__winner"}>
        <div className={"contests__winner__header"}>
          <h3>Пользователи</h3>
        </div>

        <div className={"contests__winner__search"}>
          <TextField
            value={searchValue}
            onChange={(value: string) => {
              setSearchValue(value);
              withDebounce(() => onChangeSearch(value));
            }}
            placeholder={"Поиск"}
            prepend={<SearchIcon color={"black"} />}
            className={`search__block-textField`}
          />
        </div>

        <div className={"contests__winner__list"}>
          {filterUserList.map((item, idx) => (
            <div key={idx} className="layout-vacation-modal__block">
              <input
                type="checkbox"
                checked={selectedReplacer.includes(idx)}
                onChange={() => onChangeCheckbox(idx)}
              />

              <div className="layout-vacation-modal__block__right">
                <img src={item.image} alt="" />

                <div className="layout-vacation-modal__block__desc">
                  <h1>{item.name}</h1>

                  <p className="layout-vacation-modal__block__desc__position">{item.position}</p>

                  <p className="layout-vacation-modal__block__desc__department">{item.department}</p>
                </div>
              </div>
            </div>
          ))}
        </div>

        <div className={"contests__winner__btns"}>
          <Button
            text={"Назначить победителя"}
            className={"btn btn-primary w-100"}
            onClick={() => handleAddData()}
            disabled={!selectedReplacer.length}
          />
        </div>
      </div>
    </DialogContainer>
  )
}