import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import moment from "moment";

import { CalendarItem } from "./CalendarItem";
import { isMd } from "../../../utils";
import {
    getBookingList,
    getCurrentUser,
    getHolidayList, getIsAdmin, getIsBookingLoading, getIsOwner,
    getMonth, getProfileVarsData,
    getSettings, setOpenPopup, setPopupData
} from "../../../redux";

import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import interactionPlugin from "@fullcalendar/interaction";
import resourceTimelinePlugin from "@fullcalendar/resource-timeline";

import styled from "./CalendarBlock.module.scss";

const weekday: any = {
    1: "monday",
    2: "tuesday",
    3: "wednesday",
    4: "thursday",
    5: "friday",
    6: "saturday",
    0: "sunday"
};

export const CalendarBlock = () => {
    const isMobile = isMd();

    const calendarRef = useRef<any>(null);

    const dispatch = useDispatch();
    const month = useSelector(getMonth);
    const holidayList = useSelector(getHolidayList);
    const bookingList = useSelector(getBookingList);
    const currentUser = useSelector(getCurrentUser);
    const settings = useSelector(getSettings);
    const isAdmin = useSelector(getIsAdmin);
    const isOwner = useSelector(getIsOwner);
    const isBookingLoading = useSelector(getIsBookingLoading);
    const vars = useSelector(getProfileVarsData);

    const [transformData, setTransformData] = useState<any[]>([]);

    const findUser = (id: string) => {
        const findItem = transformData?.find((user) => Number(user?.id) === Number(id));

        return findItem && findItem || null;
    };

    const handleOpenPopup = (data: any) => {
        dispatch(setPopupData(data));
        dispatch(setOpenPopup());
    };

    const style: any = {
        "--lightBlue": vars.colors.secondaryColor
    };

    useEffect(() => {
        if (bookingList && !isBookingLoading) {
            const listUpdate = [...bookingList].map((user, index) => {
                const {
                    scheduled_start_date,
                    scheduled_end_date,
                    intended_start_date,
                    intended_end_date,
                    actual_start_date,
                    actual_end_date
                } = user || {};

                return {
                    ...user,
                    start: actual_start_date || intended_start_date || scheduled_start_date,
                    end:
                        (actual_end_date && moment(actual_end_date).add(1, "days").format("YYYY-MM-DD")) ||
                        (intended_end_date && moment(intended_end_date).add(1, "days").format("YYYY-MM-DD")) ||
                        (scheduled_end_date && moment(scheduled_end_date).add(1, "days").format("YYYY-MM-DD")),
                    indicator: index
                };
            });

            setTransformData(listUpdate);
        }
    }, [bookingList, isBookingLoading]);

    useEffect(() => {
        if (calendarRef.current) {
            calendarRef.current.getApi().gotoDate(month);
            calendarRef.current.getApi().render();
        }

        return () => {
            calendarRef.current?.getApi().destroy();
        };
    }, [month]);

    // if loading Calendar
    // if (isBookingLoading) return null;

    return (
        <div className={`calendar__wrapper ${styled.wrapper}`} style={style}>
            <FullCalendar
                ref={calendarRef}
                schedulerLicenseKey="CC-Attribution-NonCommercial-NoDerivatives"
                // height='100%'
                plugins={[dayGridPlugin, interactionPlugin, resourceTimelinePlugin]}
                initialView="dayGridMonth"
                views={{
                    dayGridMonth: {
                        type: 'month',
                        duration: { months: 12 },
                        monthMode: true,
                        fixedWeekCount: true,
                    }
                }}
                locale="ru"
                headerToolbar={false}
                firstDay={1}
                monthStartFormat={{
                    month: isMobile ? "2-digit" : "long",
                    day: "numeric"
                }}
                dayHeaderFormat={{
                    weekday: isMobile ? "short" : "long"
                }}
                dayHeaderClassNames={(day) =>
                    settings?.working_days && !settings?.working_days[weekday[day?.dow]] && "fc-weekend"
                }
                dayCellClassNames={(day: any) => {
                    if (
                        holidayList?.find(({ start_date, end_date }: any) =>
                            moment(day?.date).isBetween(start_date, end_date, "days", "[]")
                        ) &&
                        settings?.working_days &&
                        !settings?.working_days[weekday[day?.dow]]
                    ) {
                        return "fc-weekend fc-holiday";
                    }

                    if (
                        holidayList?.find(({ start_date, end_date }: any) =>
                            moment(day?.date).isBetween(start_date, end_date, "days", "[]")
                        )
                    ) {
                        return "fc-holiday";
                    }

                    if (settings?.working_days && !settings?.working_days[weekday[day?.dow]]) {
                        return "fc-weekend";
                    }

                    return "";
                }}
                dayMaxEventRows={4}
                moreLinkContent={({ num, shortText }) => (isMobile ? shortText : `+${num} скрыто`)}
                moreLinkHint={(number) => `Показать ещё ${number}`}
                closeHint="Закрыть"
                select={({ start, startStr, endStr }) => {
                    if (moment(start).isBefore(moment().subtract(1, "days"))) return;

                    handleOpenPopup({
                        for_user: !isAdmin && currentUser,
                        start_date: startStr,
                        end_date: endStr
                    });
                }}
                eventClick={(info) => {
                    const {
                        scheduled_start_date,
                        scheduled_end_date,
                        intended_start_date,
                        intended_end_date,
                        actual_start_date,
                        actual_end_date,
                        indicator,
                        status
                    } = findUser(info?.event?.id) || {};

                    handleOpenPopup({
                        ...findUser(info?.event?.id),
                        start_date: actual_start_date || intended_start_date || scheduled_start_date,
                        end_date: actual_end_date || intended_end_date || scheduled_end_date,
                        indicator,
                        isEdit: isAdmin && status === 0,
                        isConciliate: (isAdmin || isOwner) && status !== 0,
                        isShow: !isAdmin,
                        isAccept: status === 2,
                        isRefuse: status === -1,
                        isCancel: status === 0,
                        isProcess: !isAdmin && !isOwner && status === 1
                    });
                }}
                eventContent={(info) => <CalendarItem user={findUser(info?.event?.id)} />}
                events={transformData}
                selectable
            />
        </div>
    );
};
