export default function downloadFile(file: string, fileName: string) {
  const aEle = document.createElement('a'); // Create a label
  const href = file; // Create downloaded link
  aEle.target = '_blank';
  aEle.href = href;
  aEle.download = fileName; // File name after download
  document.body.appendChild(aEle);
  aEle.click(); // Click to download
  document.body.removeChild(aEle); // Download complete remove element
}
