import VacationHeader from "./VacationHeader";
import { VacationLeftContent } from "./VacationLeftContent";
import { CalendarBlock } from "../../../components/vacation/CalendarBlock";
import { $getBookingSettings, $getVacationsSettings } from "../../../api/requests/vacation";
import { useEffect, useState } from "react";
import { getProfileData, setSettings } from "../../../redux";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

export const VacationPage = () => {
    const navigate = useNavigate();

    const dispatch = useDispatch();

    const { id } = useSelector(getProfileData);

    useEffect(() => {
        $getBookingSettings().then(bookingSetting => {
            $getVacationsSettings().then(vacationSetting => {

                if (bookingSetting?.data && vacationSetting?.data) {
                    dispatch(setSettings(vacationSetting?.data));

                    if (
                        bookingSetting?.data?.vacation_booking_active ||
                        vacationSetting?.data?.members?.find((memberId: number) => +memberId === +id)
                    ) return;

                    navigate(`/calendar`);
                }

                if (!bookingSetting?.data && !vacationSetting?.data) {
                    navigate(`/calendar`);
                }
            });
        });
    }, []);

    return (
        <div className={""}>
            <div className="container">
                <div className="row">
                    <div className="col-12">
                        <div className="vacation">
                            <VacationHeader />

                            <div className={"vacation__content"}>
                                <div className={"vacation__content__left"}>
                                    <VacationLeftContent />
                                </div>

                                <div className="vacation__content__right">
                                    <CalendarBlock/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};