import HorizonNavigate from "../../components/navigation/HorizonNavigate";
import { useEffect, useState } from "react";
import CalendarDays from "../../components/calendar/CalendarDays";
import TrainingContentItem from "./TrainingContentItem";
import { $getSportList } from "../../api/requests/sport";
import {
    SportDateData,
    SportItemData,
    SportResponseDateData,
    SportViewItemData
} from "../../api/requests/sport/interface";

const daysOfWeek = ["вс", "пн", "вт", "ср", "чт", "пт", "сб"];

const TrainingPage = () => {
    const [sportContentList, setSportContentList] = useState<SportViewItemData[]>([]);

    const transformDateData = (data: SportResponseDateData[]): SportDateData[] => {
        return data.map((item) => {
            const dateObj = new Date(item.date);
            const weekDay = daysOfWeek[dateObj.getDay()];
            const formattedDate = `${String(dateObj.getDate()).padStart(2, '0')}.${String(dateObj.getMonth() + 1).padStart(2, '0')}`; // Format date as dd.mm
            const time = `${item.start} - ${item.end}`;

            return {
                week: weekDay,
                date: formattedDate,
                time: time,
            };
        });
    }

    const transformContentData = (list: SportItemData[]): SportViewItemData[] => {
        return [...list].map(item => {
            return {
                ...item,
                date: transformDateData(item.date)
            }
        })
    }

    useEffect(() => {
        init();
    }, []);

    function init() {
        $getSportList().then(res => {
            if (!res.data) return;

            setSportContentList(transformContentData(res.data))
        })
    }

    return (
        <div className={"sport"}>
            <HorizonNavigate isScroll={false} />

            <div className="container sport__container">
                <div className="sport__head">
                    <div className="row">
                        <div className="col-md-2 d-flex align-items-center">
                            <h2 className="sport__head-title">
                                Спорт
                            </h2>
                        </div>
                        <div className="col-md-10">
                            <div className={"sport__head__days"}>
                                <CalendarDays />
                            </div>
                        </div>
                    </div>
                </div>

                <div className="sport__content">
                    <div className="row">
                        {sportContentList.length && sportContentList.map((item, idx) => (
                            <div
                                key={`sport-content-item-${idx}`}
                                className="col-md-4 sport__content-card"
                            >
                                <TrainingContentItem
                                    {...item}
                                />
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default TrainingPage;