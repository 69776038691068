const AddIcon = () => {
    return (
        <svg width="32" height="32" viewBox="0 0 32 32" fill="none"
             xmlns="http://www.w3.org/2000/svg">
            <path
                d="M8.00098 16H16.001M24.001 16H16.001M16.001 16V8M16.001 16V24"
                stroke="currentColor"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
};

export default AddIcon;
