import React, { useMemo, useState } from "react";
import DialogContainer from "../../../components/ui/dialog/DialogContainer";
import Button from "../../../components/ui/form/Buttons/Button";
import ContestCreateEditPreview from "../ContestsCreateEdit/ContestCreateEditPreview";
import { CloseIcon } from "../../../img";
import TextField from "../../../components/ui/form/Fields/TextField";
import { Controller, useForm } from "react-hook-form";
import FileClipField from "../../../components/ui/form/FileField/FileClipField";
import { useNavigate } from "react-router-dom";
import ContestsViewWinner from "./ContestsViewWinner";

interface FormData {
    comment: string;
    files?: any;
}

interface ContestsPageViewProps {
    isOpen: boolean;
    onClose: () => void;
    data?: any;
    onSuccess?: () => void;
}

const ContestsPageView = (
    {
        data,
        isOpen,
        onClose,
        onSuccess
    }: ContestsPageViewProps
) => {
    const navigate = useNavigate();

    const isAdmin = true;

    const dataUpdate = useMemo(() => ({
        ...data,
        image: "/testImages/news/news-2.png",
        name: data?.title,
        date_end: "2024-08-25T16:28:12.111Z",
        category: {
            label: "Наука"
        },
        points_win: "1 100",
        points_participation: "10",
        peoples: 5
    }), [data]);

    const [statusSelectItem, setStatusSelectItem] = useState("start");
    const [uploadFileList, setUploadFileList] = useState<any[]>([]);

    const {
        handleSubmit,
        control,
        reset,
        formState: { isValid }
    } = useForm<FormData>();

    function handleOpenSupply() {
        setStatusSelectItem("supply");
    }

    function handleOpenPeople() {
        navigate("application");
    }

    function onSubmit(data: any) {
        setStatusSelectItem("start");
        onSuccess && onSuccess();
        // $supplyApplication(data.message).then(res => {
        //     reset();
        //     setStatusSelectItem("start");
        //     onSuccess && onSuccess();
        // })
    }

    function handleUploadList(list: any) {
        list().forEach((item: any) => {
            if (item.file) {
                const reader = new FileReader();

                reader.onloadend = () => {
                    if (reader.result && typeof reader.result === "string") {
                        setUploadFileList((prev: any[]) => {
                            if (prev) return [...prev, { file: reader.result }];

                            return [{ file: reader.result }];
                        });
                    }
                };

                reader.readAsDataURL(item.file);
            }
        });
    }

    function handleCloseModal() {
        onClose();
        setStatusSelectItem("start");
    }

    function handleOpenWinner() {
        setStatusSelectItem("winner");
    }

    return (
        <DialogContainer
            isOpen={isOpen}
            setIsOpen={handleCloseModal}
            isShowCloseBtn={false}
            classNameModal={"p-0"}
            widthProps={427}
        >
            <div className={"contests__view"}>
                <div className={"contests__view__close"} onClick={handleCloseModal}>
                    <CloseIcon color={statusSelectItem === "winner" ? "black" : "white"}/>
                </div>

                {statusSelectItem === "start" && (
                    <>
                        <ContestCreateEditPreview
                            data={dataUpdate}
                            handleSubmit={handleOpenSupply}
                            isHideBtn={isAdmin}
                        />

                        {isAdmin && (
                            <div className={"contests__view__btn__application"}>
                                <Button
                                    text={"Заявки"}
                                    rightIcon={<span
                                        className={"contests__view__btn__count"}>6</span>}
                                    className={"btn btn-primary w-100"}
                                    onClick={handleOpenWinner}
                                />
                            </div>
                        )}

                        <div className={"contests__view__btns"}>
                            {isAdmin && (
                                <Button
                                    text={"Редактировать"}
                                    className={"btn btn-light w-100"}
                                    onClick={() => dataUpdate.id && navigate(`edit?contest_id=${dataUpdate.id}`)}
                                />
                            )}

                            <Button
                                text={"Участники"}
                                className={"btn btn-light w-100"}
                                onClick={handleOpenPeople}
                            />
                        </div>
                    </>
                )}

                {statusSelectItem === "supply" && (
                    <div className="contests__create__preview-items">
                        <div className="contests__create__preview-img">
                            <img src={dataUpdate.image} />
                        </div>

                        <div className="contests__create__preview-item contests__view__block">
                            <h4>Подача заявки</h4>

                            <form onSubmit={handleSubmit(onSubmit)}>
                                <Controller
                                    name="comment"
                                    control={control}
                                    rules={{
                                        required: {
                                            value: true,
                                            message: "Введите ответ"
                                        }
                                    }}
                                    render={({ field: { onChange, value } }) => (
                                        <TextField
                                            value={value}
                                            className=""
                                            title="Ваш ответ"
                                            placeholder={"Введите ответ"}
                                            onChange={onChange}
                                        />
                                    )}
                                />

                                <Controller
                                    name="files"
                                    control={control}
                                    rules={{
                                        required: {
                                            value: false,
                                            message: "Прикрепите файл"
                                        }
                                    }}
                                    render={({ field: { onChange, value } }) => (
                                        <FileClipField
                                            accept={".pdf, .doc"}
                                            label={"Прикрепите файл (Не обязательно)"}
                                            upload={value}
                                            setUpload={handleUploadList}
                                        />
                                    )}
                                />

                                <Button
                                    disabled={!isValid}
                                    type={"submit"}
                                    text={"Отправить"}
                                    className={"btn btn-primary w-100"}
                                />
                            </form>
                        </div>
                    </div>
                )}

                {statusSelectItem === "winner" && (
                    <ContestsViewWinner
                        data={dataUpdate}
                        onBack={() => setStatusSelectItem("start")}
                        onClose={onClose}
                    />
                )}
            </div>
        </DialogContainer>
    );
};

export default ContestsPageView;