import { useDispatch, useSelector } from "react-redux";
import {
    getProfileData,
    getStructureCompany,
    setStructureCompany,
    setStructureCurrentTopHeight
} from "../../../../redux";
import { isLg, isMd } from "../../../../utils";
import Button from "../../../../components/ui/form/Buttons/Button";
import { ArrowRightIcon, EmailIcon, StarIcon, UserProfileIcon } from "../../../../img";
import { Tooltip } from "react-tooltip";
import { Link, useNavigate } from "react-router-dom";
import { useRef } from "react";
import { MessengerIcon } from "../../../../img/icons/structure/MessengerIcon";

interface StructureItemProps {
    person: {
        index: number;
        count: number;
        id: string;
        department_id: string;
        department: any;
        hasChild: string;
        is_active: boolean;
        isExpand: boolean;
        isActive: boolean;
        isActiveStyle: boolean;
        setDepartment: (value: string) => void;
        user: any;
        isVacationAdmin: boolean;
        title: string;
    };
    isFavorite?: boolean;
}

export const StructureItem = (
    {
        person,
        isFavorite
    }: StructureItemProps
) => {

    const contentItem = useRef<any>(null);

    const navigate = useNavigate();
    const query = { is_admin: true };
    const { structure } = useSelector(getStructureCompany);
    const { workspace_role: role, workspace_id: wsId } = useSelector(getProfileData);
    const dispatch = useDispatch();

    const isTablet = isLg();
    const isMobile = isMd();

    const {
        index,
        count,
        id,
        department_id,
        department,
        hasChild,
        is_active,
        isExpand,
        isActive,
        isActiveStyle,
        setDepartment,
        user,
        isVacationAdmin,
        title
    } = person || {};

    const isEnable = is_active || department?.is_active;
    const isAdmin = role === 1 || role === 2;
    const pathAdmin = query?.is_admin;

    const handleActive = () => {
        if (!isActiveStyle && hasChild) {
            const updateArray = structure?.map((_: any, i: number) =>
                structure?.[i]?.map((item: any) => {
                    return { ...item, isActiveStyle: false };
                })
            );

            const updateIndexArray = structure?.[index]?.map((item: any) => {
                if (department?.id ? item?.department?.id === department?.id : item?.id === id) {
                    return {
                        ...item,
                        isActive: true,
                        isActiveStyle: true,
                        isHidden: isTablet && false
                    };
                }

                return {
                    ...item,
                    isActive: false,
                    isActiveStyle: false,
                    isHidden: isTablet && true
                };
            });

            const copyArray = [...updateArray];

            copyArray[index] = updateIndexArray;

            dispatch(
                setStructureCompany({
                    structure: copyArray?.slice(0, index + 1)
                })
            );

            dispatch(setStructureCurrentTopHeight(contentItem.current.getBoundingClientRect().top - 204));

            setDepartment(department_id || department?.department_id);
        }
    };

    const makeMessageUrl = ({ photo, deafultDataKeys, rest, name, id }: any) => {
        const baseUrl = `/messenger?id=${deafultDataKeys ? rest[deafultDataKeys.id] : id}&name=${
            deafultDataKeys ? rest[deafultDataKeys.name] : name
        }`;

        if (!photo) {
            return baseUrl;
        }
        return `${baseUrl}&photo=${photo}`;
    };

    function handleProfile() {
        navigate(`/user?workspace_id=${wsId}&user_id=${user?.userId}`);
    }

    function handleMessage() {
        const { photo, name, userId } = user;

        navigate(makeMessageUrl({ photo, name, id: userId }));
    }

    function handleFavorite() {
        console.log(user, person);
    }

    return (
        <div
            id={isActiveStyle ? "active" : ""}
            className={"structure__content__item"}
            ref={contentItem}
        >
            <div
                className={`structure__content__item-body ${count > 1 ? (isExpand ? "open" : "") : "open"}`}>
                <div className={"structure__content__item-avatar"} onClick={handleActive}>
                    {user?.photo && <img src={user?.photo} />}

                    {!user?.photo && user?.name?.charAt(0)}

                    {/*{!photo && !name && <IconAccount />}*/}
                </div>

                <div className={"structure__content__item-body-info"}>
                    <div
                        className={"structure__content__item-body-name"}>{user === null ? "Не зарегистрирован в СВОИ" : user?.name}</div>

                    <div className={"positionStyle, structure__content__item-body-position"}>
                        <span>{department?.title}</span>
                        <span>{title}</span>
                    </div>

                    {(user || (isVacationAdmin && hasChild)) && (
                        <div className={"structure__content__item-body-contacts"}>
                            {user?.workspace_role?.privacy === 1 && (
                                <Tooltip content="Открыть профиль">
                                    <Button
                                        text={<UserProfileIcon/>}
                                        className={"btn btn-icon structure__content__item-body-iconButton, structure__content__item-body-account"}
                                        onClick={handleProfile}
                                    />
                                </Tooltip>
                            )}

                            {user?.mail && user?.workspace_role?.privacy_mail === 1 && (
                                <Tooltip content="Написать на почту">
                                    <Link
                                        to={`mailto:${user?.mail}`}
                                        className={"btn btn-icon structure__content__item-body-icon-btn, structure__content__item-body-account"}
                                        onClick={handleProfile}
                                    >
                                        <EmailIcon />
                                    </Link>
                                </Tooltip>
                            )}

                            {user?.workspace_role?.privacy_messenger === 0 && (
                                <Tooltip content="Написать сообщение">
                                    <Button
                                        text={<MessengerIcon/>}
                                        className={"btn btn-icon structure__content__item-body-icon-btn, structure__content__item-body-account"}
                                        onClick={handleMessage}
                                    />
                                </Tooltip>
                            )}
                        </div>
                    )}
                </div>

                <div className={"structure__content__item-body-favorite"}>
                    <div className={"favorite-btn"} onClick={handleFavorite}>
                        <StarIcon strokeColor={isActive ? "#00A7B5" : "#0000004D"} color={isActive ? "#00A7B5" : ""}/>
                    </div>
                </div>
            </div>

            {!!isEnable && (isExpand || isActive) && hasChild && (
                <div className={"d-flex align-items-center justify-content-center"}>
                    <Button
                        text={<ArrowRightIcon color={"white"} />}
                        className={"btn btn-primary subdivision"}
                        onClick={handleActive}
                    />
                </div>
            )}
        </div>
    );
};
