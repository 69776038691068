import React, { useMemo, useState } from "react";
import DialogContainer from "../../../components/ui/dialog/DialogContainer";
import Button from "../../../components/ui/form/Buttons/Button";
import ContestCreateEditPreview from "../ContestsCreateEdit/ContestCreateEditPreview";
import { CloseIcon } from "../../../img";

interface ContestsViewCompletedProps {
    isOpen: boolean;
    onClose: () => void;
    data?: any;
}

const ContestsViewCompleted = (
    {
        data,
        isOpen,
        onClose,
    }: ContestsViewCompletedProps
) => {
    const isAdmin = true;

    const dataUpdate = useMemo(() => ({
        ...data,
        image: "/testImages/news/news-2.png",
        name: data?.title,
        date_end: "2024-08-25T16:28:12.111Z",
        category: {
            label: "Наука"
        },
        points_win: 0,
        points_participation: 0,
        peoples: 5
    }), [data]);

    const [departmentItems, setDepartmentItems] = useState([
        {
            id: 1,
            image: "/testImages/people/Image-1.png",
            name: "Краюшкин Александр Витальевич",
            position: "Руководитель",
            icon: "/testImages/statuses/statuses-1.png",
            department: "Департамент испытания и сертификации",
            winner: true,
        },
        {
            id: 2,
            image: "/testImages/people/Image-2.png",
            name: "Смирнова Виалета Михайловна",
            position: "HR менеджер",
            icon: "/testImages/statuses/statuses-2.png",
            department: "Департамент испытания и сертификации",
            winner: true,
        },
        {
            id: 3,
            image: "/testImages/people/Image-3.png",
            name: "Смирнова Виалета Михайловна",
            position: "HR менеджер",
            icon: "/testImages/statuses/statuses-3.png",
            department: "Департамент испытания и сертификации",
            winner: false,
        },
        {
            id: 4,
            image: "/testImages/people/Image-4.png",
            name: "Смирнова Виалета Михайловна",
            position: "HR менеджер",
            icon: "/testImages/statuses/statuses-1.png",
            department: "Департамент испытания и сертификации",
            winner: false,
        },
        {
            id: 5,
            image: "/testImages/people/Image-5.png",
            name: "Смирнова Виалета Михайловна",
            position: "HR менеджер",
            icon: "/testImages/statuses/statuses-2.png",
            department: "Департамент испытания и сертификации",
            winner: false,
        }
    ]);

    const usersWinList = [...departmentItems].filter(item => item.winner)
    const usersOtherList = [...departmentItems].filter(item => !item.winner)

    function handleCloseModal() {
        onClose();
    }

    function handleUploadTable() {
        onClose();
    }

    return (
        <DialogContainer
            isOpen={isOpen}
            setIsOpen={handleCloseModal}
            isShowCloseBtn={false}
            classNameModal={"p-0"}
            widthProps={427}
        >
            <div className={"contests__view"}>
                <div className={"contests__view__close"} onClick={handleCloseModal}>
                    <CloseIcon color={"white"} />
                </div>

                <ContestCreateEditPreview
                    data={dataUpdate}
                    isHideBtn={isAdmin}
                />

                <div className={"contests__completed__content"}>
                    <h4>Участники</h4>

                    <div className={"contests__completed__list"}>
                        <div className={"contests__completed__list__win"}>
                            {usersWinList.map((item: any, idx: number) => (
                                <div key={idx} className="layout-vacation-modal__block">
                                    <div className="layout-vacation-modal__block__right">
                                        <img src={item.image} alt="" />

                                        <div className="layout-vacation-modal__block__desc">
                                            <h1>{item.name}</h1>

                                            <p className="layout-vacation-modal__block__desc__position">{item.position}</p>

                                            <p className="layout-vacation-modal__block__desc__department">{item.department}</p>
                                        </div>
                                    </div>

                                    <div className={"contests__completed__reason"}>
                                        <h5>Причина победы</h5>

                                        <p>Отличный участник показавший что такое лидерские качества</p>
                                    </div>
                                </div>
                            ))}
                        </div>

                        {usersOtherList.map((item: any, idx: number) => (
                            <div key={idx} className="layout-vacation-modal__block">
                                <div className="layout-vacation-modal__block__right">
                                    <img src={item.image} alt="" />

                                    <div className="layout-vacation-modal__block__desc">
                                        <h1>{item.name}</h1>

                                        <p className="layout-vacation-modal__block__desc__position">{item.position}</p>

                                        <p className="layout-vacation-modal__block__desc__department">{item.department}</p>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>

                {isAdmin && (
                    <div className={"contests__view__btn__application"}>
                        <Button
                            text={"Выгрузить таблицу участников"}
                            className={"btn btn-primary w-100"}
                            onClick={handleUploadTable}
                        />
                    </div>
                )}
            </div>
        </DialogContainer>
    );
};

export default ContestsViewCompleted;