import { useEffect, useState } from 'react';
import { isMd } from "../../../../../utils";
import { ArrowRightIcon, CloseIcon } from "../../../../../img";
import FileField from "../../../../ui/form/FileField/FileField";
import Button from "../../../../ui/form/Buttons/Button";
import downloadFile from "../../../../../utils/file/download-file";
import { useSelector } from "react-redux";
import { getProfileVarsData } from "../../../../../redux";
import styles from "./ReasonBlock.module.scss";

const reasons = [
  {
    value: 'Супруг / супруга военнослужащего',
  },
  {
    value: 'Родитель ребенка-инвалида',
  },
  {
    value: 'Многодетная семья (3 и более детей до 14 лет)',
  },
];

interface ReasonBlockData {
  booking: any
  setBooking: (value: any) => void;
}

export const ReasonBlock = (
    {
      booking,
      setBooking
    }: ReasonBlockData
) => {
  const isMobile = isMd();

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const anchorId = open ? 'reasons-popover' : undefined;
  const vars = useSelector(getProfileVarsData);

  const [current, setCurrent] = useState(
    reasons?.find((reason) => reason?.value === booking?.request?.reason),
  );

  const style: any = {
    "--lightBlue": vars.colors.secondaryColor
  };

  const handleOpen = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleSelect = (reason: any) => {
    setBooking((prev: any) => {
      return {
        ...prev,
        request: {
          ...prev?.request,
          reason: reason?.value,
        },
      };
    });

    setCurrent(reason);
    handleClose();
  };

  useEffect(() => {
    setCurrent(reasons?.find((reason) => reason?.value === booking?.request?.reason));
  }, [booking?.request]);

  return (
    <div className={"calendar__reason__wrapper"} style={style}>
      <div
        className={`calendar__reason__select ${styles.select} ${(booking?.isEdit || booking?.isConciliate || booking?.isCancel || booking?.isShow) && "calendar__reason__disabled"}`}
        aria-describedby={anchorId}
        onClick={handleOpen}
      >
        <span>{current?.value || 'Указать обстоятельство'}</span>

        {!booking?.isEdit && !booking?.isConciliate && !booking?.isCancel && !booking?.isShow && (
          <ArrowRightIcon />
        )}
      </div>

      {!booking?.isEdit && !booking?.isConciliate && !booking?.isCancel && !booking?.isShow && (
        <FileField
            label='Прикрепить документ'
            upload={""}
            setUpload={(file) => {
              setBooking((prev: any) => {
                return {
                  ...prev,
                  request: {
                    ...prev?.request,
                    files: [file],
                  },
                };
              })
            }}
        />
      )}

      {booking?.request?.files?.length > 0 && (
        <div className={"calendar__reason__files"}>
          {booking?.request?.files?.map(({ id, original_filename, url }: any, index: number) => (
            <div key={id}>
              <label>{index === 0 ? 'Скачать документ' : ''}</label>

              <Button
                  // leftIcon={<IconDownload/>}
                  text={original_filename}
                  onClick={() => downloadFile(url, original_filename)}
              />
            </div>
          ))}
        </div>
      )}

      {/*<Popover*/}
      {/*  id={anchorId}*/}
      {/*  anchorEl={anchorEl}*/}
      {/*  anchorReference={isMobile ? 'anchorPosition' : 'anchorEl'}*/}
      {/*  anchorPosition={isMobile ? { top: 1000, left: 0 } : undefined}*/}
      {/*  marginThreshold={isMobile ? 0 : 16}*/}
      {/*  classes={"calendar__reason__root calendar__reason__paper"}*/}
      {/*  anchorOrigin={{*/}
      {/*    vertical: 49,*/}
      {/*    horizontal: 'left',*/}
      {/*  }}*/}
      {/*  transformOrigin={{*/}
      {/*    vertical: 'top',*/}
      {/*    horizontal: 'left',*/}
      {/*  }}*/}
      {/*  open={open}*/}
      {/*  onClose={handleClose}*/}
      {/*>*/}
      {/*  {isMobile && (*/}
      {/*    <div className={"calendar__reason__head"}>*/}
      {/*      {'Обстоятельство'}*/}

      {/*      <div className={`calendar__reason__close ${styles.close}`} onClick={handleClose}>*/}
      {/*        <CloseIcon />*/}
      {/*      </div>*/}
      {/*    </div>*/}
      {/*  )}*/}

      {/*  <div className={"calendar__reason__body"}>*/}
      {/*    {reasons?.map((reason) => (*/}
      {/*      <div key={reason?.value} className={`calendar__reason__item ${styles.item}`} onClick={() => handleSelect(reason)}>*/}
      {/*        {reason?.value}*/}
      {/*      </div>*/}
      {/*    ))}*/}
      {/*  </div>*/}
      {/*</Popover>*/}
    </div>
  );
};
