/* eslint-disable no-nested-ternary */
import { useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import moment, { Moment } from "moment";
import cn from 'classnames';

import {
  getBookingList,
  getIsAdmin, getProfileVarsData,
  setMonth,
  setOpenPopup,
  setPopupData
} from "../../../../../redux";
import { StatusIconSuccess, StatusIconDanger, StatusIconDefault } from "../../../../../img/icons/statuses";

interface UserSingleData {
  searchUser: string;
  onClick: () => void;
  isShort: boolean;
  isSearch: boolean;
  isFor: boolean;
  isConciliate: boolean;
  isAccept: boolean;
  isRefuse: boolean;
  for_user: {
    firstname: string;
    lastname: string;
    middle_name: string;
    photo: string;
    structure: any;
    staff: any;
    vacation_info: any;
    id: string;
  }
}

export const UserSingle = ({
  searchUser,
  onClick,
  isShort,
  isSearch,
  isFor,
  isConciliate,
  isAccept,
  isRefuse,
  ...props
}: UserSingleData) => {

  const dispatch = useDispatch();
  const bookingList = useSelector(getBookingList);
  const isAdmin = useSelector(getIsAdmin);
  const vars = useSelector(getProfileVarsData);

  const { for_user } = props || {};
  const { firstname, lastname, middle_name, photo, structure, staff, vacation_info } = for_user || {};

  const filteredBookingsList = useMemo(() => {
    return bookingList?.filter((item: any)=> item.for_user_id === for_user.id)
  },[bookingList])

  const formatDate = (start: string, end: string) => {
    return start && end && `${moment(start)?.format('DD.MM')} - ${moment(end)?.format('DD.MM')}`;
  };

  const handleMonth = (date: Moment) => {
    dispatch(setMonth(moment(date).format('YYYY-MM-DD')));
  };

  const handleOpenPopup = () => {
    onClick();

    handleMonth(moment());

    dispatch(
      setPopupData({
        ...props,
      }),
    );
    dispatch(setOpenPopup());
  };

  const style: any = {
    "--lightBlue": vars.colors.secondaryColor,
    "--whiteColor": vars.colors.fourthColor,
    "--wrapperBg": isSearch ? `${vars.colors.secondaryColor}0d` : '',
    "--conciliateColor": isAccept ? vars.colors.secondaryColor : isRefuse ? '#ff0000' : 'rgba(0, 0, 0, 0.5)'
  };

  return (
    <div
        className={`calendar__member__user__wrapper 
        ${isShort ? "short" : ""} 
        ${isSearch ? "search" : ""}
        ${isShort && isFor ? 'for' : isShort && isConciliate ? 'conciliate' : ''}
        `}
        style={style}
        onClick={isAdmin && isSearch ? handleOpenPopup : undefined}
    >
      <div className={`calendar__member__user__avatar ${isShort && isFor ? 'for' : ''}`}>
        {photo && <img src={photo} />}

        {!photo && firstname && firstname.slice(0, 1)}
      </div>

      <div className={"calendar__member__user__info"}>
        <div className={"calendar__member__user__name"}>{`${lastname} ${firstname} ${middle_name}`}</div>

        <div className={"calendar__member__user__position"}>{staff?.title || (!isFor && isConciliate && 'Администратор')}</div>

        {!isShort && (
          <>
            <div className={"calendar__member__user__department"}>{structure?.title}</div>

            <div className={"calendar__member__user__vacation"}>
              {filteredBookingsList?.length > 0 && (
                <div className={"calendar__member__user__item"}>
                  <div className={"calendar__member__user__label"}>Дата отпуска</div>

                  <div className={"calendar__member__user__list"}>
                    {filteredBookingsList?.map(
                      ({
                        scheduled_start_date,
                        scheduled_end_date,
                        intended_start_date,
                        intended_end_date,
                        actual_start_date,
                        actual_end_date,
                        status,
                      }: any) => (
                        <span className={cn("calendar__member__user__date", status === 2 && "calendar__member__user__accept")}>
                          {(actual_start_date &&
                            actual_end_date &&
                            formatDate(actual_start_date, actual_end_date)) ||
                            (intended_start_date &&
                              intended_end_date &&
                              formatDate(intended_start_date, intended_end_date)) ||
                            (scheduled_start_date &&
                              scheduled_end_date &&
                              formatDate(scheduled_start_date, scheduled_end_date))}
                        </span>
                      ),
                    )}
                  </div>
                </div>
              )}

              <div className={"calendar__member__user__item"}>
                <div className={"calendar__member__user__label"}>Доступные дни отпуска</div>

                <span className={"calendar__member__user__value"}>
                  {vacation_info?.unused_vacation < 0 ? 0 : vacation_info?.unused_vacation}
                </span>
              </div>
            </div>
          </>
        )}
      </div>

      {!isFor && isConciliate && (
        <div className={"calendar__member__user__conciliate"}>
          {isAccept && <StatusIconSuccess />}

          {isRefuse && <StatusIconDanger />}

          {!isAccept && !isRefuse && <StatusIconDefault />}
        </div>
      )}
    </div>
  );
};
