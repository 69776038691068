import { FC, useEffect, useState } from "react";
import { CloseIcon, PaperClipIcon, PictureIcon } from "../../../../img";

interface FileFieldProps {
    upload: File | string | null;
    setUpload: (file: File) => void;
    label?: string;
    className?: string;
    accept?: string;
}

const FileField: FC<FileFieldProps> = (
    {
        upload,
        setUpload,
        label = "Фото",
        className = "form-file",
        accept = ".png, .jpg"
    }
) => {
    const [placeholder, setPlaceholder] = useState("");
    const [imageURL, setImageURL] = useState<File | string | null>(null);

    const handleFile = ({ target }: any) => {
        const file = target?.files[0];

        if (file) {
            const imageURL = URL.createObjectURL(file);

            setImageURL(imageURL);

            setUpload(file);

            setPlaceholder(file?.name);
        }
    };

    useEffect(() => {
        if (upload && (typeof upload === "string")) setImageURL(upload);
    }, [upload]);

    return (
        <div className={className}>
            <p>{label}</p>

            <div className={`${className}-block ${imageURL ? "image" : ""}`}>
                <label htmlFor="file"></label>

                <input type="file" accept={accept} onChange={handleFile} id={"file"} hidden />

                {(imageURL && (typeof imageURL === "string"))
                    && (<img src={imageURL} />)
                }

                <PictureIcon color={imageURL ? "white" : "#00A7B5"}/>
            </div>
        </div>
    );
};

export default FileField;
