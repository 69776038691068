import { useSelector } from 'react-redux';
import { getIsAdmin, getIsOwner, getProfileVarsData } from "../../../redux";
import {
  vacationColors,
  vacationHoverColors
} from "../../../containers/calendar/vacation/constans";
import styles from "./CalendarItem.module.scss";

export const CalendarItem = ({ user }: any) => {
  const isAdmin = useSelector(getIsAdmin);
  const isOwner = useSelector(getIsOwner);
  const vars = useSelector(getProfileVarsData);

  const { for_user, status, indicator } = user || {};
  const { firstname, lastname } = for_user || {};

  const isBgColor = (isAdmin || isOwner) && indicator >= 0 && vacationColors[indicator % vacationColors.length]
  const isBgActionColor = (isAdmin || isOwner) && indicator >= 0 && vacationHoverColors[indicator % vacationHoverColors.length]

  const style: any = {
    "--lightBlue": vars.colors.secondaryColor,
    "--whiteColor": vars.colors.fourthColor,
    "--status": status === 2 ? 1 : 0.3,
    "--backgroundColor": isBgColor ? isBgColor : vars.colors.secondaryColor,
    "--backgroundActionColor": isBgActionColor ? isBgActionColor : `${vars.colors.secondaryColor}e6`,
    "--color": isBgColor ? "#000" : vars.colors.fourthColor,
  };

  return (
    <div className={`calendar__item__wrapper ${styles.wrapper}`} style={style}>
      <div className={`calendar__item__name ${styles.name}`}>{`${firstname} ${lastname}`}</div>
    </div>
  );
};
