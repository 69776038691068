const CheckIcon = () => {
    return (
        <svg width="36" height="36" viewBox="0 0 36 36" fill="none"
             xmlns="http://www.w3.org/2000/svg">
            <path
                d="M11 18.7857L15.05 23.125L24.5 13"
                stroke="currentColor"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
};

export default CheckIcon;
