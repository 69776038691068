import React, { useState } from "react";
import { ArrowExitIcon, CoinCompetitionIcon } from "../../../img";
import TextField from "../../../components/ui/form/Fields/TextField";
import Button from "../../../components/ui/form/Buttons/Button";

interface ContestsViewWinnerWinProps {
    data: any;
    onBack?: () => void;
}

const ContestsViewWinnerWin = (
    {
        data,
        onBack
    }: ContestsViewWinnerWinProps
) => {
    const [commentValue, setCommentValue] = useState<string>("");

    function handleBack() {
        onBack && onBack();
    }

    function handleWinner() {
        const requestData = {
            list: data.selectUsersList,
            comment: commentValue
        }

        // $awardReward(requestData).then(res => {
        //     onClose();
        // })
    }

    return (
        <div className={"contests__winner contests__win"}>
            <div className={"contests__winner__header contests__win__header"}>
                <div className={"back"} onClick={handleBack}>
                    <ArrowExitIcon color={"#00A7B5"} size={14} strokeWidth={4} />
                </div>

                <h3>Победители</h3>
            </div>

            <div className={"contests__win__list"}>
                {data?.selectUsersList?.map((item: any, idx: number) => (
                    <div key={idx} className="layout-vacation-modal__block">
                        <div className="layout-vacation-modal__block__right">
                            <img src={item.image} alt="" />

                            <div className="layout-vacation-modal__block__desc">
                                <h1>{item.name}</h1>

                                <p className="layout-vacation-modal__block__desc__position">{item.position}</p>

                                <p className="layout-vacation-modal__block__desc__department">{item.department}</p>
                            </div>
                        </div>
                    </div>
                ))}
            </div>

            <div className={"contests__win__price"}>
                <CoinCompetitionIcon />

                <p>{data.points_win}</p>
            </div>

            <div className={"contests__win__comment"}>
                <TextField
                    value={commentValue}
                    onChange={setCommentValue}
                    title={"Причина победы"}
                    placeholder={"Причина"}
                    className={``}
                />
            </div>

            <div className={"contests__win__btns contests__winner__btns"}>
                <Button
                    text={"Начислить награду"}
                    className={"btn btn-primary w-100"}
                    onClick={handleWinner}
                />
            </div>
        </div>
    );
};

export default ContestsViewWinnerWin;