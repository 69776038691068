import { FC } from "react";
import { IBaseIconProps } from "../types";

export const ServicesEmailIcon: FC<IBaseIconProps> = ({
  size = 24,
  color = '#000',
}) => {
  return (
    <svg width={ size } height={ size } viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g opacity="0.3">
        <path
          d="M12.56 3.37012C18.08 3.37012 21.94 7.03012 21.94 12.1301C21.94 15.5901 20.5 17.6701 18.1 17.6701C16.82 17.6701 15.9 17.0701 15.56 15.9301C14.78 17.0701 13.56 17.6701 11.96 17.6701C9.2 17.6701 7.16 15.5701 7.16 12.5701C7.16 9.61012 9.2 7.51012 11.96 7.51012C13.36 7.51012 14.52 7.99012 15.3 8.93012V7.63012H17.52V14.4301C17.52 15.4501 18 15.7901 18.56 15.7901C19.64 15.7901 20.24 14.5301 20.24 12.1701C20.24 7.89012 17.12 5.01012 12.56 5.01012C7.92 5.01012 4.84 8.11012 4.84 12.4701C4.84 16.8901 7.88 19.9701 12.48 19.9701C13.7 19.9701 15.14 19.7101 16.36 19.0901L16.9 20.6901C15.68 21.3101 13.96 21.6301 12.48 21.6301C6.8 21.6301 3 17.7701 3 12.4701C3 7.23012 6.84 3.37012 12.56 3.37012ZM12.38 15.7301C14.06 15.7301 15.34 14.5701 15.34 12.5701C15.34 10.5901 14.06 9.45012 12.38 9.45012C10.64 9.45012 9.42 10.6501 9.42 12.5701C9.42 14.5101 10.64 15.7301 12.38 15.7301Z"
          fill={ color } />
      </g>
    </svg>

  )
}