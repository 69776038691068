import React, { FC } from "react";
import { IBaseIconProps } from "../types";

export const StarIcon: FC<IBaseIconProps> = (
    {
        strokeColor = "#1958FA",
        color = "",
        size = 20
    }
) => {
    return (
        <svg
            width={size}
            height={size}
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M7.05961 6.7796L9.18664 2.49608C9.24855 2.37212 9.34378 2.26785 9.46163 2.19497C9.57949 2.1221 9.71532 2.0835 9.85389 2.0835C9.99246 2.0835 10.1283 2.1221 10.2461 2.19497C10.364 2.26785 10.4592 2.37212 10.5211 2.49608L12.6482 6.7796L17.4033 7.47059C17.5404 7.48961 17.6695 7.54681 17.7757 7.63565C17.8819 7.7245 17.961 7.84142 18.0039 7.97306C18.0468 8.1047 18.0519 8.24576 18.0185 8.38014C17.9851 8.51453 17.9146 8.63681 17.8151 8.73305L14.3748 12.0652L15.187 16.7728C15.291 17.377 14.6524 17.8372 14.1063 17.5522L9.85389 15.3286L5.60066 17.5522C5.0554 17.838 4.4168 17.377 4.52078 16.772L5.33295 12.0644L1.89271 8.73223C1.79363 8.63593 1.72356 8.51376 1.69045 8.37962C1.65735 8.24548 1.66254 8.10474 1.70544 7.9734C1.74834 7.84206 1.82722 7.72539 1.93313 7.63665C2.03903 7.54791 2.1677 7.49066 2.30452 7.47141L7.05961 6.7796Z"
                stroke={strokeColor}
                fill={color}
                strokeWidth="1.6"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
};
