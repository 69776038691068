import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { getCurrentUser } from "../../../../../redux";
import { Skeleton } from "../../../../ui/skeleton";
import { $conciliatorsGet } from "../../../../../api/requests/vacation";

interface ConciliatorsBlockData {
    booking: any;
}

export const ConciliatorsBlock = ({ booking }: ConciliatorsBlockData) => {
    const currentUser = useSelector(getCurrentUser);

    const [conciliatorsLoading, setConciliatorsLoading] = useState(false);
    const [list, setList] = useState([]);

    useEffect(() => {
        if (booking?.id) {
            setConciliatorsLoading(true);
            $conciliatorsGet(booking?.id).then(res => {
                setConciliatorsLoading(false);

                setList(res?.data?.data);
            });
        }
    }, [booking?.id]);

    return (
        <div className={"calendar__booking-popup__conciliator__wrapper"}>
            {conciliatorsLoading && (
                <>
                    <Skeleton />
                    <Skeleton />
                    <Skeleton />
                    <Skeleton />
                </>
            )}

            {/*{!conciliatorsLoading &&*/}
            {/*    list.map((conciliator: any) => (*/}
            {/*        <UserSingle*/}
            {/*            key={conciliator?.id}*/}
            {/*            isShort*/}
            {/*            isConciliate={booking?.isConciliate || booking?.isShow}*/}
            {/*            isAccept={conciliator?.id === (booking?.conciliator || currentUser?.id) && booking?.isAccept}*/}
            {/*            isRefuse={conciliator?.id === (booking?.conciliator || currentUser?.id) && booking?.isRefuse}*/}
            {/*            {...{ for_user: conciliator }}*/}
            {/*        />*/}
            {/*    ))*/}
            {/*}*/}
        </div>
    );
};
