import React, { useMemo, useState } from "react";
import { ArrowExitIcon, SearchIcon } from "../../../img";
import { withDebounce } from "../../../functions";
import TextField from "../../../components/ui/form/Fields/TextField";
import Button from "../../../components/ui/form/Buttons/Button";
import ContestsViewWinnerWin from "./ContestsViewWinnerWin";
import ContestsViewWinnerExclude from "./ContestsViewWinnerExclude";

interface ContestsViewWinnerProps {
    data: any;
    onBack: () => void;
    onClose: () => void;
}

const ContestsViewWinner = (
    {
        data,
        onBack,
        onClose,
    }: ContestsViewWinnerProps
) => {

    const [departmentItems, setDepartmentItems] = useState([
        {
            id: 1,
            image: "/testImages/people/Image-1.png",
            name: "Краюшкин Александр Витальевич",
            position: "Руководитель",
            icon: "/testImages/statuses/statuses-1.png",
            department: "Департамент испытания и сертификации"
        },
        {
            id: 2,
            image: "/testImages/people/Image-2.png",
            name: "Смирнова Виалета Михайловна",
            position: "HR менеджер",
            icon: "/testImages/statuses/statuses-2.png",
            department: "Департамент испытания и сертификации"
        },
        {
            id: 3,
            image: "/testImages/people/Image-3.png",
            name: "Смирнова Виалета Михайловна",
            position: "HR менеджер",
            icon: "/testImages/statuses/statuses-3.png",
            department: "Департамент испытания и сертификации"
        },
        {
            id: 4,
            image: "/testImages/people/Image-4.png",
            name: "Смирнова Виалета Михайловна",
            position: "HR менеджер",
            icon: "/testImages/statuses/statuses-1.png",
            department: "Департамент испытания и сертификации"
        },
        {
            id: 5,
            image: "/testImages/people/Image-5.png",
            name: "Смирнова Виалета Михайловна",
            position: "HR менеджер",
            icon: "/testImages/statuses/statuses-2.png",
            department: "Департамент испытания и сертификации"
        }
    ]);
    const [filterUserList, setFilterUserList] = useState(departmentItems);

    const [selectedReplacer, setSelectedReplacer] = useState<number[]>([]);
    const [searchValue, setSearchValue] = useState<string>("");

    const [statusShow, setStatusShow] = useState("start");

    const updateData = useMemo(() => {
        const selectUsers = filterUserList.filter((item, idx) => selectedReplacer.includes(idx))

        return {
            ...data,
            selectUsersList: selectUsers
        }
    }, [selectedReplacer])

    function handleBack() {
        onBack();
    }

    function onChangeSearch(value: string) {
        setSearchValue(value);

        const updateList = [...departmentItems].filter(item => item.name.toLowerCase().includes(value.toLowerCase()));

        setFilterUserList(updateList);
    }

    function handleWinner() {
        setStatusShow("winner")
    }

    function handleSlice() {
        setStatusShow("start")
        const updateList = [...filterUserList].filter((item, idx) => !selectedReplacer.includes(idx));

        setFilterUserList(updateList);
        setSelectedReplacer([]);
    }

    function onChangeCheckbox(idx: number) {
        selectedReplacer.includes(idx) ?
            setSelectedReplacer((prev) => [...prev].filter(i => i !== idx)) :
            setSelectedReplacer((prev) => [...prev, idx])
    }

    if (statusShow === "winner") return (
        <ContestsViewWinnerWin
            onBack={() => setStatusShow("start")}
            data={updateData}
        />
    )

    if (statusShow === "exclude") return (
        <ContestsViewWinnerExclude
            data={updateData}
            onBack={() => setStatusShow("start")}
            onExclude={handleSlice}
            onClose={onClose}
        />
    )

    return (
        <div className={"contests__winner"}>
            <div className={"contests__winner__header"}>
                <div className={"back"} onClick={handleBack}>
                    <ArrowExitIcon color={"#00A7B5"} size={14} strokeWidth={4}/>
                </div>

                <h3>{data?.title}</h3>
            </div>

            <div className={"contests__winner__search"}>
                <TextField
                    value={searchValue}
                    onChange={(value: string) => {
                        setSearchValue(value);
                        withDebounce(() => onChangeSearch(value));
                    }}
                    placeholder={"Поиск"}
                    prepend={<SearchIcon color={"black"} />}
                    className={`search__block-textField`}
                />
            </div>

            <div className={"contests__winner__list"}>
                {filterUserList.map((item, idx) => (
                    <div key={idx} className="layout-vacation-modal__block">
                        <input
                            type="checkbox"
                            checked={selectedReplacer.includes(idx)}
                            onChange={() => onChangeCheckbox(idx)}
                        />

                        <div className="layout-vacation-modal__block__right">
                            <img src={item.image} alt="" />

                            <div className="layout-vacation-modal__block__desc">
                                <h1>{item.name}</h1>

                                <p className="layout-vacation-modal__block__desc__position">{item.position}</p>

                                <p className="layout-vacation-modal__block__desc__department">{item.department}</p>
                            </div>
                        </div>
                    </div>
                ))}
            </div>

            <div className={"contests__winner__btns"}>
                <Button
                    text={"Назначить победителя"}
                    className={"btn btn-primary w-100"}
                    onClick={handleWinner}
                    disabled={!selectedReplacer.length}
                />

                <Button
                    text={"Исключить"}
                    className={"btn btn-red w-100"}
                    onClick={() => setStatusShow("exclude")}
                    disabled={!selectedReplacer.length}
                />
            </div>
        </div>
    );
};

export default ContestsViewWinner;