import { ArrowExitIcon, CloseIcon, HamburgerIcon, SettingIcon } from "../../../img";
import { useLocation, useNavigate } from "react-router-dom";
import { Controller, useForm } from "react-hook-form";
import ImageField from "../../../components/ui/form/ImageField/ImageField";
import Dropdown from "../../../components/ui/form/Dropdown/Dropdown";
import React, { useState } from "react";
import TextField from "../../../components/ui/form/Fields/TextField";
import { IServicesField, IServicesStatuses } from "../../../api/requests/services/interface";
import PlusIcon2 from "../../../img/icons/vacation/PlusIcon2";
import {
  ServicesCreateStatusesModal,
  ServicesCreateTextModal,
  ServicesEmployeesModal
} from "../../../components/services";
import { DragDropContext, Draggable, Droppable } from "@hello-pangea/dnd";
import { IPollsAnswers } from "../../../api/requests/polls/interface";
import ToggleBlock from "../../../components/ui/form/Toggle/ToggleBlock";
import { stat } from "fs";
import Button from "../../../components/ui/form/Buttons/Button";
import { Simulate } from "react-dom/test-utils";
import dragOver = Simulate.dragOver;

interface DropdownItem {
  label: string;
  value: number | string;
}

export const ServicesCreatePage = () => {
  const location = useLocation();
  const pathname = location.pathname;
  const searchParams = new URLSearchParams(location.search);

  const navigate = useNavigate();
  const isMobile = window.screen.width < 1020;

  const purpose: string = pathname.includes('edit') ? 'edit' : 'create';

  const [ hashtags, setHashtags ] = useState([ '' ])
  const [ currentHashtag, setCurrentHashtag ] = useState('')

  const [ isOpenModal, setIsOpenModal ] = useState(0)

  const [ fieldsData, setFieldsData ] = useState<IServicesField[]>([ {
    id: 0,
    name: 'Test',
    type: 'Спорт',
    required: 1
  }
  ])
  const [ editedData, setEditedData ] = useState<any>()
  const [ statusesEditedData, setStatusesEditedData ] = useState<any>()

  const [ systemStatusesData, setSystemStatusesData ] = useState<IServicesStatuses[]>([
    {
      id: 6586,
      sample_id: 288,
      title: "Создана",
      type: 1,
      push_text: "Вы получили отклик на вакансию \"Специалист по внутренним коммуникациям (DevRel)\" Волгоград",
      mail_text: "Вы получили отклик на вакансию \"Специалист по внутренним коммуникациям (DevRel)\" Волгоград",
      created_at: "2023-11-22T04:16:37.000000Z",
      updated_at: "2023-11-22T04:16:37.000000Z",
      supervisor_notification_mail: 1,
      supervisor_notification_push: 0,
      executors_notification_mail: 1,
      executors_notification_push: 1,
      conciliator_notification_mail: 0,
      conciliator_notification_push: 0,
      name: "Создана"
    },
    {
      id: 6587,
      sample_id: 288,
      title: "Ожидает проверки",
      type: 2,
      push_text: '',
      mail_text: 'jklj',
      created_at: "2023-11-22T04:16:37.000000Z",
      updated_at: "2023-11-22T04:16:37.000000Z",
      supervisor_notification_mail: 1,
      supervisor_notification_push: 0,
      executors_notification_mail: 0,
      executors_notification_push: 0,
      conciliator_notification_mail: 0,
      conciliator_notification_push: 0,
      name: "Ожидает проверки"
    }
  ])

  const [ statusesData, setStatusesData ] = useState<IServicesStatuses[]>([])

  const [supervisorsData, setSupervisorsData] = useState([])
  const [executorsData, setExecutorsData] = useState([])
  const [conciliatorsData, setConciliatorData] = useState([])

  const [ categoryList, setCategoryList ] = useState<DropdownItem[]>([
    {
      label: "Спорт",
      value: 0
    },
    {
      label: "Инновации",
      value: 1
    },
    {
      label: "Творчество",
      value: 2
    }
  ]);

  const {
    handleSubmit,
    control,
    watch,
    register,
    reset,
    formState: {isValid},
    setValue,
  } = useForm<any>();

  const handleNavigation = () => {
    const pathArray = location.pathname.split('/');

    if (pathArray.length > 1) {
      pathArray.pop();
      const newPath = pathArray.join('/') || '/';
      navigate(newPath);
    } else {
      navigate('/');
    }
  }

  const pressChangeValue = (event: any) => {
    const value: string = event.target.value;

    if (event.key === 'Enter' && value) {
      setHashtags([ ...hashtags, value ])
      setCurrentHashtag('')
    }
  };

  const onDragEnd = (result: any) => {
    const {destination, draggableId, source, type} = result;

    if (!destination) {
      return;
    }

    if (destination.droppableId === source.droppableId && destination.index === source.index) {
      return;
    }

    if (type === 'group') {
      const sourceIndex = source.index;
      const targetIndex = destination.index;

      const workValue = [ ...statusesData ];
      const [ deletedItem ] = workValue.splice(sourceIndex, 1);
      workValue.splice(targetIndex, 0, deletedItem);

      setStatusesData(workValue);

      return;
    }
  };

  const handleDeleteInput = (
    idx: number,
    data: any,
    setData: any
  ) => {
    const updatedList = data && data?.filter((
      item: any,
      index: number
    ) => index !== idx)

    setData(updatedList)
  }

  const handleAddStatusBlock = () => {
    const lastId = statusesData.length > 0 ? statusesData[statusesData.length - 1].id : 0;
    const newItem = {
      id: lastId + 1,
      name: '',
      title: '',
      push_text: '',
      mail_text: '',
      supervisor_notification_mail: 0,
      supervisor_notification_push: 0,
      executors_notification_mail: 0,
      executors_notification_push: 0,
      conciliator_notification_mail: 0,
      conciliator_notification_push: 0,
    };

    setStatusesData([...statusesData, newItem]);
  }

  const handleChangeStatusesValue = (id: number, value: string) => {
    const updatedList = statusesData.map((item, idx) =>
      id === idx ? {...item, name: value, title: value} : item
    )

    setStatusesData(updatedList)
  }

  const getEmployeesModalData = (id: number) => {
    let obj: any;

    if (id === 5) {
      obj = {
        data: supervisorsData,
        dataToSet: setSupervisorsData,
      }
    }
    else if (id === 6) {
      obj = {
        data: executorsData,
        dataToSet: setExecutorsData,
      }
    }
    else if (id === 7) {
      obj = {
        data: conciliatorsData,
        dataToSet: setConciliatorData,
      }
    }

    return obj
  }

  function onSubmit(data: any) {
    console.log(data);
  }

  return (
    <div className='services-create'>
      <div className='layout-inner__header profile-header'>
        { !isMobile && (
          <div className='layout-inner__header__title'>
            <div className='header__exitIcon' onClick={ handleNavigation }>
              <ArrowExitIcon />
            </div>
            <h1> { purpose === 'edit' ? 'Редактирование' : 'Создать группу' }</h1>
          </div>
        ) }
      </div>

      <div className='services-create-content'>
        <div className='services-create-block'>
          <form
            onSubmit={ handleSubmit(onSubmit) }
            className="services-create-form"
          >
            <Controller
              name="image"
              control={ control }
              rules={ {
                required: {
                  value: true,
                  message: ""
                }
              } }
              render={ ({field: {onChange, value}}) => (
                <ImageField
                  upload={ value }
                  label="Обложка"
                  setUpload={ onChange }
                />
              ) }
            />

            <h1 className='services-create-form__title'>Основные</h1>

            <Controller
              name="type"
              control={ control }
              rules={ {
                required: {
                  value: true,
                  message: ""
                }
              } }
              render={ ({field: {onChange, value}}) => (
                <Dropdown
                  className={ "form-select" }
                  placeholder={ "Тип сервиса" }
                  label={ "Тип сервиса" }
                  options={ [
                    {
                      label: "Все",
                      value: "all"
                    },
                    ...categoryList
                  ] }
                  value={ value }
                  onChange={ onChange }
                />
              ) }
            />

            <Controller
              name="category_title"
              control={ control }
              rules={ {
                required: {
                  value: true,
                  message: ""
                }
              } }
              render={ ({field: {onChange, value}}) => (
                <Dropdown
                  className={ "form-select" }
                  placeholder={ "Родительский сервис" }
                  label={ "Родительский сервис" }
                  options={ [
                    {
                      label: "Все",
                      value: "all"
                    },
                    ...categoryList
                  ] }
                  value={ value }
                  onChange={ onChange }
                />
              ) }
            />

            <Controller
              name="cities"
              control={ control }
              rules={ {
                required: {
                  value: true,
                  message: ""
                }
              } }
              render={ ({field: {onChange, value}}) => (
                <Dropdown
                  isMulti
                  className={ "form-select" }
                  placeholder={ "Город" }
                  label={ "Город" }
                  options={ [
                    {
                      label: "Все",
                      value: "all"
                    },
                    ...categoryList
                  ] }
                  value={ value }
                  onChange={ onChange }
                />
              ) }
            />

            <div className='services-create-form__hashtags'>
              <Controller
                name="title"
                control={ control }
                rules={ {
                  required: {
                    value: true,
                    message: ""
                  }
                } }
                render={ ({field: {value}}) => (
                  <TextField
                    value={ currentHashtag }
                    className=""
                    title="Хэштеги"
                    name="title"
                    onChange={ () => setCurrentHashtag(value) }
                    onKeyPress={ (e: any) => pressChangeValue(e) }
                  />
                ) }
              />

              <p className='services-create-form__hashtags__warning'>
                Введите ключевое слово и нажмите Enter
              </p>

              <div className='services-create-form__hashtags__values'>
                { hashtags?.map((
                  value,
                  idx
                ) => (
                  <div key={ idx }>
                    { value && (
                      <div className='services-create-form__hashtags__values__item'>
                        <p>#{ value }</p>

                        <div
                          onClick={ () =>  handleDeleteInput(idx, hashtags, setHashtags) }
                          className='services-create-form__hashtags__values__delete'>
                          <CloseIcon color='#00A7B5' />
                        </div>
                      </div>
                    ) }
                  </div>
                )) }
              </div>
            </div>

            <span className='services-create-form__line'></span>

            <h1 className='services-create-form__title'>Форма</h1>

            <div className='services-create-form-field'>
              <p className='services-create-form-field__label'>Текстовое поле</p>

              { fieldsData.map((
                item,
                idx
              ) => (
                <div className='services-create-form-field__block' key={ idx }>
                  <input
                    type="text"
                    onChange={ (e) => '' }
                    disabled
                    value={ item.name }
                    className='services-create-form-field__input'
                  />

                  <div className='services-create-form-field__input__right'>
                    <div className='services-create-form-field__input__button'
                         onClick={ () => {
                           setEditedData(item)
                           setIsOpenModal(2)
                         } }
                    >
                      <SettingIcon color='#00A7B5' />
                    </div>

                    <div
                      className='services-create-form-field__input__button'
                      onClick={ () => handleDeleteInput(idx, fieldsData, setFieldsData) }>
                      <CloseIcon color='#FF0000' width={ 15 } height={ 15 } />
                    </div>
                  </div>
                </div>
              )) }

              <div className='services-create-form-field__button' onClick={ () => setIsOpenModal(1) }>
                <PlusIcon2 color='#007BFF' />
              </div>
            </div>

            <span className='services-create-form__line'></span>

            <h1 className='services-create-form__title'>Статусы заявки</h1>

            <div className='services-create-form-field'>
              <p className='services-create-form-field__label'>Системные статусы</p>

              { systemStatusesData.map((
                item,
                idx
              ) => (
                <div className='services-create-form-field__block' key={ idx }>
                  <input
                    type="text"
                    disabled
                    onChange={ (e) => '' }
                    value={ item.name }
                    className='services-create-form-field__input'
                  />

                  <div className='services-create-form-field__input__right'>
                    <div className='services-create-form-field__input__button'
                         onClick={ () => {
                           setStatusesEditedData(item)
                           setIsOpenModal(3)
                         } }
                    >
                      <SettingIcon color='#00A7B5' />
                    </div>
                  </div>
                </div>
              )) }
            </div>

            <div className='services-create-form-field'>
              <p className='services-create-form-field__label'>Пользовательские статусы</p>

              <DragDropContext onDragEnd={ onDragEnd }>
                <Droppable droppableId='ROOT' type="group">
                  { (provided) => (
                    <div
                      { ...provided.droppableProps }
                      ref={ provided.innerRef }
                      className='services-create-form-field'

                    >
                      { statusesData.map((
                        item: IServicesStatuses,
                        idx: number
                      ) => (
                        <Draggable
                          draggableId={ `items-${ idx }-block-${ item?.id }` }
                          key={ `items-${ idx }-block-${ item?.id }` }
                          index={ idx }
                        >
                          { (_provided) => (
                            <div
                              { ..._provided.draggableProps }
                              { ..._provided.dragHandleProps }
                              ref={ _provided.innerRef }
                              className='services-create-form-field__block'
                            >
                              <div className='polls-create-answers__block__left'>
                                <HamburgerIcon />
                                <input
                                  type="text"
                                  onChange={ (e) => handleChangeStatusesValue(idx, e.target.value) }
                                  value={ item.name }
                                  className='services-create-form-field__input'
                                />
                              </div>


                              <div className='services-create-form-field__input__right'>
                                <div className='services-create-form-field__input__button'
                                     onClick={ () => {
                                       setStatusesEditedData(item)
                                       setIsOpenModal(4)
                                     } }
                                >
                                  <SettingIcon color='#00A7B5' />
                                </div>

                                <div
                                  className='services-create-form-field__input__button'
                                  onClick={ () => handleDeleteInput(idx, statusesData, setStatusesData)  }>
                                  <CloseIcon color='#FF0000' width={ 15 } height={ 15 } />
                                </div>
                              </div>
                            </div>
                          ) }
                        </Draggable>
                      )) }

                      { provided.placeholder }

                      <div className='polls-create-answers__button' onClick={ () => handleAddStatusBlock() }>
                        <PlusIcon2 color={ '#007BFF' } size={ 32 } />
                      </div>
                    </div>
                  ) }
                </Droppable>
              </DragDropContext>
            </div>

            <span className='services-create-form__line'></span>

            <h1 className='services-create-form__title'>Руководитель заявки</h1>

            <div className='services-create-form__employees'>
              {supervisorsData.length > 0 && (
                <div className={"services-create-form__employees__list"}>
                  {supervisorsData?.map((item: any, idx: number) => (
                    <div key={idx} className="layout-vacation-modal__block">
                      <div className="layout-vacation-modal__block__right">
                        <img src={item.image} alt="" />

                        <div className="layout-vacation-modal__block__desc">
                          <h1>{item.name}</h1>

                          <p className="layout-vacation-modal__block__desc__position">{item.position}</p>

                          <p className="layout-vacation-modal__block__desc__department">{item.department}</p>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              )}

              <div className={'services-create-form__employees__bottom'}>
                <div className='services-create-form__employees__addButton' onClick={() => setIsOpenModal(5)}>
                  <PlusIcon2 />
                </div>
              </div>
            </div>

            <span className='services-create-form__line'></span>

            <h1 className='services-create-form__title'>Исполнители заявки</h1>

            <div className='services-create-form__employees'>
              {executorsData.length > 0 && (
                <div className={"services-create-form__employees__list"}>
                  {executorsData?.map((item: any, idx: number) => (
                    <div key={idx} className="layout-vacation-modal__block">
                      <div className="layout-vacation-modal__block__right">
                        <img src={item.image} alt="" />

                        <div className="layout-vacation-modal__block__desc">
                          <h1>{item.name}</h1>

                          <p className="layout-vacation-modal__block__desc__position">{item.position}</p>

                          <p className="layout-vacation-modal__block__desc__department">{item.department}</p>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              )}

              <div className={'services-create-form__employees__bottom'}>
                <div className='services-create-form__employees__addButton' onClick={() => setIsOpenModal(6)}>
                  <PlusIcon2 />
                </div>
              </div>
            </div>

            <span className='services-create-form__line'></span>

            <h1 className='services-create-form__title'>Согласанты</h1>

            <div className='services-create-form__employees'>
              {conciliatorsData.length > 0 && (
                <div className={"services-create-form__employees__list"}>
                  {conciliatorsData?.map((item: any, idx: number) => (
                    <div key={idx} className="layout-vacation-modal__block">
                      <div className="layout-vacation-modal__block__right">
                        <img src={item.image} alt="" />

                        <div className="layout-vacation-modal__block__desc">
                          <h1>{item.name}</h1>

                          <p className="layout-vacation-modal__block__desc__position">{item.position}</p>

                          <p className="layout-vacation-modal__block__desc__department">{item.department}</p>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              )}

              <div className={'services-create-form__employees__bottom'}>
                <div className='services-create-form__employees__addButton' onClick={() => setIsOpenModal(7)}>
                  <PlusIcon2 />
                </div>
              </div>
            </div>

            <span className='services-create-form__line'></span>

            <Controller
              name="is_active"
              control={ control }
              rules={ {
                required: {
                  value: true,
                  message: ""
                }
              } }
              render={ ({field: {onChange, value}}) => (
                <ToggleBlock
                  isChecked={ value }
                  desc="Отображать заявку"
                  handleChange={ onChange }
                />
              ) }
            />

            <Controller
              name="is_pinned"
              control={ control }
              rules={ {
                required: {
                  value: true,
                  message: ""
                }
              } }
              render={ ({field: {onChange, value}}) => (
                <ToggleBlock
                  isChecked={ value }
                  desc="Закрепить заявку"
                  handleChange={ onChange }
                />
              ) }
            />

            <Controller
              name="is_private"
              control={ control }
              rules={ {
                required: {
                  value: true,
                  message: ""
                }
              } }
              render={ ({field: {onChange, value}}) => (
                <ToggleBlock
                  isChecked={ value }
                  desc="Данные доступны только отправителю и исполнителю"
                  handleChange={ onChange }
                />
              ) }
            />

            <Controller
              name="is_hidden"
              control={ control }
              rules={ {
                required: {
                  value: true,
                  message: ""
                }
              } }
              render={ ({field: {onChange, value}}) => (
                <ToggleBlock
                  isChecked={ value }
                  desc="Работает, но скрыт от пользователей"
                  handleChange={ onChange }
                />
              ) }
            />


            <div className='services-create-buttons'>
              <div className={"d-flex gap-3"}>
                  <Button
                    text={"Отмена"}
                    className={"btn btn-light w-100"}
                    onClick={() => ''}
                  />


                <Button
                  type={"submit"}
                  text={'Создать'}
                  className={"btn btn-primary w-100"}
                />
              </div>
            </div>
          </form>
        </div>
      </div>

      <ServicesCreateTextModal
        isOpen={ isOpenModal === 1 || isOpenModal === 2 }
        onClose={ () => {
          setIsOpenModal(0)
          setEditedData({})
        } }
        purpose={ isOpenModal === 1 ? 'add' : 'edit' }
        fieldsData={ fieldsData }
        setFieldsData={ setFieldsData }
        editedData={ editedData }
      />

      <ServicesCreateStatusesModal
        isOpen={ isOpenModal === 3 || isOpenModal === 4 }
        onClose={ () => setIsOpenModal(0) }
        purpose={ 'edit'}
        statusesData={isOpenModal === 3 ? systemStatusesData : statusesData }
        setStatusesData={ isOpenModal === 3 ? setSystemStatusesData : setStatusesData}
        editedData={statusesEditedData}
        setEditedData={ setStatusesEditedData}
      />

      <ServicesEmployeesModal
        isOpen={isOpenModal === 5 || isOpenModal === 6 || isOpenModal === 7}
        onClose={() => setIsOpenModal(0)}
        data={getEmployeesModalData(isOpenModal)?.data}
        setData={getEmployeesModalData(isOpenModal)?.dataToSet}
      />
    </div>
  )
}