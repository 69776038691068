import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getHolidayList, setMonth, setPopupData } from "../../../../../redux";
import { isMd } from "../../../../../utils";
import moment from "moment";
import { getNoun } from "../../../../../utils/field/getNoun";
import DatePickerForm from "../../../../ui/form/DatePickerForm";

interface DateRangeBlockData {
  user: any;
  booking: any;
  setBooking: (value: any) => void;
  isError: boolean | string;
  errorMessage: string;
}

export const DateRangeBlock = (
    {
      user,
      booking,
      setBooking,
      isError,
      errorMessage
    }: DateRangeBlockData
) => {
  const isMobile = isMd();

  const dispatch = useDispatch();
  const holidayList = useSelector(getHolidayList);

  const [holidayDates, setHolidayDates] = useState<any[]>([]);
  const [holidayCount, setHolidayCount] = useState(0);

  const handleMonth = (date: string) => {
    dispatch(setMonth(moment(date).format('YYYY-MM-DD')));
  };

  const handleOpenEditPopup = () => {
    handleMonth(user?.start_date);

    dispatch(
      setPopupData({
        ...user,
        isEdit: true,
        isConciliate: false,
        isAccept: false,
        isRefuse: false,
        isCancel: false,
      }),
    );
  };

  const handleFillDates = (startDate: any, endDate: any) => {
    const dates = [];

    const currentDate = startDate.clone();

    while (currentDate.isSameOrBefore(endDate)) {
      dates?.push(currentDate.format('YYYY-MM-DD'));

      currentDate.add(1, 'day');
    }

    return dates;
  };

  const getDateByTimeStamp = (date: string) => {
    const dateObject = new Date(date);

    return Math.floor(dateObject.getTime() / 1000);
  }

  useEffect(() => {
    holidayList?.forEach((holiday: any) => {
      if (holiday?.start_date === holiday?.end_date) {
        setHolidayDates((prev: any): any[] => {
          return [...prev, holiday?.start_date];
        });

        return;
      }

      setHolidayDates((prev: any) => {
        return [...prev, ...handleFillDates(moment(holiday?.start_date), moment(holiday?.end_date))];
      });
    });
  }, []);

  useEffect(() => {
    if (holidayDates?.length > 0) {
      setHolidayCount(0);

      holidayDates?.forEach((holiday) => {
        if (
          moment(holiday).isSameOrAfter(moment(booking?.request?.start_date)) &&
          moment(holiday).isSameOrBefore(moment(booking?.request?.end_date))
        ) {
          setHolidayCount((prev) => prev + 1);
        }
      });
    }
  }, [holidayDates, booking?.request?.start_date, booking?.request?.end_date]);

  console.log(booking, 'booking');

  return (
    <div className={"calendar__date-range__block__wrapper"}>
      {((isMobile && (booking?.isEdit || booking?.isShow)) ||
        (isMobile && !booking?.isEdit && !booking?.isShow && !booking?.isConciliate) ||
        !isMobile) && (
        <div className={"calendar__date-range__block__block"}>
          <div className={"calendar__date-range__block__field"}>
            <DatePickerForm
                label={"Дата начала"}
                value={booking?.request?.start_date}
                onChange={setBooking}
            />
          </div>

          <div className={"calendar__date-range__block__field"}>
            <DatePickerForm
                label={"Дата окончания"}
                value={booking?.request?.end_date}
                onChange={setBooking}
            />
          </div>

          {isError && <div className={"calendar__date-range__block__error"}>{errorMessage}</div>}
        </div>
      )}

      {/*{isMobile && booking?.isAdmin && !booking?.isEdit && !booking?.isShow && booking?.isConciliate && (*/}
      {/*  <UserDate*/}
      {/*    indicator={user?.indicator}*/}
      {/*    startDate={user?.start_date}*/}
      {/*    endDate={user?.end_date}*/}
      {/*    handleAction={handleOpenEditPopup}*/}
      {/*  />*/}
      {/*)}*/}

      {isMobile && booking?.created && (
        <div className={"calendar__date-range__block__note calendar__date-range__block__noteFull"}>
          <div className={"calendar__date-range__block__noteDay"}>{moment(booking?.created).format('DD MMMM - HH:mm')}</div>

          <div className={"calendar__date-range__block__noteText"}>Забронировано</div>
        </div>
      )}

      {!!booking?.daysCount && (
        <div className={"calendar__date-range__block__block"}>
          <div className={"calendar__date-range__block__note calendar__date-range__block__noteFull"}>
            <div className={"calendar__date-range__block__noteDay"}>
              {`${booking?.daysCount} ${getNoun(booking?.daysCount, 'день', 'дня', 'дней')}`}
            </div>

            <div className={"calendar__date-range__block__noteText"}>Количество запланированных дней</div>
          </div>
        </div>
      )}

      <div className={"calendar__date-range__block__block"}>
        <div className={"calendar__date-range__block__note calendar__date-range__block__noteFull"}>
          <div className={"calendar__date-range__block__noteDay"}>
            {`${booking?.additionalDays || 0} ${getNoun(
              booking?.additionalDays || 0,
              'день',
              'дня',
              'дней',
            )}`}
          </div>

          <div className={"calendar__date-range__block__noteText"}>Доступно для бронирования</div>
        </div>
      </div>

      {holidayCount > 0 && (
        <div className={"calendar__date-range__block__holiday"}>
          {`Праздничных дней на этот срок бронирования: ${holidayCount} ${getNoun(
            holidayCount,
            'день',
            'дня',
            'дней',
          )}`}
        </div>
      )}
    </div>
  );
};
