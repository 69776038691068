import { useState } from "react";
import { BadgesCard, ProfileCard, StatsCard, EmployeesCard } from "../../components/layout-components";
import { UserProfileIcon, UsersProfilesIcon } from "../../img";
import Dropdown from "../../components/ui/form/Dropdown/Dropdown";
import MainLayoutContent from "../../layouts/MainLayoutContent";

export const WorkspacePage = () => {
    const [data, setData] = useState([
        {
            id: 1,
            title: 'Рабочие места',
            icon: <UserProfileIcon/>
        },
        {
            id: 2,
            title: 'Переговорная',
            icon: <UsersProfilesIcon/>
        }
    ])
    return (
        <MainLayoutContent
            leftComponents={
                <>
                    <ProfileCard />
                    <StatsCard />
                    <EmployeesCard/>
                    <BadgesCard/>
                </>
            }
        >
            <div className='workspace'>
            <div className='workspace-header'>
                <h1>Рабочие места</h1>

                <Dropdown
                    className='workspace-select'
                    placeholder={'Выбрать город'}
                    options={[]}
                    onChange={() => ''}
                />
                {/*<AppFiltersCity isSelected={isSelected} selectHandler={() => ('')} {...toggleProps}/>*/}
            </div>

            <div className='workspace-content'>
                {data.map((item) => (
                    <div key={item.id} className='workspace-block'>
                        {item.icon}

                        <h3>{item.title}</h3>
                    </div>
                ))}
            </div>
        </div>
        </MainLayoutContent>
    )
}