import { useNavigate, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { ArrowExitIcon, EyeIcon, ShareIcon } from "../../../img";
import { $getSportList } from "../../../api/requests/sport";
import TrainingContentItem from "../TrainingContentItem";
import {
    SportDateData,
    SportItemData,
    SportResponseDateData, SportViewItemData
} from "../../../api/requests/sport/interface";

const daysOfWeek = ["вс", "пн", "вт", "ср", "чт", "пт", "сб"];

const TrainingSessionViewPage = () => {
    const isMobile = window.screen.width < 1020;

    const navigate = useNavigate();
    const { id } = useParams();

    const [ trainingSessionData, setTrainingSessionData ] = useState<SportViewItemData | null>(null)

    const transformDateData = (data: SportResponseDateData[]): SportDateData[] => {
        return data.map((item) => {
            const dateObj = new Date(item.date);
            const weekDay = daysOfWeek[dateObj.getDay()];
            const formattedDate = `${String(dateObj.getDate()).padStart(2, '0')}.${String(dateObj.getMonth() + 1).padStart(2, '0')}`; // Format date as dd.mm
            const time = `${item.start} - ${item.end}`;

            return {
                week: weekDay,
                date: formattedDate,
                time: time,
            };
        });
    }

    const transformContentData = (item: SportItemData): SportViewItemData => {
        return {
            ...item,
            date: transformDateData(item.date)
        }
    }

    useEffect(() => {
        $getSportList().then(res => {
            if (!res.data) return;

            const getNews = res.data.find((item: any) => item.id == id)

            getNews && setTrainingSessionData(transformContentData(getNews))
        })
    }, [])

    return (
        <div className='news-show'>
            <div className='layout-inner__header profile-header'>
                { !isMobile && (
                    <div className='layout-inner__header__title'>
                        <div className='header__exitIcon' onClick={ () => navigate(-1) }>
                            <ArrowExitIcon />
                        </div>
                        <h1>{ trainingSessionData?.title }</h1>
                    </div>
                ) }
            </div>

            <div className='news-show-content'>
                <div className='news-show-content__left'>
                    <div className='sport__view'>
                        <div className='sport__view__content'>
                            {trainingSessionData && <TrainingContentItem {...trainingSessionData}/>}

                            <div className='news-show-info__bottom'>
                                <div className='news-show-info__buttons'>
                                    <button className='news-show-info__buttons__likeButton'>
                                        <p>Мне нравиться</p>
                                    </button>

                                    <button className='news-show-info__buttons__shareButton'>
                                        <ShareIcon size={24} color='#00A7B5'/> <p>Мне нравиться</p>
                                    </button>
                                </div>

                                <div className='news-show-info__stats'>
                                    {trainingSessionData?.views && (
                                        <div className="news-show-info__stats__block">
                                            <EyeIcon color="#000" size={20} />

                                            <p>{trainingSessionData.views}</p>
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default TrainingSessionViewPage;