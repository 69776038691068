
interface CalendarDaysProps {
    days?: number
}

type WeekDay = {
    number: number;
    isToday: boolean;
    date: string;
};

const CalendarDays = (
    {
        days = 7
    }: CalendarDaysProps
) => {

    const getCurrentWeek = (): WeekDay[] => {
        const daysOfWeek = ["пн", "вт", "ср", "чт", "пт", "сб", "вс"];

        const today = new Date();

        const currentDay = today.getDay();

        const monday = new Date(today);
        const diff = (currentDay === 0 ? -6 : 1) - currentDay;
        monday.setDate(today.getDate() + diff);

        return Array.from({ length: days }, (_, i) => {
            const day = new Date(monday);
            day.setDate(monday.getDate() + i);

            return {
                number: day.getDate(),
                isToday: day.toDateString() === today.toDateString(),
                date: daysOfWeek[i]
            };
        });
    };

    return (
        <div className={"calendar__days__items"}>
            {getCurrentWeek().map(({ isToday, number, date }, idx) => (
                <div
                    key={`day-item-${idx}`}
                    className={`calendar__days__item ${isToday ? "active" : ""}`}>
                    <div
                        className={"calendar__days__item-number"}>{number}</div>
                    <p className={"calendar__days__item-text"}>{date}</p>
                </div>
            ))}
        </div>
    )
}

export default CalendarDays;