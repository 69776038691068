import ContentLoader from 'react-content-loader';
import { useSelector } from "react-redux";
import { getProfileVarsData } from "../../../redux";

export const SingleSkeleton = () => {
  const vars = useSelector(getProfileVarsData);

  return (
    <ContentLoader
      title=''
      height='112'
      backgroundColor='#E3E3E3'
      foregroundColor='#F9F9F9'
      gradientRatio={1}
      style={{
        width: '100%',
        height: '112px',
        backgroundColor: vars.colors.fourthColor,
        padding: '10px 15px',
      }}
    >
      <rect rx='50%' ry='50%' width='40' height='40' />

      <rect
          x='calc(40px + 14px)'
          y='5'
          rx='2'
          ry='2'
          width='70%'
          height='10'
      />

      <rect
          x='calc(40px + 14px)'
          y='calc(5px + 10px + 12px)'
          rx='2'
          ry='2'
          width='40%'
          height='8'
      />

      <rect
        x='calc(40px + 14px)'
        y='calc(5px + 10px + 12px + 8px + 18px)'
        rx='2'
        ry='2'
        width='45%'
        height='8'
      />

      <rect
        x='calc(40px + 14px)'
        y='calc(5px + 10px + 12px + 8px + 18px + 8px + 12px)'
        rx='2'
        ry='2'
        width='55%'
        height='8'
      />
    </ContentLoader>
  );
};
