import { IBaseIconProps } from "../types";

export const ArrowExitIcon = (
    {
        color = "#000",
        size = 24,
        strokeWidth = 2
    }: IBaseIconProps
) => {
    return (
        <svg
            width={size}
            height={size}
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path
                d="M21 12H3M3 12L11.5 3.5M3 12L11.5 20.5"
                stroke={color}
                strokeWidth={strokeWidth}
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
};
