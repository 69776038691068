export const PictureIcon = (
    {
        color = "white"
    }
) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
        >
            <path
                d="M6.69714 20.9343L15.7557 12.4086L20.0186 16.6714M6.69714 20.9343H17.3543C19.12 20.9343 20.5514 19.5029 20.5514 17.7371V12.4086M6.69714 20.9343C4.93141 20.9343 3.5 19.5029 3.5 17.7371V7.08001C3.5 5.31427 4.93141 3.88287 6.69714 3.88287H13.6243M19.4857 9.02859L19.4857 6.01429M19.4857 6.01429L19.4857 3M19.4857 6.01429L16.4714 6.01429M19.4857 6.01429L22.5 6.01429M9.89428 8.67858C9.89428 9.56144 9.17858 10.2771 8.29571 10.2771C7.41285 10.2771 6.69714 9.56144 6.69714 8.67858C6.69714 7.79571 7.41285 7.08001 8.29571 7.08001C9.17858 7.08001 9.89428 7.79571 9.89428 8.67858Z"
                stroke={color}
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    )
}
