import { FC, useEffect, useState } from "react";
import { ru } from "date-fns/locale/ru";
import "moment/locale/ru";
import DatePicker from "react-datepicker";
import { FieldErrors } from "react-hook-form";
import "react-datepicker/dist/react-datepicker.css";
import { CalendarIcon } from "../../../../img/icons/calendar/CalendarIcon";

type DateInterface = Date | undefined;

export interface dataPickerType {
    birthDate: Date;
}

interface DatePickerForm {
    startDate?: DateInterface;
    onChange: (date: Date) => void;
    isIcon?: boolean;
    placeholder?: string;
    isRightIcon?: boolean;
    isTimeSelect?: boolean;
    isMonthSelect?: boolean;
    className?: string;
    disabled?: boolean;
    minDate?: Date | undefined;
    maxDate?: Date | undefined;
    name?: string;
    errors?: FieldErrors<dataPickerType>;
    value?: string;
    label?: string;
}

const DatePickerForm: FC<DatePickerForm> = (
    {
        startDate,
        onChange,
        isIcon = false,
        placeholder = "",
        isRightIcon = false,
        isTimeSelect = false,
        isMonthSelect = false,
        className,
        disabled,
        minDate,
        maxDate,
        errors,
        name,
        value,
        label
    }
) => {
    const dateStart = (date: any): DateInterface => {
        if (typeof date === "string") return new Date(date);

        return startDate || undefined;
    };

    const [startDateLocal, setStartDate] = useState<DateInterface>(
        dateStart(startDate)
    );
    const [endDate, setEndDate] = useState<DateInterface>(undefined);

    useEffect(() => {
        // if (startDateLocal === null && startDate) setStartDate(dateStart(startDate))
        if (dateStart(startDate) !== startDateLocal)
            setStartDate(dateStart(startDate));
    }, [startDate]);

    const onDateRange = (value: DateInterface) => {
        onChange(value || new Date());
    };

    const getDateFormatText = (): string => {
        if (isTimeSelect) return "dd.MM.yyyy HH:mm";

        if (isMonthSelect) return "MM/yyyy";

        return "dd.MM.yyyy";
    };

    const renderMonthContent = (
        monthIndex: number,
        shortMonthText: string,
        fullMonthText: string
    ) => {
        const tooltipText = `Подсказка: ${fullMonthText}`;

        return <span title={tooltipText}>{shortMonthText}</span>;
    };

    return (
        <div className={"form-group calendar-block"}>
            {label && (
                <label className={"form-label"}>
                    {label}
                </label>
            )}

            <div className={"calendar-block__date-picker"}>
                {isIcon && (
                    <div
                        className={`calendar-block__icon ${isRightIcon ? "right" : "left"}`}
                    >
                        <CalendarIcon />
                    </div>
                )}

                <DatePicker
                    locale={ru}
                    className={`${className} form-control form-datepicker`}
                    selected={startDateLocal}
                    value={value}
                    dateFormat={getDateFormatText()}
                    wrapperClassName="w-100"
                    onChange={(date: Date | null) => {
                        onDateRange(date || undefined);

                        setStartDate(date || undefined);
                    }}
                    selectsStart
                    startDate={startDateLocal}
                    endDate={endDate}
                    placeholderText={placeholder}
                    minDate={minDate}
                    maxDate={maxDate}
                    showTimeSelect={isTimeSelect}
                    timeCaption="Время"
                    timeFormat="HH:mm"

                    renderMonthContent={renderMonthContent}
                    showMonthYearPicker={isMonthSelect}
                    disabled={disabled}
                    showYearDropdown
                    showMonthDropdown
                    yearDropdownItemNumber={50}
                    scrollableYearDropdown
                />
            </div>

            {errors?.[name as keyof dataPickerType] && (
                <div className="text-[12px] leading-[14.06px] flex flex-col">
                    <p className="text-red-default mb-[5px]">
                        {errors?.[name as keyof dataPickerType]?.message}
                    </p>
                </div>
            )}
        </div>
    );
};

export default DatePickerForm;
