import { IBaseIconProps } from "../types";

export const TickIcon = (
    {
        size = 24,
        color = "#fff"
    }: IBaseIconProps
) => {
    return (
        <svg
            width={size} height={+size - 1}
            viewBox="0 0 13 11"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M11.0531 1.89999L3.89361 9.09999L1.45312 6.64571" stroke={color}
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
};