import React, { useMemo, useState } from "react";
import DialogContainer from "../../../components/ui/dialog/DialogContainer";
import Button from "../../../components/ui/form/Buttons/Button";
import ContestCreateEditPreview from "../ContestsCreateEdit/ContestCreateEditPreview";
import { CloseIcon } from "../../../img";
import {
    CompetitionsApplicationCard
} from "../../../components/competitions/CompetitionsApplicationCard";
import FileClipField from "../../../components/ui/form/FileField/FileClipField";

interface ContestsViewApplicationProps {
    isOpen: boolean;
    onClose: () => void;
    data?: any;
}

const ContestsViewApplication = (
    {
        data,
        isOpen,
        onClose,
    }: ContestsViewApplicationProps
) => {
    function handleCloseModal() {
        onClose();
    }

    function handleUploadTable() {
        onClose();
    }

    return (
        <DialogContainer
            isOpen={isOpen}
            setIsOpen={handleCloseModal}
            isShowCloseBtn={false}
            classNameModal={"p-0"}
            widthProps={427}
        >
            <div className={"contests__view"}>
                <div className={"contests__view__close"} onClick={handleCloseModal}>
                    <CloseIcon color={"black"} />
                </div>

                <CompetitionsApplicationCard
                    data={data}
                />

                <div className="contests__view__application__content">
                    <FileClipField
                        label={"Прикрепите файл"}
                        upload={null}
                        uploadName={data?.file?.name}
                        className={"form-file"}
                        isLockFile
                    />
                </div>

                <div className={"contests__view__application__btn"}>
                    <Button
                        text={"Принять"}
                        className={"btn btn-primary w-100"}
                        onClick={handleUploadTable}
                    />

                    <Button
                        text={"Отклонить"}
                        className={"btn btn-red w-100"}
                        onClick={handleUploadTable}
                    />
                </div>
            </div>
        </DialogContainer>
    );
};

export default ContestsViewApplication;