import MainLayoutContent from "../../layouts/MainLayoutContent";
import { useEffect, useState } from "react";
import { IBlogsRes } from "../../api/requests/blogs/interface";
import { $getBlogs } from "../../api/requests/blogs";
import { BlogsCard } from "../../components/blogs";

export const BlogsPage = () => {
    const [ data, setData ] = useState<IBlogsRes[]>([])

    useEffect(() => {
        $getBlogs().then(res => {
            if (!res.data) return;

            setData(res.data)
        })
    }, [])
    return (
        <div className='blogs'>
            { data.map((
                blog,
                idx
            ) => (
                <div className={ `blogs-item ${ idx !== data.length - 1     ? 'blogs-item-line' : '' }` }
                     key={ idx }
                >
                    <BlogsCard data={ blog } />
                </div>
            )) }
        </div>
    )
}