export const PercentageIcon = (
    {
        color = "black",
        size = 24,
        opacity = 0.5
    }
) => (
    <svg
        width={size}
        height={size}
        viewBox="0 0 25 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <g opacity={opacity}>
            <path
                d="M8.07812 4C6.15697 4 4.57812 5.57885 4.57812 7.5C4.57812 9.42115 6.15697 11 8.07812 11C9.99928 11 11.5781 9.42115 11.5781 7.5C11.5781 5.57885 9.99928 4 8.07812 4ZM17.5783 5.00013C17.1284 4.67863 16.5031 4.78278 16.1817 5.23274L7.34539 17.6036C7.02409 18.0534 7.1282 18.6785 7.57794 18.9999C8.02782 19.3214 8.65316 19.2172 8.97456 18.7673L17.8109 6.39643C18.1322 5.94662 18.028 5.32153 17.5783 5.00013ZM8.07812 6C8.9184 6 9.57812 6.65973 9.57812 7.5C9.57812 8.34027 8.9184 9 8.07812 9C7.23785 9 6.57812 8.34027 6.57812 7.5C6.57812 6.65973 7.23785 6 8.07812 6ZM17.0781 13C15.157 13 13.5781 14.5788 13.5781 16.5C13.5781 18.4212 15.157 20 17.0781 20C18.9993 20 20.5781 18.4212 20.5781 16.5C20.5781 14.5788 18.9993 13 17.0781 13ZM17.0781 15C17.9184 15 18.5781 15.6597 18.5781 16.5C18.5781 17.3403 17.9184 18 17.0781 18C16.2379 18 15.5781 17.3403 15.5781 16.5C15.5781 15.6597 16.2379 15 17.0781 15Z"
                fill={color}
            />
        </g>
    </svg>
);
