import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { isLg } from "../../../../utils";
import { DragDropContext, Draggable, Droppable } from "@hello-pangea/dnd";
import { DragAndDropIcon } from "../../../../img";
import { StructureItem } from "./StructureItem";
import { getProfileVarsData, getStructureCompany, setStructureCompany } from "../../../../redux";
import styles from "./StructureItem.module.scss";
import { $updateStructure } from "../../../../api/requests/structure";

interface StructureContentGridProps {
    isAdmin: boolean;
    isVacationAdmin: boolean;
    departmentId: string;
    search: string;
    setDepartment: (value: boolean) => void;
    setSearchDepartment: (value: boolean) => void;
    setUser: (value: boolean) => void;
    setSearch: (value: boolean) => void;
    setLoadingMain: (value: boolean) => void;
    setDepartmentVacation: () => void;
    handleClosePopup: () => void;
}

const StructureContentGrid = (
    {
        isAdmin,
        isVacationAdmin,
        departmentId,
        search,
        setDepartment,
        setSearchDepartment,
        setUser,
        setSearch,
        setLoadingMain,
        setDepartmentVacation,
        handleClosePopup
    }: StructureContentGridProps
) => {
    const isTablet = isLg();

    const dispatch = useDispatch();
    const { structure } = useSelector(getStructureCompany);
    const vars = useSelector(getProfileVarsData);

    const [limit, setLimit] = useState(10);

    const handleDragEnd = ({ destination, source }: any, index: number) => {
        if (!destination) return;

        if (destination?.index === source?.index) return;

        const {
            department: { department_id, parent_id }
        } = structure[index]?.find((_: any, i: number) => i === source?.index) || {};

        const updateArray = [...structure[index]];
        const removed = updateArray?.splice(source?.index, 1);
        updateArray?.splice(destination?.index, 0, ...removed);

        const sortArray = updateArray?.map((item) => item?.inner_id || item?.department?.id || item?.id);

        const copyArray = [...structure];

        copyArray[index] = updateArray;

        dispatch(
            setStructureCompany({
                structure: copyArray
            })
        );

        $updateStructure({
            department_id: parent_id === "1000" ? department_id : parent_id || departmentId,
            sort: sortArray
        }).then(res => {});
    };

    const handleLimit = (number: number) => setLimit(number);

    useEffect(() => {
        setLimit(10);
    }, [search]);

    const style: any = {
        "--lightBlue": vars.colors.secondaryColor,
        "--length": structure?.length
    };

    return (
        <div id="grid" className={"structure__content__grid"} style={style}>
            {structure?.map((col: any, colIndex: number) => (
                <DragDropContext
                    key={colIndex}
                    onDragEnd={(result) => handleDragEnd(result, colIndex)}
                >
                    <Droppable droppableId="structure">
                        {(mainProvided) => (
                            <div
                                className={"structure__content__column"}
                                ref={mainProvided.innerRef} {...mainProvided.droppableProps}
                            >
                                <div
                                    className={"structure__content__column-block"}
                                    style={{
                                        top: col.find((_i: any) => _i.top)?.top
                                    }}
                                >
                                    {col?.slice(0, search ? limit : col?.length)?.map((person: any, personIndex: number) => {
                                        const {
                                            id,
                                            inner_id,
                                            department,
                                            isHidden,
                                            is_active,
                                            isActive,
                                            isSearch,
                                            isUser
                                        } =
                                        person || {};

                                        const isEnable = is_active || department?.is_active;

                                        if (isTablet && !isHidden) {
                                            return (
                                                <Draggable
                                                    key={inner_id || department?.id || id}
                                                    draggableId={`${inner_id || department?.id || id}-item-${personIndex}`}
                                                    index={personIndex}
                                                >
                                                    {(innerProvided) => (
                                                        <div
                                                            ref={innerProvided.innerRef} {...innerProvided.draggableProps}>
                                                            <div
                                                                className={`structure__content__drag ${(!isAdmin || isSearch || isUser) && "structure__content__drag__hidden"}`}
                                                                {...innerProvided.dragHandleProps}
                                                            >
                                                                <DragAndDropIcon />
                                                            </div>

                                                            <StructureItem
                                                                person={{
                                                                    ...person,
                                                                    count: !isEnable || isActive ? 1 : col?.length,
                                                                    isVacationAdmin,
                                                                    setDepartment,
                                                                    setSearchDepartment,
                                                                    setUser,
                                                                    setSearch,
                                                                    setLoadingMain,
                                                                    setDepartmentVacation,
                                                                    handleClosePopup
                                                                }}
                                                            />
                                                        </div>
                                                    )}
                                                </Draggable>
                                            );
                                        }

                                        if (!isTablet) {
                                            return (
                                                <Draggable
                                                    key={inner_id || department?.id || id}
                                                    draggableId={`${inner_id || department?.id || id}-item-${personIndex}`}
                                                    index={personIndex}
                                                >
                                                    {(innerProvided) => (
                                                        <div
                                                            ref={innerProvided.innerRef} {...innerProvided.draggableProps}>
                                                            <div
                                                                className={`structure__content__drag ${(!isAdmin || isSearch || isUser) && "structure__content__drag__hidden"}`}
                                                                {...innerProvided.dragHandleProps}
                                                            >
                                                                <DragAndDropIcon />
                                                            </div>

                                                            <StructureItem
                                                                person={{
                                                                    ...person,
                                                                    count: !isEnable || isActive ? 1 : col?.length,
                                                                    isVacationAdmin,
                                                                    setDepartment,
                                                                    setSearchDepartment,
                                                                    setUser,
                                                                    setSearch,
                                                                    setLoadingMain,
                                                                    setDepartmentVacation,
                                                                    handleClosePopup
                                                                }}
                                                            />
                                                        </div>
                                                    )}
                                                </Draggable>
                                            );
                                        }

                                        return null;
                                    })}

                                    {search && col?.length > 10 && (
                                        <div
                                            className={`structure__content__more ${limit > 10 ? "hide" : ""} ${styles.wrapper}`}
                                            onClick={() => handleLimit(col?.length)}
                                        >
                                            Показать ещё
                                        </div>
                                    )}
                                </div>

                                {mainProvided.placeholder}
                            </div>
                        )}
                    </Droppable>
                </DragDropContext>
            ))}

            {structure?.length === 0 &&
              <div className={"structure__content__empty"}>Сотрудник не найден</div>}
        </div>
    );
};

export default StructureContentGrid;
