import { IApiHandlerParams } from "../../interface"
import api from '../../../api'

export const $getPollsList = (
    handler?: IApiHandlerParams
): Promise<any> => api
    .get( `api/v1/vacations/news`, {
        mock: require('./mocks/favorites.polls.json'),
        handler,
    })

