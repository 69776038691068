export const ShopIcon = (
    {
        color = "black",
        size = 24,
        opacity = 0.5
    }
) => (
    <svg
        width={size}
        height={size}
        viewBox="0 0 25 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <g opacity={opacity}>
            <path
                d="M3.14844 9V19C3.14844 19.5304 3.35915 20.0391 3.73422 20.4142C4.1093 20.7893 4.618 21 5.14844 21H19.1484C19.6789 21 20.1876 20.7893 20.5627 20.4142C20.9377 20.0391 21.1484 19.5304 21.1484 19V9"
                stroke={color}
                strokeWidth="2"
            />
            <path
                d="M20.6326 3H16.6406L17.1406 8C17.1406 8 18.1406 9 19.6406 9C20.428 9.00104 21.1887 8.71441 21.7796 8.194C21.8421 8.13563 21.8886 8.0623 21.9149 7.98094C21.9411 7.89958 21.9462 7.81287 21.9296 7.729L21.2236 3.5C21.2 3.36029 21.1277 3.23345 21.0195 3.14194C20.9114 3.05042 20.7743 3.00014 20.6326 3Z"
                stroke={color}
                strokeWidth="2"
            />
            <path
                d="M16.6406 3L17.1406 8C17.1406 8 16.1406 9 14.6406 9C13.1406 9 12.1406 8 12.1406 8V3H16.6406Z"
                stroke={color}
                strokeWidth="2"
            />
            <path
                d="M12.1406 3V8C12.1406 8 11.1406 9 9.64062 9C8.14062 9 7.14062 8 7.14062 8L7.64062 3H12.1406Z"
                stroke={color}
                strokeWidth="2"
            />
            <path
                d="M7.63982 3H3.64882C3.50681 2.99995 3.36939 3.05026 3.26099 3.142C3.1526 3.23373 3.08025 3.36094 3.05682 3.501L2.35182 7.73C2.32282 7.902 2.37182 8.079 2.50182 8.195C2.82982 8.485 3.56282 9.001 4.63982 9.001C6.13982 9.001 7.13982 8.001 7.13982 8.001L7.63982 3Z"
                stroke={color}
                strokeWidth="2"
            />
        </g>
    </svg>
);
