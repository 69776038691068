import { FC, ReactNode, useEffect, useState, useRef } from "react";

import { CalendarContent } from "../components/layout-components";
import { useLocation } from "react-router-dom";

interface IMainLayout {
    children?: ReactNode;
    leftComponents?: ReactNode;
    contentGap?: number,
    isLeftComponentsAvailable?: boolean;
}

const MainLayoutContent: FC<IMainLayout> = ({
    contentGap = 60,
    leftComponents,
    isLeftComponentsAvailable = true,
    children }) => {
    const [scroll, setScroll] = useState<any>({});

    useEffect(() => {
        const handleScroll = () => {
            const slider = document.getElementById("sliderRouters");

            if (!slider) return;

            const sliderRect = slider.getBoundingClientRect();

            setScroll(sliderRect);
        };

        window.addEventListener("scroll", handleScroll);

        return () => {
            window.removeEventListener("scroll", handleScroll);
        };
    }, []);

    return (
        <div className={`layout ${scroll.top <= 80 ? "scrolled" : ""}`}>
            {isLeftComponentsAvailable ? (
                <div className="layout-left">

                    {leftComponents}
                </div>
            ): null}


            <div style={{gap: contentGap}} className="layout-right">
                {children}
            </div>
        </div>
    );
};

export default MainLayoutContent;
