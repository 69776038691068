import { useNavigate } from "react-router-dom";
import { ArrowExitIcon, SearchIcon } from "../../../img";
import Dropdown from "../../../components/ui/form/Dropdown/Dropdown";
import React, { useEffect, useState } from "react";
import TextField from "../../../components/ui/form/Fields/TextField";
import StructureContent from "./StructureContent";
import { $getStructureList } from "../../../api/requests/structure";
import { setStructureCompany } from "../../../redux";
import { useDispatch } from "react-redux";

interface DropdownItem {
    label: string;
    value: number | string;
}

const StructurePage = () => {
    const navigate = useNavigate();
    const isMobile = window.screen.width < 1020;

    const dispatch = useDispatch();

    const [structureList, setStructureList] = useState<any[]>([]);
    const [filterList, setFilterList] = useState<any[]>([]);

    const [categoryList, setCategoryList] = useState<DropdownItem[]>([
        {
            label: "Спорт",
            value: 0
        },
        {
            label: "Инновации",
            value: 1
        },
        {
            label: "Творчество",
            value: 2
        }
    ]);
    const [categorySelect, setCategorySelect] = useState<DropdownItem | null>(null);

    const [departmentId, setDepartmentId] = useState(null);

    const [searchValue, setSearchValue] = useState<string>("");

    const handleNavigation = () => {
        const pathArray = location.pathname.split("/");
        if (pathArray.length > 1) {
            pathArray.pop();
            const newPath = pathArray.join("/") || "/";
            navigate(newPath);
        } else {
            navigate("/");
        }
    };

    useEffect(() => {
        init();
    }, []);

    function init() {
        $getStructureList().then(res => {
            const getMain = res?.data?.[0];

            setDepartmentId(getMain?.department?.department_id || getMain?.department_id);
            //
            // setSearchDepartment(getMain?.department?.department_id || getMain?.department_id);

            dispatch(
                setStructureCompany({
                    structureMain: getMain
                })
            );
        });
    }

    function handleChangeCategoryDropdown(select: DropdownItem) {
        setCategorySelect(select);

        const filterList = [...structureList].filter(item => {
            const isStatus = categorySelect ? (item.status === categorySelect?.value) : true;

            if (select.value === "all") return isStatus;

            return (item.category_id === select.value); //&& isStatus;
        });

        setFilterList(filterList);
    }

    function onChangeSearch(value: string) {
        setSearchValue(value);

        const filterList = [...structureList].filter(item => {
            const isCategory = categorySelect ? (item.status === categorySelect?.value) : true;

            return (item.name.toLowerCase().includes(value.toLowerCase())) && isCategory;
        });

        setFilterList(filterList);
    }

    return (
        <div className="favorites-news structure">
            <div className="layout-inner__header profile-header structure__header">
                {!isMobile && (
                    <div className="layout-inner__header__title">
                        <div className="header__exitIcon" onClick={handleNavigation}>
                            <ArrowExitIcon />
                        </div>
                        <h1>Организационно-штатная структура</h1>
                    </div>
                )}

                <div className="layout-inner__header__selects structure__header">
                    <Dropdown
                        className="structure__header__dropdown"
                        placeholder={"Головная компания"}
                        options={[
                            {
                                label: "Все",
                                value: "all"
                            },
                            ...categoryList
                        ]}
                        value={categorySelect}
                        onChange={handleChangeCategoryDropdown}
                    />

                    <div className={"form-group contests__application__search"}>
                        <TextField
                            value={searchValue}
                            onChange={onChangeSearch}
                            placeholder={"Поиск"}
                            prepend={<SearchIcon color={"#00000080"} />}
                        />
                    </div>
                </div>
            </div>

            <div className="container">
                <div className="row">
                    <div className="col-12">
                        <StructureContent
                            departmentId={departmentId}
                            isVacationAdmin={false}
                            setDepartmentVacation={() => {
                            }}
                            handleClosePopup={() => {
                            }}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default StructurePage;