import { IApiHandlerParams } from "../../interface"
import api from '../../../api'
import { ContestApplicationUsersData, ContestData } from "./interface";

export const $getContestList = (
    handler?: IApiHandlerParams
): Promise<ContestData> => api
.get( `api/v1/contest`, {
    mock: require('./mocks/contest.list.json'),
    handler,
})

export const $getContestUserApplicationList = (
    handler?: IApiHandlerParams
): Promise<ContestApplicationUsersData> => api
.get( `api/v1/contest`, {
    mock: require('./mocks/contest.user.list.json'),
    handler,
})