import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../../store';

export interface structureInitialStateType {
    structureCompany: any;
    topHeight: number;
}

const initialState: structureInitialStateType = {
    structureCompany: {
        structure: [],
    },
    topHeight: 0
};

const structureSlice = createSlice({
    name: 'structure',
    initialState,
    reducers: {
        setStructureCompany: (state, action: PayloadAction<any>) => ({
            ...state,
            structureCompany: { ...state.structureCompany, ...action.payload },
        }),
        setStructureCurrentTopHeight: (state, action: PayloadAction<any>) => {
            state.topHeight = action.payload
        },
    },
});

export const {
    setStructureCompany,
    setStructureCurrentTopHeight
} = structureSlice.actions;

export const getStructureCompany = (state: RootState) => state.structure.structureCompany;
export const getStructureTopHeight = (state: RootState) => state.structure.topHeight;

export default structureSlice.reducer
