import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { routeLinks } from "../../../routes/routeLinks";

const routeTitleReducer = createSlice({
    name: 'route_title',
    initialState: routeLinks,
    reducers: {
        updateRouteTitle: (state, action) => {
            const { key, title } = action.payload;
            if (state[key]) {
                state[key].title = title;
            }
        },
    },
})

export const { updateRouteTitle } = routeTitleReducer.actions

export default routeTitleReducer.reducer