import { FC, useEffect, useState } from "react";
import { CloseIcon, PaperClipIcon, PictureIcon } from "../../../../img";
import Button from "../Buttons/Button";
import { DownloadIcon } from "../../../../img/icons/DownloadIcon";

interface FileFieldProps {
    upload: File | string | null;
    uploadName?: string;
    setUpload?: (prev: any) => void;
    label?: string;
    className?: string;
    accept?: string;
    isLockFile?: boolean;
}

const FileClipField: FC<FileFieldProps> = (
    {
        upload,
        uploadName,
        setUpload,
        label = "Фото",
        className = "form-file",
        accept = ".png, .jpg",
        isLockFile = false
    }
) => {
    const [nameList, setNameList] = useState<string[]>([]);
    const [fileList, setFileList] = useState<any>(null);

    const handleFile = ({ target }: any) => {
        const files = target?.files;

        for (let i = 0; files.length > i; i++) {
            const file = files[i];

            if (file) {
                const imageURL = URL.createObjectURL(file);

                const saveItem = {
                    file: file,
                    name: file?.name
                }

                console.log(saveItem, 'saveItem');

                setFileList((prev: any[]) => {
                    if (prev) return [...prev, saveItem]

                    return [saveItem]
                });

                setUpload && setUpload((prev: any[]) => {
                    if (prev) return [...prev, saveItem]

                    return [saveItem]
                });

                setNameList((prev: any[]) => {
                    if (prev) return [...prev, file?.name]

                    return [file?.name]
                });
            }
        }
    };

    useEffect(() => {
        if (upload && Array.isArray(upload)) setFileList(upload);
    }, [upload]);

    useEffect(() => {
        if (uploadName) setNameList([uploadName]);
    }, [uploadName]);

    function handleRemoveFile(name: string) {
        const updateList = [...fileList].filter(item => item.name !== name)
        const updateNameList = [...nameList].filter(item => item !== name)

        setFileList(updateList);
        setUpload && setUpload(updateList);

        setNameList(updateNameList);
    }

    return (
        <div className={className}>
            <p>{label}</p>

            <div className={`${className}-block ${fileList ? "image" : ""}`}>
                <label htmlFor="file">
                    <input
                        type="file"
                        multiple
                        accept={accept}
                        onChange={handleFile}
                        id={"file"}
                        hidden
                        disabled={isLockFile}
                    />

                    <span className={`${className}-icon`}>
                        {className === "form-image" && (
                            <PictureIcon color={fileList ? "white" : "#00A7B5"}/>
                        )}

                        {className === "form-file" && (
                            <>
                                <DownloadIcon />

                                {nameList.map((i, idx) => (
                                    <p key={idx}>{i}</p>
                                ))}
                            </>
                        )}

                        {!className && <PaperClipIcon color={"#00A7B5"}/>}
                    </span>
                </label>

                {!isLockFile && nameList.map((i, idx) => (
                    <div key={`text-file-${idx}`} className={`${className}-name`}>
                        <p>{i}</p>

                        <Button
                            text={(
                                <CloseIcon
                                    color={"#FF0000"}
                                    width={10}
                                    height={10}
                                />
                            )}
                            className={"btn-icon"}
                            onClick={() => handleRemoveFile(i)}
                        />
                    </div>
                ))}
            </div>
        </div>
    );
};

export default FileClipField;
