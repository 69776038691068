import { useNavigate } from "react-router-dom";
import { SportViewItemData } from "../../api/requests/sport/interface";

interface SportContentItemProps extends SportViewItemData {}

const TrainingContentItem = (
    {
        id,
        user,
        image,
        title,
        places,
        text,
        date,
    }: SportContentItemProps
) => {
    const navigate = useNavigate();

    return (
        <div className="sport__content-card-block"
             onClick={() => navigate(`/sport/view/${id}`)}
        >
            <img src={image} alt=""
                 className="sport__content-card-block__image" />

            <div className="sport__content-card-block-content">
                <div className="sport__content-card-block-content__top">
                    <h2 className="sport__content-card-block-content__title">
                        {title}
                    </h2>

                    <div
                        className={`sport__content-card-block-content__subtitle`}>
                        Осталось мест: {places}
                    </div>
                </div>

                <div className="sport__content-card-block-content__text">
                    {text}
                </div>

                <div className="sport__content-card-block-content__bottom">
                    {date && date.map((item, idx) => (
                        <div
                            key={`date-item-${idx}`}
                            className="sport__content-card-block-content__bottom__block"
                        >
                            <p>{item.week}</p>
                            {item.date && <br/>}
                            {item.date && <p>{item.date}</p>}
                            {item.date && <br/>}
                            <p>{item.time}</p>
                        </div>
                    ))}
                </div>

                <div className="sport__content-card-user">
                    <img src={user.image} alt=""
                         className="sport__content-card-user__image" />

                    <div className="sport__content-card-user__content">
                        <h1 className="sport__content-card-user__content__name">{user.name}</h1>
                        <p className="sport__content-card-user__content__position">{user.position}</p>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default TrainingContentItem;