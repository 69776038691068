export const SliderContactsIcon = () => {
    return (
        <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M2.57129 9H16.5713M2.57129 9V5.6C2.57129 5.44087 2.6345 5.28826 2.74703 5.17574C2.85955 5.06321 3.01216 5 3.17129 5H21.9713C22.1304 5 22.283 5.06321 22.3956 5.17574C22.5081 5.28826 22.5713 5.44087 22.5713 5.6V18.4C22.5713 18.5591 22.5081 18.7117 22.3956 18.8243C22.283 18.9368 22.1304 19 21.9713 19H3.17129C3.01216 19 2.85955 18.9368 2.74703 18.8243C2.6345 18.7117 2.57129 18.5591 2.57129 18.4V9Z"
                stroke="currentColor" strokeWidth="2" stroke-linecap="round" strokeLinejoin="round" />
            <path
                d="M18.9713 12H16.1713C15.8399 12 15.5713 12.2686 15.5713 12.6V15.4C15.5713 15.7314 15.8399 16 16.1713 16H18.9713C19.3027 16 19.5713 15.7314 19.5713 15.4V12.6C19.5713 12.2686 19.3027 12 18.9713 12Z"
                fill="currentColor" />
        </svg>

    )
}
