import React, { useState } from "react";
import { ArrowExitIcon, CoinCompetitionIcon } from "../../../img";
import TextField from "../../../components/ui/form/Fields/TextField";
import Button from "../../../components/ui/form/Buttons/Button";

interface ContestsViewWinnerExcludeProps {
    data: any;
    onBack: () => void;
    onExclude: () => void;
    onClose: () => void;
}

const ContestsViewWinnerExclude = (
    {
        data,
        onBack,
        onExclude,
        onClose,
    }: ContestsViewWinnerExcludeProps
) => {
    const [commentValue, setCommentValue] = useState<string>("");

    return (
        <div className={"contests__winner contests__win"}>
            <div className={"contests__winner__header contests__win__header"}>
                <div className={"back"} onClick={() => onBack()}>
                    <ArrowExitIcon color={"#00A7B5"} size={14} strokeWidth={4} />
                </div>

                <h3 className={"m-auto"}>Исключить?</h3>
            </div>

            <div className={"contests__exclude__message"}>
                <p>Вы действительно хотите исключить пользователей из конкурса?</p>
            </div>

            <div className={"contests__win__list"}>
                {data?.selectUsersList?.map((item: any, idx: number) => (
                    <div key={idx} className="layout-vacation-modal__block">
                        <div className="layout-vacation-modal__block__right">
                            <img src={item.image} alt="" />

                            <div className="layout-vacation-modal__block__desc">
                                <h1>{item.name}</h1>

                                <p className="layout-vacation-modal__block__desc__position">{item.position}</p>

                                <p className="layout-vacation-modal__block__desc__department">{item.department}</p>
                            </div>
                        </div>
                    </div>
                ))}
            </div>

            {/*<div className={"contests__exclude__comment"}>*/}
            {/*    <TextField*/}
            {/*        value={commentValue}*/}
            {/*        onChange={setCommentValue}*/}
            {/*        title={"Причина исключения"}*/}
            {/*        placeholder={"Причина"}*/}
            {/*        className={``}*/}
            {/*    />*/}
            {/*</div>*/}

            <div className={"contests__win__btns contests__winner__btns"}>
                <Button
                    text={"Исключить"}
                    className={"btn btn-red w-100"}
                    onClick={() => onExclude()}
                />

                <Button
                    text={"Отмена"}
                    className={"btn btn-light w-100"}
                    onClick={() => onClose()}
                />
            </div>
        </div>
    );
};

export default ContestsViewWinnerExclude;