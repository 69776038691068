import { FC } from "react";
import NewsDateIcon from "../../img/icons/news/NewsDateIcon";
import { CommentIcon, EyeIcon, HeartIcon, LikeIcon, ThreePeopleIcon } from "../../img";
import TextOverflow from "../../utils/text-overflow/TextOverflow";

interface PollsCard {
    title: string;
    image: string;
    category_title: string;
    date: string;
    views: number;
    likes_count: number;
    comments_count: number;
    isLiked: boolean,
}

interface IPollsCard {
    isLikeIcon?: boolean;
    data: PollsCard
}

export const PollsCard: FC<IPollsCard> = ({
    isLikeIcon = false,
    data
}) => {
    const isMobile = window.screen.width <= 600
    const {
        title,
        image,
        category_title,
        date,
        views,
        likes_count,
        comments_count,
        isLiked
    } = data
    return (
        <div className='feed-news-card'>
            <img src={ image } />

            <div className='feed-news-card__infoBox'>

                { isLikeIcon ? (
                        <div className='feed-news-card__infoBox__top'>
                            <h5>{ TextOverflow({title, lines: 3}) }</h5>

                            <div
                                // onClick={ () => {
                                //     handleLike(item.id)
                                // } }
                                className={ `employees-block__likeIcon ${ isLiked ? 'active' : '' }` }
                            >
                                <HeartIcon />
                            </div>
                        </div>
                    ) :
                    <h5>{ TextOverflow({title, lines: 3}) }</h5>
                }

                <div className='feed-news-card__content'>
                    <div className='feed-news-card__dateBox'>
                        <NewsDateIcon size={ '1.25rem' }
                                      style={ {marginRight: isMobile ? '0.375rem' : '0.5rem', minWidth: '1.25rem'} } />
                        <div>До { date }</div>
                    </div>

                    <div className='feed-news-card__stats'>
                        <div className='feed-news-card__stats__block'>
                            <EyeIcon />

                            <h1>{ views }</h1>
                        </div>

                        <div className='feed-news-card__stats__block'>
                            <ThreePeopleIcon />

                            <h1>{ likes_count }</h1>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}