import { IApiHandlerParams } from "../../interface";
import api from "../../index";

export const $getServices = (
  handler?: IApiHandlerParams
): Promise<any> => api
  .get( `api/v1/services`, {
    mock: require('./mocks/services.json'),
    handler,
  })

export const $getServiceApplications = (
  handler?: IApiHandlerParams
): Promise<any> => api
  .get( `api/v1/services-applications`, {
    mock: require('./mocks/services-applications.json'),
    handler,
  })