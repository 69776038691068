export const DeleteIcon = ({
    fillColor,
    color,
    id = '',
    onClick = () => {},
    style = {},
}: any) => (
    <svg
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        id={id}
        onClick={onClick}
        style={{ ...style, cursor: 'pointer' }}
    >
        <circle
            cx="9.75311"
            cy="9.75311"
            r="9.75311"
            fill={fillColor}
            id={id}
        />
        <mask
            id="mask0"
            // maskType="alpha"
            maskUnits="userSpaceOnUse"
            x="6"
            y="6"
            width="8"
            height="8"
        >
            <path
                d="M6.16654 13.3392C6.36776 13.5404 6.69487 13.5404 6.89609 13.3392L9.75237 10.4829L12.6087 13.3392C12.8099 13.5404 13.137 13.5404 13.3382 13.3392C13.5394 13.138 13.5394 12.8109 13.3382 12.6096L10.4819 9.75335L13.3382 6.89707C13.5394 6.69585 13.5394 6.36874 13.3382 6.16752C13.137 5.9663 12.8099 5.9663 12.6087 6.16752L9.75237 9.0238L6.89609 6.16752C6.69487 5.9663 6.36776 5.9663 6.16654 6.16752C5.96532 6.36874 5.96532 6.69585 6.16654 6.89707L9.02282 9.75335L6.16654 12.6096C5.96532 12.8109 5.96532 13.138 6.16654 13.3392Z"
                fill={color}
                id={id}
            />
        </mask>
        <g mask="url(#mask0)" id={id}>
            <path
                d="M6.16654 13.3392C6.36776 13.5404 6.69487 13.5404 6.89609 13.3392L9.75237 10.4829L12.6087 13.3392C12.8099 13.5404 13.137 13.5404 13.3382 13.3392C13.5394 13.138 13.5394 12.8109 13.3382 12.6096L10.4819 9.75335L13.3382 6.89707C13.5394 6.69585 13.5394 6.36874 13.3382 6.16752C13.137 5.9663 12.8099 5.9663 12.6087 6.16752L9.75237 9.0238L6.89609 6.16752C6.69487 5.9663 6.36776 5.9663 6.16654 6.16752C5.96532 6.36874 5.96532 6.69585 6.16654 6.89707L9.02282 9.75335L6.16654 12.6096C5.96532 12.8109 5.96532 13.138 6.16654 13.3392Z"
                fill={color}
                id={id}
            />
        </g>
    </svg>
)
