import { FC, useState } from "react";
import { LikeIcon } from "../../img";
import { ICommentsMessenger } from "../../api/requests/blogs/interface";

interface CommentMessengerChatBoxProps {
    comments_data: ICommentsMessenger[]
}
export const CommentMessengerChatBox: FC<CommentMessengerChatBoxProps> = ({
    comments_data
}) => {
    const [data, setData] = useState([
        {
            id: 12684,
            text: "Спасибо за статью, отличная. Возможно, разработчикам, читать сложно, но все понятно написано. А как релизить приложение?",
            date: 1723464513,
            parent_id: "0",
            hashtags: null,
            name: "Георгий Левин ",
            photo: '/testImages/people/Image-2.png',
            user_id: 232236,
            attachment: null,
            filename: null,
            file_size: null,
            likes: 346,
            user_liked: 0,
            attachments: [
                {
                    attachment: "https://api.svoi.club/images/news_comments/PoyTIIgtKp.png",
                    file_name: "testImage.png",
                    file_size: "2054"
                }
            ],
            can_delete: 1,
            children: []
        },
        {
            id: 12684,
            text: "Спасибо за статью, отличная. Возможно, разработчикам, читать сложно, но все понятно написано. А как релизить приложение?",
            date: 1723464513,
            parent_id: "0",
            hashtags: null,
            name: "Вячеслав Зуев",
            photo: '/testImages/people/Image-3.png',
            user_id: 232236,
            attachment: null,
            filename: null,
            file_size: null,
            likes: 346,
            user_liked: 0,
            attachments: [],
            can_delete: 1,
            children: [
                {
                    id: 15032,
                    text: "Спасибо!!!",
                    date: 1723469367,
                    parent_id: "13773",
                    hashtags: null,
                    name: "Георгий Левин",
                    photo: '/testImages/people/Image-4.png',
                    user_id: 232236,
                    attachment: null,
                    filename: null,
                    file_size: null,
                    likes: 0,
                    user_liked: 0,
                    attachments: [],
                    can_delete: 1,
                    children: []
                },
            ]
        }
    ])

    return (
        <div className='news-chat'>
            {comments_data.map((item: ICommentsMessenger) => (
                <div className='news-chat__block'>
                    <div className='news-chat__info'>
                        <img src={item.photo} alt="" className='news-chat__info__avatar'/>

                        <div className='news-chat__info__desc'>
                            <h1 className='news-chat__info__name'>{item.name}</h1>

                            <p className='news-chat__info__date'>21.08.2020</p>
                        </div>
                    </div>

                    <div className='news-chat__content'>
                        {item.attachments ? (
                            <div className='news-chat-child__attachment'>
                                {item.attachments.map((attachment: any) => (
                                    <img src={attachment?.attachment} alt="" />
                                ))}
                            </div>
                        ) : null}

                        {item.text}
                    </div>

                    <div className='news-chat__buttons'>
                        <div className='news-chat__buttons__answerButton'>Ответить</div>

                        <div className='news-chat__buttons__likeButton'>
                            <LikeIcon size={20} color='#000'/>

                            <p>{item.likes}</p>
                        </div>
                    </div>

                    {item.children ? (
                        <>
                            {item.children.map((child) => (
                                <div className='news-chat-child__block'>
                                    <div className='news-chat-child__leftRow'></div>

                                    <div className='news-chat-child__right'>
                                        <div className='news-chat__info'>
                                            <img src={child.photo} alt="" className='news-chat__info__avatar'/>

                                            <div className='news-chat__info__desc'>
                                                <h1 className='news-chat__info__name'>{child.name}</h1>

                                                <p className='news-chat__info__date'>21.08.2020</p>
                                            </div>
                                        </div>

                                        <div>
                                            {child.attachments ? (
                                                <div className='news-chat-child__attachment'>
                                                    {child.attachments.map((attachment: any) => (
                                                        <img src={attachment?.attachment} alt="" />
                                                    ))}
                                                </div>
                                            ) : null}

                                            {child.text}
                                        </div>

                                        <div className='news-chat-child__likeButton'>
                                            <LikeIcon/>

                                            <p>{child.likes}</p>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </>
                    ): null}
                </div>
            ))}
        </div>
    )
}