import { FC, ReactNode, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
    changeMainPage,
    getMainPageData,
    changeProfile,
    changeProfileVarsData,
    getProfileData
} from "../redux";
import { $profileData } from "../api/requests/auth";
import Cookies from "js-cookie";
import { $mainPageNewData } from "../api/requests/main";
import Preloader from "../components/ui/preloader/Preloader";
import HeaderHome from "../components/header/HeaderHome";
import FooterHome from "../components/header/FooterHome";
import { Link, Navigate, Outlet, useLocation, useNavigate } from "react-router-dom";
import ScrollToTop from "../utils/scroll/ScrollToTop";
import AsideHome from "../components/header/AsideHome";

interface IListPages {
    id: number;
    key: string;
    route: string;
    title: string;
    isFooterAvailable: boolean;
    is_drafted?: boolean;
}

interface IInnerLayout {
    title?: string;
    isFooterAvailable?: boolean;
    isShowBreadcrumb?: boolean;
}

const InnerLayout: FC<IInnerLayout> = (
    {
        title,
        isFooterAvailable = true,
        isShowBreadcrumb = true
    }
) => {
    const isMobile = window.screen.width < 1020
    const navigate = useNavigate();
    const location = useLocation()
    const mainData = useSelector(getMainPageData);
    const profileData = useSelector(getProfileData);
    const dispatch = useDispatch();
    const isBackgroundWhite =
      !location.pathname.includes('services')
      && location.pathname.includes('create')
      || location.pathname.includes('/services/education')
      || location.pathname.includes('edit')

    const routeLinks = useSelector((state: any) => state.routeTitle);

    const listMenu: any[] = [
        {
            img: "/images/icons/home.svg",
            text: "Главная страница",
            key: "home",
            count: 0,
            active: true,
            link: "/"
        },
        {
            img: "/images/icons/percentage2.svg",
            text: "Витрина скидок",
            key: "percent",
            count: 156,
            active: false,
            backColorCount: "#FA1919",
            colorCount: "#FFFFFF",
            link: "/"
        },
        {
            img: "/images/icons/chat_bubble.svg",
            text: "Мессенджер",
            key: "message",
            count: 10,
            active: false,
            backColorCount: "#1958FA",
            colorCount: "#FFFFFF",
            link: "/"
        },
        {
            img: "/images/icons/facebook_like.svg",
            text: "Благодарности",
            key: "urls",
            count: 2,
            active: false,
            backColorCount: "#FFD600",
            colorCount: "#000000",
            link: "/"
        },
        {
            img: "/images/icons/news.svg",
            text: "Новости",
            key: "news",
            count: 5,
            active: false,
            backColorCount: "#C7EF71",
            colorCount: "#000000",
            link: "/"
        },
        {
            img: "/images/icons/megaphone.svg",
            text: "Конкурсы",
            key: "news",
            active: false,
            count: 0,
            link: "/"
        },
        {
            img: "/images/icons/check_all.svg",
            text: "Опросы",
            key: "news",
            active: false,
            count: 0,
            link: "/"
        },
        {
            img: "/images/icons/chart.svg",
            text: "Мои траты",
            key: "news",
            active: false,
            count: 0,
            link: "/"
        },
        {
            img: "/images/icons/user.svg",
            text: "Профиль",
            key: "news",
            active: false,
            count: 0,
            link: "/"
        }
    ];

    const listPages: IListPages[] = [
        {
            id: 1,
            key: 'birthdays',
            route: '/birthdays',
            title: 'Дни рождения',
            isFooterAvailable: !isMobile
        },
        {
            id: 2,
            key: 'employees',
            route: '/employees',
            title: 'Новые сотрудники',
            isFooterAvailable: !isMobile
        },
        {
            id: 3,
            key: 'profile',
            route: '/profile',
            title: 'Моя страница',
            isFooterAvailable: true
        },
        {
            id: 20,
            key: 'news-blogs-show',
            route: '/news/blogs-show',
            title: 'Моя страница',
            isFooterAvailable: true
        },
        {
            id: 8,
            key: 'favorites',
            route: '/favorites',
            title: 'Моя страница',
            isFooterAvailable: true
        },
        {
            id: 9,
            key: 'favorites_employees',
            route: '/favorites/employees',
            title: 'Моя страница',
            isFooterAvailable: true
        },
        {
            id: 10,
            key: 'favorites_polls',
            route: '/favorites/polls',
            title: 'Моя страница',
            isFooterAvailable: true
        },
        {
            id: 15,
            key: 'news',
            route: '/news',
            title: 'Новости',
            isFooterAvailable: true
        },
        {
            id:12,
            key: 'favorites_news',
            route: '/favorites/news',
            title: 'Моя страница',
            isFooterAvailable: true
        },
        {
            id:13,
            key: 'favorites_competitions',
            route: '/favorites/competitions',
            title: 'Моя страница',
            isFooterAvailable: true
        },
        {
            id: 6,
            key: 'profile/colleagues',
            route: '/profile/colleagues',
            title: 'Мои сотрудники',
            isFooterAvailable: !isMobile
        },
        {
            id: 3,
            key: 'vacation',
            route: '/calendar/vacation',
            title: 'Отпуска',
            isFooterAvailable: true
        },
        {
            id:21,
            key: 'polls',
            route: '/polls',
            title: 'Опросы',
            isFooterAvailable: true
        },
        {
            id:22,
            key: 'blogs',
            route: '/blogs',
            title: 'Опросы',
            isFooterAvailable: true,
            is_drafted: true,
        },
    ]

    const [ isOpenMenu, setIsOpenMenu ] = useState(false);

    const getContentData = listPages.find((item) => item.route === location.pathname)

    const pathnames = location.pathname.split('/').filter(x => x);

    const groupAdminModule =
        profileData?.group_admin_module?.filter(Boolean).length > 0;
    const isOwner =
        (+profileData?.workspace_role === 1 ||
            +profileData?.group_role === 1 ||
            +profileData?.group_role === 2) &&
        !groupAdminModule;

    const defaultColorScheme = {
        primaryColor: "#003166", //blueColor
        secondaryColor: "#007AFF", //lightBlue
        thirdColor: "#E9F0F9", //grayColor
        fourthColor: "#FFF" //whiteColor
    };

    if (!Cookies.get("token")) {
        return <Navigate to={ "/auth" } replace />;
    }

    const getLinkTitle = () => {
        let title: any;
        for (const key in routeLinks) {
            if (routeLinks[key]?.route === location?.pathname) {
                title = routeLinks[key]?.title;
            }
        }

        return title
    }

    const getBreadcrumbs = (
        pathname: any,
        routes: any
    ) => {
        const pathnames: any = pathname.split('/').filter((x: any) => x);
        const breadcrumbs = [];

        if (pathnames.length === 0) {
            breadcrumbs.push({title: 'Главная', route: '/'});
        } else {
            breadcrumbs.push({title: 'Главная', route: '/'});

            let currentPath = '';
            pathnames.forEach((segment: any) => {
                currentPath += `/${ segment }`;
                const routeKey = Object.keys(routes).find(key => routes[key].route === currentPath);
                if (routeKey) {
                    breadcrumbs.push(routes[routeKey]);
                }
            });
        }

        return breadcrumbs;
    };

    useEffect(() => {
        if (profileData) return;

        $profileData().then((res) => {
            dispatch(
                changeProfileVarsData({
                    newMain: !!+res?.main_page_new,
                    newProfile: !!+res?.profile_new,
                    colors: res.workspace_design
                        ? JSON.parse(res.workspace_design)
                        : defaultColorScheme,
                    isOwner
                })
            );
            dispatch(changeProfile(res));
        });
    }, []);

    useEffect(() => {
        if (mainData) return;

        $mainPageNewData().then((res) => {
            console.log(res);
            dispatch(changeMainPage(res));
        });
    }, []);

    function openMenu(): void {
        setIsOpenMenu(!isOpenMenu);
    }

    function onClickMenuFooter(i: any): void {
        if (i.key === "navigation") openMenu();
    }

    if (!profileData || !mainData)
        return (
            <div
                className={ "w-100" }
                style={ {
                    height: "100vh",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center"
                } }
            >
                <Preloader size={ "large" } />
            </div>
        );

    return (
        <>
            <HeaderHome openMenu={ openMenu } title={ getContentData?.title } is_drafted={getContentData?.is_drafted}/>

            <AsideHome
                isActive={ isOpenMenu }
                openMenu={ openMenu }
                menu={ listMenu }
            />

            <div className={ "inner__layout" } style={{background: isBackgroundWhite ? '#fff' : ''}}>
                { !isMobile && isShowBreadcrumb && (
                    <div className='breadcrumbs'>
                        { getBreadcrumbs(location.pathname, routeLinks).map((
                            value: any,
                            index: any
                        ) => (
                            <Link
                                key={`breadcrumb-item-${index}`}
                                to={ value.route }
                                className={ `breadcrumbs__link ${ index === pathnames.length  ? 'active' : '' }` }
                            >
                                {index !== 0 ? (
                                    <span>{ '>' }</span>
                                ) : ''}
                                <p>{ value.title }</p>
                            </Link>
                        )) }
                    </div>
                ) }

                <Outlet />
            </div>

            {getContentData ? (
                <>
                    {getContentData.isFooterAvailable && (
                        <FooterHome
                            isOpenMenu={ isOpenMenu }
                            openMenu={ openMenu }
                            listMenu={ listMenu }
                            onClickMenuFooter={ onClickMenuFooter }
                        />
                    )}
                </>
            ) : (
                <FooterHome
                    isOpenMenu={ isOpenMenu }
                    openMenu={ openMenu }
                    listMenu={ listMenu }
                    onClickMenuFooter={ onClickMenuFooter }
                />
            )}

            <ScrollToTop />
        </>
    );
};

export default InnerLayout;
