import { FC } from "react";
import { IBaseIconProps } from "../types";

export const ServicesFileIcon: FC<IBaseIconProps> = ({
  color = '#000',
  size = 25,
}) => {
  return (
    <svg width={size} height={size} viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g opacity="0.3">
        <path
          d="M9.00078 6.49992H15.0008M4.80078 5.2999V19.6999C4.80078 21.0254 5.8753 22.0999 7.20078 22.0999H16.8008C18.1263 22.0999 19.2008 21.0254 19.2008 19.6999V5.29992C19.2008 3.97443 18.1263 2.89992 16.8008 2.89992L7.20079 2.8999C5.8753 2.8999 4.80078 3.97442 4.80078 5.2999ZM12.0008 17.2999H12.0858V17.3768H12.0008V17.2999Z"
          stroke={color} strokeWidth="2" stroke-linecap="round" strokeLinejoin="round" />
      </g>
    </svg>
  )
}