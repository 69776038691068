import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { CompetitionsCard } from "../../components/competitions";
import { ArrowExitIcon } from "../../img";
import { $getContestList } from "../../api/requests/contest";
import Dropdown from "../../components/ui/form/Dropdown/Dropdown";
import { ContestItem } from "../../api/requests/contest/interface";
import ContestsPageView from "./ContestsView/index";
import ContestsPageSuccess from "./ContestsView/ContestsPageSuccess";
import ContestsViewCompleted from "./ContestsView/ContestsViewCompleted";

interface DropdownItem {
    label: string;
    value: number | string;
}

const ContestsPage = () => {
    const navigate = useNavigate();
    const isMobile = window.screen.width < 1020;

    const [contestList, setContestList] = useState<ContestItem[]>([]);
    const [filterList, setFilterList] = useState<ContestItem[]>([]);

    const [categoryList, setCategoryList] = useState<DropdownItem[]>([
        {
            label: "Спорт",
            value: 0
        },
        {
            label: "Инновации",
            value: 1
        },
        {
            label: "Творчество",
            value: 2
        }
    ]);
    const [categorySelect, setCategorySelect] = useState<DropdownItem | null>(null);

    const [statusList, setStatusList] = useState<DropdownItem[]>([
        {
            label: "Активный",
            value: "active"
        },
        {
            label: "Завершенный",
            value: "finish"
        },
        {
            label: "Скрытый",
            value: "hide"
        }
    ]);
    const [statusSelect, setStatusSelect] = useState<DropdownItem | null>(null);

    const [isOpenModalSelect, setIsOpenModalSelect] = useState(false);
    const [isOpenModalCompletedSelect, setIsOpenModalCompletedSelect] = useState(false);
    const [selectData, setSelectData] = useState<ContestItem | null>(null);

    const [isOpenSuccessModal, setIsOpenSuccessModal] = useState(false);

    const handleNavigation = () => {
        const pathArray = location.pathname.split("/");
        if (pathArray.length > 1) {
            pathArray.pop();
            const newPath = pathArray.join("/") || "/";
            navigate(newPath);
        } else {
            navigate("/");
        }
    };

    useEffect(() => {
        $getContestList().then(res => {
            if (!res.data) return;

            setContestList(res.data);
            setFilterList(res.data);
        });
    }, []);

    function handleChangeCategoryDropdown(select: DropdownItem) {
        setCategorySelect(select);

        const filterList = [...contestList].filter(item => {
            const isStatus = statusSelect ? (item.status === statusSelect?.value) : true;

            if (select.value === "all") return isStatus;

            return (item.category_id === select.value) && isStatus;
        });

        setFilterList(filterList);
    }

    function handleChangeStatusDropdown(select: DropdownItem) {
        setStatusSelect(select);

        const filterList = select.value === "all" ? [...contestList] : [...contestList].filter(item => {
            const isCategory = categorySelect ? (item.category_id === categorySelect?.value) : true;
            if (select.value === "all") return isCategory;

            return (item.status === select.value) && isCategory;
        });

        setFilterList(filterList);
    }

    function handleSuccessCloseModal() {
        setIsOpenSuccessModal(false);
    }

    function handleSuccessModal() {
        setIsOpenModalSelect(false);
        setIsOpenSuccessModal(true);
    }

    return (
        <div className="favorites-news">
            <div className="layout-inner__header profile-header">
                {!isMobile && (
                    <div className="layout-inner__header__title">
                        <div className="header__exitIcon" onClick={handleNavigation}>
                            <ArrowExitIcon />
                        </div>
                        <h1>Конкурсы</h1>
                    </div>
                )}

                <div className="layout-inner__header__selects">
                    <Dropdown
                        className="workspace-select"
                        placeholder={"Категория"}
                        options={[
                            {
                                label: "Все",
                                value: "all"
                            },
                            ...categoryList
                        ]}
                        value={categorySelect}
                        onChange={handleChangeCategoryDropdown}
                    />

                    <Dropdown
                        className="workspace-select"
                        placeholder={"Статус"}
                        options={[
                            {
                                label: "Все",
                                value: "all"
                            },
                            ...statusList
                        ]}
                        value={statusSelect}
                        onChange={handleChangeStatusDropdown}
                    />
                </div>
            </div>

            <div className="favorites-news-content">
                {filterList.map((
                    item,
                    idx
                ) => (
                    <CompetitionsCard
                        data={item}
                        key={idx}
                        handleOpenItem={(data) => {
                            setSelectData(data);

                            if (data.status === "finish") return setIsOpenModalCompletedSelect(true);

                            setIsOpenModalSelect(true);
                        }}
                    />
                ))}
            </div>

            <ContestsPageView
                isOpen={isOpenModalSelect}
                onClose={() => setIsOpenModalSelect(false)}
                onSuccess={handleSuccessModal}
                data={selectData}
            />

            <ContestsViewCompleted
                isOpen={isOpenModalCompletedSelect}
                onClose={() => setIsOpenModalCompletedSelect(false)}
                data={selectData}
            />

            <ContestsPageSuccess
                isOpen={isOpenSuccessModal}
                onClose={handleSuccessCloseModal}
            />
        </div>
    );
};

export default ContestsPage;