import { useState } from "react";
import { CalendarIcon, SliderBlogIcon, SliderFeedIcon, SliderServicesIcon } from "../../img";
import { SliderFlameIcon } from "../../img/icons/slider/SliderFlameIcon";
import { PercentageIcon } from "../../img/icons/PercentageIcon";
import { ShopIcon } from "../../img/icons/ShopIcon";
import { useNavigate } from "react-router-dom";

interface HorizonNavigateProps {
    isScroll?: boolean;
}

const HorizonNavigate = (
    {
        isScroll = false
    }: HorizonNavigateProps
) => {
    const navigate = useNavigate();

    const [ sliderRouters, setSliderRouters ] = useState([
        {
            id: 0,
            title: 'Лента',
            route: '/',
            icon: <SliderFeedIcon />
        },
        {
            id: 3,
            title: 'Календарь',
            route: '/calendar',
            icon: <CalendarIcon color={"currentColor"}/>
        },
        {
            id: 1,
            title: 'Спорт',
            route: '/sport',
            icon: <SliderFlameIcon color={"currentColor"}/>
        },
        {
            id: 10,
            title: 'Блог',
            route: '/blogs',
            icon: <SliderBlogIcon />
        },
        {
            id: 4,
            title: 'Витрина скидок',
            route: '/discount',
            icon: <PercentageIcon color={"currentColor"} opacity={1}/>
        },
        {
            id: 5,
            title: 'Корп. магазин',
            route: '/corp',
            icon: <ShopIcon color={"currentColor"} opacity={1}/>
        },
        {
            id: 6,
            title: 'Все сервисы',
            route: '/services',
            icon: <SliderServicesIcon />
        },
    ])

    return (
        <div className={`navigation ${isScroll ? 'scrolled' : ''}`}>
            {sliderRouters.map((
                router,
                idx
            ) => (
                <div
                    key={idx}
                    className={`navigation__router ${location.pathname === router.route ? 'active' : ''}`}
                    onClick={() => navigate(router.route)}
                >
                    {router?.icon}

                    <p>{router.title}</p>
                </div>
            ))}
        </div>
    )
}

export default HorizonNavigate;