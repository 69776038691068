import { PaperClipIcon } from "../../img";
import { CommentMessengerChatBox } from "./CommentMessengerChatBox";
import { FC } from "react";
import { ICommentsMessenger } from "../../api/requests/blogs/interface";

interface CommentMessengerPros {
    comments_data: ICommentsMessenger[]
}

export const CommentMessenger: FC<CommentMessengerPros> = ({comments_data}) => {

    return (
        <div className='news-messenger'>
            <div className='news-messenger__top'>
                <img className='news-messenger__top__avatar' src="/testImages/people/Image-1.png" alt="" />

                <div className='news-messenger__top__textarea'>
                    <input
                        type="text"
                        placeholder='Оставить комментарий'
                        className='news-messenger__top__input'
                    />

                    <div className='news-messenger__top__textarea__buttons'>
                        <PaperClipIcon/>

                        <div className='news-messenger__top__textarea__buttons__enterButton'>
                            Опубликовать
                        </div>
                    </div>
                </div>
            </div>

            <CommentMessengerChatBox comments_data={comments_data}/>
        </div>
    )
}