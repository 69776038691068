import DialogContainer from "../../ui/dialog/DialogContainer";
import { FC, useEffect, useState } from "react";
import { IPolls, IPolls2 } from "../../../api/requests/polls/interface";
import { AnalyticsDownloadIcon, TickIcon } from "../../../img";
import { useNavigate } from "react-router-dom";

interface IPollsQuestionsModal {
    data?: IPolls2,
    onClose?: () => void,
    isOpenModal: boolean,
    setData: any,
}

export const PollsQuestionsModal: FC<IPollsQuestionsModal> = ({
    data,
    onClose,
    isOpenModal = true,
    setData,
}) => {
    const navigate = useNavigate()
    // const [ currentPollIdx, setCurrentPollIdx ] = useState(0)
    // const [ currentPoll, setCurrentPoll ] = useState(data?.text[0])
    //
    // const [ selectedAnswer, setSelectedAnswer ] = useState<any[]>([])
    const [ isCommentOpened, setIsCommentOpened ] = useState(data?.extra)
    //
    // const getCurrentCheckboxes = selectedAnswer.find((item) => item.question_id === currentPoll?.question_id)
    //
    // const changeCurrentPoll = (
    //     item: any,
    //     idx: number
    // ) => {
    //     setCurrentPollIdx(idx)
    //     setCurrentPoll(item)
    // }
    //
    // const handleSetAnswer = (item: any) => {
    //     let checkbox: any;
    //     if (item?.id === getCurrentCheckboxes?.answer_id) {
    //         checkbox = {}
    //     } else {
    //         checkbox = item
    //     }
    //
    //     const existingIndex: any = currentPoll && selectedAnswer.findIndex((item: any) => item.question_id === currentPoll?.question_id);
    //     let updatedAnswers;
    //
    //     if (existingIndex >= 0) {
    //         updatedAnswers = [ ...selectedAnswer ];
    //         updatedAnswers[existingIndex].answer_id = checkbox.id;
    //     } else {
    //         updatedAnswers = [ ...selectedAnswer, {question_id: currentPoll?.question_id, answer_id: checkbox.id} ];
    //     }
    //
    //     setSelectedAnswer(updatedAnswers)
    // }
    //

    const handleChangeValue = (id: number, value: boolean) => {
        const updatedValue = data?.answers?.map((item, idx) =>
            id === idx ? {...item, value} : {...item, value: false}
        )

        setData({...data, answers: updatedValue})
    }

    const handleOpenComment = () => {

        setIsCommentOpened(!isCommentOpened)
    }
    //
    // useEffect(() => {
    //     setCurrentPoll(data?.text[0])
    // }, [data])

    return (
        <DialogContainer classNameHeader='polls-modal__header' isOpen={ isOpenModal } closeModal={ onClose }>
            { data && (
                <div className='polls-modal'>
                    <img src={ data?.image } alt="" className='polls-modal__image' />

                    <div className='polls-modal__content'>
                        <div className='polls-modal__circles'>
                            {data.answers.map((item, idx) => (
                                <div
                                    key={idx}
                                    className={`polls-modal__circles-item ${ idx === 0 ? 'last' : '' }`}
                                >
                                    <div
                                        className='polls-modal__circles-item-line'>

                                    </div>

                                    <div
                                        className='polls-modal__circles-item-circle'>
                                        { idx < 1 && <TickIcon size={ 6 } /> }
                                    </div>
                                </div>
                            ))}
                        </div>

                        <div className='polls-modal-question'>
                            <h1 className='polls-modal-question__title'>{ data.title }</h1>

                            <div className='polls-modal-question__content'>
                                { data.answers.map((
                                    item,
                                    idx
                                ) => (
                                    <div className='polls-modal-question__block' key={ idx }>
                                        { item.personal_answer ? (
                                            <>
                                                <input
                                                    type="checkbox"
                                                    checked={ isCommentOpened }
                                                    onChange={ handleOpenComment }
                                                />

                                                <p>Другое</p>
                                            </>
                                        ) : (
                                            <>
                                                <input
                                                    type="checkbox"
                                                    checked={ item.value }
                                                    onChange={ () => handleChangeValue(idx, !item.value) }
                                                />

                                                <p>{ item.title }</p>
                                            </>
                                        ) }
                                    </div>
                                )) }
                            </div>

                            { isCommentOpened ? (
                                <div className='polls-modal-question__comment'>
                                    <h1 className='polls-modal-question__comment__label'>Ваш ответ</h1>

                                    <input type="text" className='polls-modal-question__comment__input' />
                                </div>
                            ) : '' }

                            <div className='polls-modal-question__buttons'>
                                <div className='polls-modal-question__button'>
                                    Ответить
                                </div>

                                <div
                                    onClick={() => navigate(`/polls/edit?poll_id=${data.id}`)}
                                    className='polls-modal-question__button polls-modal-question__button__edit'>
                                    Редактировать
                                </div>

                                <div className='polls-modal-question__button polls-modal-question__button__edit'>
                                    <AnalyticsDownloadIcon color='#00A7B5'/>
                                    Посмотреть ответы
                                </div>
                            </div>
                        </div>
                    </div>

                    {/*<div className='polls-modal__content'>*/}
                    {/*    <div className='polls-modal__circles'>*/}
                    {/*        { data.text.map((*/}
                    {/*            item,*/}
                    {/*            idx*/}
                    {/*        ) => (*/}
                    {/*            <div key={ idx }*/}
                    {/*                 className={ `polls-modal__circles-item ${ idx === 0 ? 'last' : '' } ${ idx <= currentPollIdx ? 'active' : '' }`*/}
                    {/*                 }*/}
                    {/*            >*/}
                    {/*                <div*/}
                    {/*                    className='polls-modal__circles-item-line'>*/}
                    
                    {/*                </div>*/}
                    
                    {/*                <div onClick={ () => changeCurrentPoll(item, idx) }*/}
                    {/*                     className='polls-modal__circles-item-circle'>*/}
                    {/*                    { idx < currentPollIdx && <TickIcon size={ 6 } /> }*/}
                    {/*                </div>*/}
                    {/*            </div>*/}
                    {/*        )) }*/}
                    {/*    </div>*/}
                    
                        {/*<div className='polls-modal-question'>*/}
                        {/*    { currentPoll && (*/}
                        {/*        <>*/}
                        {/*            <h1 className='polls-modal-question__title'>{ currentPoll.text }</h1>*/}
                        
                                    {/*<div className='polls-modal-question__content'>*/}
                                    {/*    { currentPoll.answers.map((*/}
                                    {/*        item,*/}
                                    {/*        idx*/}
                                    {/*    ) => (*/}
                                    {/*        <div className='polls-modal-question__block' key={ idx }>*/}
                                    {/*            { item.personal_answer ? (*/}
                                    {/*                <>*/}
                                    {/*                    <input*/}
                                    {/*                        type="checkbox"*/}
                                    {/*                        checked={ isCommentOpened }*/}
                                    {/*                        onChange={ handleOpenComment }*/}
                                    {/*                    />*/}

                                    {/*                    <p>Другое</p>*/}
                                    {/*                </>*/}
                                    {/*            ) : (*/}
                                    {/*                <>*/}
                                    {/*                    <input*/}
                                    {/*                        type="checkbox"*/}
                                    {/*                        checked={ item?.id === getCurrentCheckboxes?.answer_id }*/}
                                    {/*                        onChange={ () => handleSetAnswer(item?.id === getCurrentCheckboxes?.answer_id ? {} : item) }*/}
                                    {/*                    />*/}

                                    {/*                    <p>{ item.text }</p>*/}
                                    {/*                </>*/}
                                    {/*            ) }*/}
                                    {/*        </div>*/}
                                    {/*    )) }*/}
                                    {/*</div>*/}
                        {/*        </>*/}
                        {/*    ) }*/}
                    
                            {/*{ isCommentOpened ? (*/}
                            {/*    <div className='polls-modal-question__comment'>*/}
                            {/*        <h1 className='polls-modal-question__comment__label'>Ваш ответ</h1>*/}

                            {/*        <input type="text" className='polls-modal-question__comment__input' />*/}
                            {/*    </div>*/}
                            {/*) : '' }*/}
                    
                            {/*<div className='polls-modal-question__button'>*/}
                            {/*    Ответить*/}
                            {/*</div>*/}
                    {/*    </div>*/}
                    {/*</div>*/}
                </div>
            ) }
        </DialogContainer>
    )
}