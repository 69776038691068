import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { $getFavoritesNews } from "../../../api/requests/news";
import { ArrowExitIcon } from "../../../img";
import { CompetitionsCard } from "../../../components/competitions";

export const FavoritesCompetitions = () => {
    const navigate = useNavigate();
    const isMobile = window.screen.width < 1020

    const [ newsData, setNewsData ] = useState([]);

    const handleNavigation = () => {
        const pathArray = location.pathname.split('/');
        if (pathArray.length > 1) {
            pathArray.pop();
            const newPath = pathArray.join('/') || '/';
            navigate(newPath);
        } else {
            navigate('/');
        }
    };

    useEffect(() => {
        $getFavoritesNews().then(res => {
            if (!res.data) return

            setNewsData(res.data)
        })
    }, [])

    return (
        <div className='favorites-news'>
            <div className='layout-inner__header profile-header'>
                { !isMobile && (
                    <div className='layout-inner__header__title'>
                        <div className='header__exitIcon' onClick={ handleNavigation }>
                            <ArrowExitIcon />
                        </div>
                        <h1>Конкурсы</h1>
                    </div>
                ) }
            </div>

            <div className='favorites-news-content'>
                { newsData.slice(0, 3).map((
                    item,
                    idx
                ) => (
                    <CompetitionsCard data={ item } key={ idx } isLikeIcon />
                )) }
            </div>
        </div>
    )
}