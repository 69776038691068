import { IBlogsRes } from "../../api/requests/blogs/interface";
import { FC } from "react";
import TextOverflow from "../../utils/text-overflow/TextOverflow";

interface IBlogsDraftCard {
    data: IBlogsRes
}

export const BlogsDraftCard: FC<IBlogsDraftCard> = ({
    data
}) => {
    return (
        <div className='blogs-drafts-card'>
            <img src={ data?.image } alt="" className='blogs-drafts-card__image' />

            <div className='blogs-drafts-card__content'>
                <h1 className='blogs-drafts-card__title'>{ data?.title }</h1>

                { data.category ? (
                    <div className='blogs-drafts-card__category' >
                        { data.category }
                    </div>
                ) : null }

                <p className='blogs-drafts-card__text'>
                    {TextOverflow({title: data.text, lines: data?.category ? 4 : 6})}
                </p>

            </div>
        </div>
    )
}