import React, { FC } from 'react'
import { IBaseIconProps } from "../types"

export const ShareIcon: FC<IBaseIconProps> = ({
    size = 25,
    color = '#fff',
}) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={size}
            height={size}
            viewBox="0 0 25 24"
            fill="none"
        >
            <path
                d="M18.5 22C19.2956 22 20.0587 21.6839 20.6213 21.1213C21.1839 20.5587 21.5 19.7956 21.5 19C21.5 18.2044 21.1839 17.4413 20.6213 16.8787C20.0587 16.3161 19.2956 16 18.5 16C17.7044 16 16.9413 16.3161 16.3787 16.8787C15.8161 17.4413 15.5 18.2044 15.5 19C15.5 19.7956 15.8161 20.5587 16.3787 21.1213C16.9413 21.6839 17.7044 22 18.5 22ZM18.5 8C19.2956 8 20.0587 7.68393 20.6213 7.12132C21.1839 6.55871 21.5 5.79565 21.5 5C21.5 4.20435 21.1839 3.44129 20.6213 2.87868C20.0587 2.31607 19.2956 2 18.5 2C17.7044 2 16.9413 2.31607 16.3787 2.87868C15.8161 3.44129 15.5 4.20435 15.5 5C15.5 5.79565 15.8161 6.55871 16.3787 7.12132C16.9413 7.68393 17.7044 8 18.5 8ZM6.5 15C7.29565 15 8.05871 14.6839 8.62132 14.1213C9.18393 13.5587 9.5 12.7956 9.5 12C9.5 11.2044 9.18393 10.4413 8.62132 9.87868C8.05871 9.31607 7.29565 9 6.5 9C5.70435 9 4.94129 9.31607 4.37868 9.87868C3.81607 10.4413 3.5 11.2044 3.5 12C3.5 12.7956 3.81607 13.5587 4.37868 14.1213C4.94129 14.6839 5.70435 15 6.5 15V15Z"
                stroke={color}
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M16 6.5L9 10.5M9 13.5L16 17.5"
                stroke={color}
                strokeWidth="2"
            />
        </svg>
    )
}
