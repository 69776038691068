import { FC, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
    changeMainPage,
    getMainPageData,
    changeProfile,
    changeProfileVarsData,
    getProfileData
} from "../redux";
import { $profileData } from "../api/requests/auth";
import Cookies from "js-cookie";
import { $mainPageNewData } from "../api/requests/main";
import Preloader from "../components/ui/preloader/Preloader";
import HeaderHome from "../components/header/HeaderHome";
import AsideHome from "../components/header/AsideHome";
import FooterHome from "../components/header/FooterHome";
import { Navigate, Outlet, useLocation } from "react-router-dom";
import ScrollToTop from "../utils/scroll/ScrollToTop";
import { Slider } from "../components/slider/Slider";

interface IMainLayout {
    isScrolled: boolean
}
const MainLayout: FC<IMainLayout> = ({isScrolled}) => {
    const location = useLocation()
    const mainData = useSelector(getMainPageData);
    const profileData = useSelector(getProfileData);
    const dispatch = useDispatch();

    const [scroll, setScroll] = useState<any>(false);

    const listMenu: any[] = [
        {
            img: "/images/icons/home.svg",
            text: "Главная страница",
            key: "home",
            count: 0,
            active: true,
            link: "/"
        },
        {
            img: "/images/icons/percentage2.svg",
            text: "Витрина скидок",
            key: "percent",
            count: 156,
            active: false,
            backColorCount: "#FA1919",
            colorCount: "#FFFFFF",
            link: "/"
        },
        {
            img: "/images/icons/chat_bubble.svg",
            text: "Мессенджер",
            key: "message",
            count: 10,
            active: false,
            backColorCount: "#1958FA",
            colorCount: "#FFFFFF",
            link: "/"
        },
        {
            img: "/images/icons/facebook_like.svg",
            text: "Благодарности",
            key: "urls",
            count: 2,
            active: false,
            backColorCount: "#FFD600",
            colorCount: "#000000",
            link: "/"
        },
        {
            img: "/images/icons/news.svg",
            text: "Новости",
            key: "news",
            count: 5,
            active: false,
            backColorCount: "#C7EF71",
            colorCount: "#000000",
            link: "/"
        },
        {
            img: "/images/icons/megaphone.svg",
            text: "Конкурсы",
            key: "news",
            active: false,
            count: 0,
            link: "/"
        },
        {
            img: "/images/icons/check_all.svg",
            text: "Опросы",
            key: "news",
            active: false,
            count: 0,
            link: "/"
        },
        {
            img: "/images/icons/chart.svg",
            text: "Мои траты",
            key: "news",
            active: false,
            count: 0,
            link: "/"
        },
        {
            img: "/images/icons/user.svg",
            text: "Профиль",
            key: "news",
            active: false,
            count: 0,
            link: "/"
        }
    ];

    const [isOpenMenu, setIsOpenMenu] = useState(false);

    const groupAdminModule =
        profileData?.group_admin_module?.filter(Boolean).length > 0;
    const isOwner =
        (+profileData?.workspace_role === 1 ||
            +profileData?.group_role === 1 ||
            +profileData?.group_role === 2) &&
        !groupAdminModule;

    const defaultColorScheme = {
        primaryColor: "#003166", //blueColor
        secondaryColor: "#007AFF", //lightBlue
        thirdColor: "#E9F0F9", //grayColor
        fourthColor: "#FFF" //whiteColor
    };

    if (!Cookies.get("token")) {
        return <Navigate to={"/auth"} replace />;
    }

    useEffect(() => {
        if (profileData) return;

        $profileData().then((res) => {
            console.log(res, "$profileData");
            dispatch(
                changeProfileVarsData({
                    newMain: !!+res?.main_page_new,
                    newProfile: !!+res?.profile_new,
                    colors: res.workspace_design
                        ? JSON.parse(res.workspace_design)
                        : defaultColorScheme,
                    isOwner
                })
            );
            dispatch(changeProfile(res));
        });
    }, []);

    useEffect(() => {
        if (mainData) return;

        $mainPageNewData().then((res) => {
            console.log(res);
            dispatch(changeMainPage(res));
        });
    }, []);



    useEffect(() => {
        const handleScroll = () => {
            const slider = document.getElementById("sliderRouters");

            if (!slider) return;

            const sliderRect = slider.getBoundingClientRect();

            setScroll(sliderRect.top <= 80);
        };

        window.addEventListener("scroll", handleScroll);

        return () => {
            window.removeEventListener("scroll", handleScroll);
        };
    }, []);

    useEffect(() => {
        if (document.body) {
            const body = document.body;
            body.scrollTo(1000, 1000);
        }
    }, []);

    function openMenu(): void {
        setIsOpenMenu(!isOpenMenu);
    }

    function onClickMenuFooter(i: any): void {
        if (i.key === "navigation") openMenu();
    }

    if (!profileData || !mainData)
        return (
            <div
                className={"w-100"}
                style={{
                    height: "100vh",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center"
                }}
            >
                <Preloader size={"large"} />
            </div>
        );

    return (
        <>
            <HeaderHome openMenu={openMenu} />

            <Slider isScrolled={scroll} />

            <AsideHome
                isActive={isOpenMenu}
                openMenu={openMenu}
                menu={listMenu}
            />

            <div className={`main ${isScrolled ? 'main-scrolled' : ''}`}><Outlet /></div>

            <FooterHome
                isOpenMenu={isOpenMenu}
                openMenu={openMenu}
                listMenu={listMenu}
                onClickMenuFooter={onClickMenuFooter}
            />

            <ScrollToTop />
        </>
    );
};

export default MainLayout;
