import { IApiHandlerParams } from "../../interface";
import api from "../../index";
import { SportResponseData } from "./interface";

export const $getSportList = (
    handler?: IApiHandlerParams
): Promise<SportResponseData> => api
    .get( `api/v1/sport`, {
        mock: require('./mocks/sport.list.json'),
        handler,
    })