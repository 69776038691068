import React, { FC } from 'react'
import { IBaseIconProps } from "../types"

export const MessengerIcon: FC<IBaseIconProps> = ({
    color = '#00A7B5',
    size = 24,
}) => {
    return (
        <svg width={size} height={size} viewBox="0 0 24 24" fill="none"
             xmlns="http://www.w3.org/2000/svg">
            <path
                d="M12.5 2C6.986 2 2.5 6.038 2.5 11C2.5 16.259 7.54 20.508 13.5 19.953V23.093L15.04 22.105C16.172 21.379 21.847 17.477 22.443 11.955C22.48 11.641 22.5 11.322 22.5 11C22.5 6.038 18.014 2 12.5 2ZM9.5 12H7.5V10H9.5V12ZM13.5 12H11.5V10H13.5V12ZM17.5 12H15.5V10H17.5V12Z"
                fill={color}
            />
        </svg>
    )
}
