import React, { FC } from "react";

import {
    MenuIcon,
    SearchIcon,
    NotificationIcon, ArrowExitIcon
} from "../../img";
import { isMd } from "../../utils";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { iconConfig } from "../../routes/routeLinks";
import Button from "../ui/form/Buttons/Button";

interface HeaderHomeProps {
    openMenu: () => void;
    title?: string,
    is_drafted?: boolean,
}

const HeaderHome: FC<HeaderHomeProps> = ({openMenu, title, is_drafted}) => {
    const isMobile: boolean = window.screen.width < 1020;
    const navigate = useNavigate();
    const location = useLocation();
    const pathname = location.pathname;
    const searchParams = new URLSearchParams(location.search);

    const currentIcons = iconConfig(navigate, searchParams)[location.pathname] || {
        leftIcons: [
            {
                icon: <ArrowExitIcon />,
                onClick: () => navigate(-1),
            },
        ],
        rightIcons: [
            {
                icon: <SearchIcon color="#000" />,
                onClick: () => {}//store.dispatch(toggleSearchModal()),
            },
            {
                icon: <NotificationIcon color={"#000"} />,
                onClick: () => console.log("FilterEyeIcon clicked"),
            },

        ],
    };

    const handleNavigation = () => {
        const pathArray = location.pathname.split('/');
        if (pathArray.length > 1) {
            pathArray.pop();
            const newPath = pathArray.join('/') || '/';
            navigate(newPath);
        } else {
            navigate('/');
        }
    };

    return (
        <header className={ "header" }>
            <div className="header__items">
                <div className={ "container" }>
                    <div className={ "header__item" }>
                        { isMobile && (
                            <>
                                { title ?
                                    (
                                        <div className='header__exitIcon' onClick={handleNavigation}>
                                            <ArrowExitIcon />
                                        </div>
                                    )
                                    :
                                    (
                                        <img onClick={() => navigate('profile')} src={ '/testImages/people/Image-1.png' } className='header__profileIcon' />
                                    )
                                }
                            </>
                        ) }

                        <div className={ "header__left" }>
                            { !isMobile && (
                                <button
                                    type={ "button" }
                                    className={ "header__menu" }
                                    onClick={ openMenu }
                                >
                                    <MenuIcon color={ "#000" } />
                                </button>
                            ) }

                            {isMobile && title ? (
                                <div className='header-center__title'>
                                    { title }
                                </div>
                            ) : (
                                <Link className={ "header__logo" } to={ "/" }>
                                    <img
                                        src="/images/logo.png"
                                        alt="logo"
                                    />
                                </Link>
                            ) }

                            {/*{currentIcons.leftIcons.map((iconObj, index) => (*/}
                            {/*    <div*/}
                            {/*        key={index}*/}
                            {/*        className="w-[24px] h-[24px] flex items-center justify-center cursor-pointer"*/}
                            {/*        onClick={iconObj.onClick}*/}
                            {/*    >*/}
                            {/*        {iconObj.icon}*/}
                            {/*    </div>*/}
                            {/*))}*/}
                        </div>

                        <div className={ "header__right" }>
                            <div className={"header__other"}>
                                {currentIcons.rightIcons.map((iconObj, index) => (
                                    <Button
                                        key={index}
                                        text={iconObj.icon}
                                        className="btn btn-icon p-0"
                                        onClick={iconObj.onClick}
                                    />
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </header>
    );
};

export default HeaderHome;
