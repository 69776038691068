export const ThreePeopleBorderIcon = (
    {
        color = "#00A7B5"
    }
) => {
    return (
        <svg
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M5.83203 15V14.1667C5.83203 11.8655 7.69751 10 9.9987 10C12.2999 10 14.1654 11.8655 14.1654 14.1667V15"
                stroke={color}
                strokeWidth="1.66667"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path d="M0.832031 14.9998V14.1665C0.832031 12.7858 1.95132 11.6665 3.33203 11.6665"
                  stroke={color}
                  strokeWidth="1.66667"
                  strokeLinecap="round"
                  strokeLinejoin="round"
            />
            <path d="M19.168 14.9998V14.1665C19.168 12.7858 18.0487 11.6665 16.668 11.6665"
                  stroke={color}
                  strokeWidth="1.66667"
                  strokeLinecap="round"
                  strokeLinejoin="round"
            />
            <path
                d="M10 10C11.3807 10 12.5 8.88075 12.5 7.5C12.5 6.11929 11.3807 5 10 5C8.61925 5 7.5 6.11929 7.5 7.5C7.5 8.88075 8.61925 10 10 10Z"
                stroke={color}
                strokeWidth="1.66667"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M3.33464 11.6668C4.25511 11.6668 5.0013 10.9207 5.0013 10.0002C5.0013 9.07966 4.25511 8.3335 3.33464 8.3335C2.41416 8.3335 1.66797 9.07966 1.66797 10.0002C1.66797 10.9207 2.41416 11.6668 3.33464 11.6668Z"
                stroke={color}
                strokeWidth="1.66667"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M16.6667 11.6668C17.5872 11.6668 18.3333 10.9207 18.3333 10.0002C18.3333 9.07966 17.5872 8.3335 16.6667 8.3335C15.7462 8.3335 15 9.07966 15 10.0002C15 10.9207 15.7462 11.6668 16.6667 11.6668Z"
                stroke={color}
                strokeWidth="1.66667"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    )
}