import { IBlogsRes } from "../../api/requests/blogs/interface";
import { FC } from "react";
import { CommentIcon, EyeIcon, LikeIcon } from "../../img";
import { ClockIcon } from "@mui/x-date-pickers";

interface IBlogsShowCard {
    data: IBlogsRes;
}

export const BlogsShowPageCard: FC<IBlogsShowCard> = ({
    data
}) => {
    return (
        <div className='blogs-show-card'>
            <img src={data?.image} alt="" className='blogs-show-card__image'/>

            <div className='blogs-show-card__content'>
                <h1 className='blogs-show-card__title'>{data?.title}</h1>

                {data?.category ? (
                    <div className='blogs-show-card__category'>
                        {data?.category}
                    </div>
                ): null}

                <div className='blogs-show-card__bottom'>
                    <div className='blogs-show-card__date'>
                        <ClockIcon />

                        <p>{data?.date}</p>
                    </div>

                    <div className='blogs-show-card__stats'>
                        <div className='blogs-show-card__stats__block'>
                            <EyeIcon color='#000'/>

                            <p>{data?.views_count}</p>
                        </div>

                        <div className='blogs-show-card__stats__block'>
                            <CommentIcon color='#000'/>

                            <p>{data?.comments_count}</p>
                        </div>

                        <div className='blogs-show-card__stats__block'>
                            <LikeIcon color='#000'/>

                            <p>{data?.likes_count}</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}