import { IBlogsRes } from "../../api/requests/blogs/interface";
import { FC } from "react";
import { CommentIcon, EyeIcon, HeartIcon, LikeIcon } from "../../img";
import { ClockIcon } from "@mui/x-date-pickers";
import { useNavigate } from "react-router-dom";

interface IBlogsCard {
    data: IBlogsRes
}

export const BlogsCard: FC<IBlogsCard> = ({
    data
}) => {
    const navigate = useNavigate();
    return (
        <div className='blogs-card'>
            <div className='blogs-card-user'>
                <img src={ data?.user?.image } alt="" className='blogs-card-user__image' />

                <div className='blogs-card-user__content'>
                    <h1 className='blogs-card-user__content__name'>{ data?.user?.name }</h1>
                    <p className='blogs-card-user__content__position'>{ data?.user?.position }</p>
                </div>
            </div>

            <div className='blogs-card-block'>
                <img src={ data?.image } alt="" className='blogs-card-block__image' />

                <div className='blogs-card-block-content'>
                    <div className='blogs-card-block-content__top'>
                        <h1 className='blogs-card-block-content__title'
                            onClick={ () => navigate(`/blogs/show?blogs_id=${ data.id }`) }
                        >
                            { data?.title }
                        </h1>

                        <div
                            // onClick={ () => {
                            //     handleLike(item.id)
                            // } }
                            className={ `blogs-card-block-content__likeIcon ${ data.is_liked ? 'active' : '' }` }
                        >
                            <HeartIcon />
                        </div>
                    </div>

                    <div className='blogs-card-block-content__category'>
                        { data?.category }
                    </div>

                    <div className='blogs-card-block-content__text'>
                        { data?.text }
                    </div>

                    <div className='blogs-card-block-content__bottom'>
                        <div className='blogs-card-block-content__bottom__block '>
                            <ClockIcon />

                            <p>{ data?.date }</p>
                        </div>

                        <div className='blogs-card-block-content__bottom__right'>
                            <div
                                className='blogs-card-block-content__bottom__block blogs-card-block-content__bottom__likeContent'>
                                <LikeIcon color='#000' />

                                <p>{ data?.likes_count }</p>

                            </div>

                            <div className='blogs-card-block-content__bottom__block '>
                                <CommentIcon color='#000' />

                                <p>{ data?.comments_count }</p>
                            </div>

                            <div className='blogs-card-block-content__bottom__block '>
                                <EyeIcon color='#000' />

                                <p>{ data?.views_count }</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}