import { FC } from "react";
import { ContestApplicationUsersItem } from "../../api/requests/contest/interface";

interface ICompetitionsApplicationCard {
    data: ContestApplicationUsersItem;
    handleOpenItem?: (data: ContestApplicationUsersItem) => void;
}

export const CompetitionsApplicationCard: FC<ICompetitionsApplicationCard> = (
    {
        data,
        handleOpenItem,
    }
) => {
    const {
        name,
        photo,
        status,
        position,
        desc
    } = data;

    const statusList = [
        {
            key: "new",
            value: "Новая"
        },
        {
            key: "accept",
            value: "Рассмотрена"
        },
        {
            key: "reject",
            value: "Отклонена"
        }
    ];

    const statusFind = statusList.find(item => item.key === (status || ""));

    return (
        <div className={`contests__application__card ${handleOpenItem ? "cursor-pointer" : ""}`} onClick={() => handleOpenItem && handleOpenItem(data)}>
            <div className={"contests__application__card__header"}>
                <div className="contests__application__card__header-left">
                    <img src={photo} />

                    <div className="contests__application__card__header-left-text">
                        <h4>{name}</h4>

                        <p>{position}</p>
                    </div>
                </div>

                {(handleOpenItem && status) && (
                    <div className={`contests__application__card__header-right ${statusFind?.key}`}>
                        <p>{statusFind?.value || "Неопределен"}</p>
                    </div>
                )}
            </div>

            <div className={`contests__application__card__content ${handleOpenItem ? "slice" : ""}`}>
                <p>{desc}</p>
            </div>
        </div>
    );
};