import { useEffect, useState } from "react";
import { IServices } from "../../api/requests/services/interface";
import { $getServices } from "../../api/requests/services";
import { useNavigate } from "react-router-dom";


export const ServicesPage = () => {
  const navigate = useNavigate();
  const [ servicesData, setServicesData ] = useState<IServices[]>([])

  useEffect(() => {
    $getServices().then(res => {
      if (!res.data) return

      setServicesData(res.data)
    })
  }, [])

  return (
    <div className='services'>
      <div className='services-header'>
        <h1 className='services-header__title'>Сервисы</h1>

        <div
          onClick={() => navigate('/services/create')}
          className='services-header__button'>
          Добавить
        </div>
      </div>

      <div className='services-content'>
        {servicesData.map((item, idx) => (
          <div className='services-block' key={idx}>
            <img src={item.image} alt="" className='services-block__image' />

            <h1 className='services-block__title'>{item.title}</h1>

            <div className='services-block__category'>
              {item.category}
            </div>
          </div>
        ))}
      </div>
    </div>
  )
}