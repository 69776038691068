import DialogContainer from "../../ui/dialog/DialogContainer";
import { FC, useEffect, useState } from "react";
import { CloseIcon, ServicesEmailIcon, ServicesFileIcon } from "../../../img";
import { Controller, useForm } from "react-hook-form";
import Dropdown from "../../ui/form/Dropdown/Dropdown";
import TextField from "../../ui/form/Fields/TextField";
import ToggleBlock from "../../ui/form/Toggle/ToggleBlock";
import { SecondToggleBlock } from "../../ui/form/Toggle/SecondToggleBlock";
import { IServicesStatuses } from "../../../api/requests/services/interface";

interface DropdownItem {
  label: string;
  value: number | string;
}

interface IServicesStatusesModal {
  isOpen: boolean;
  onClose: () => void;
  statusesData: IServicesStatuses[];
  editedData: IServicesStatuses;
  setStatusesData: any,
  setEditedData: any,
  purpose: string;
}

export const ServicesCreateStatusesModal: FC<IServicesStatusesModal> = ({
  isOpen,
  onClose,
  statusesData,
  setStatusesData,
  editedData,
  purpose,
  setEditedData
}) => {
  const [ data, setData ] = useState<any>()
  const {
    handleSubmit,
    control,
    watch,
    register,
    reset,
    formState: {isValid},
    setValue,
  } = useForm<any>(
    (purpose === 'edit' && editedData) ? {
      values: {
        mail_text: editedData?.mail_text,
        push_text: editedData?.push_text,
        checkboxes: {
          supervisor_notification_mail: editedData?.supervisor_notification_mail,
          supervisor_notification_push: editedData?.supervisor_notification_push,
          executors_notification_push: editedData?.executors_notification_mail,
          executors_notification_mail: editedData?.executors_notification_push,
          conciliator_notification_mail: editedData?.conciliator_notification_mail,
          conciliator_notification_push: editedData?.conciliator_notification_push
        }
      }
    } : {}
  );


  const [ categoryList, setCategoryList ] = useState<DropdownItem[]>([
    {
      label: "Спорт",
      value: 0
    },
    {
      label: "Инновации",
      value: 1
    },
    {
      label: "Творчество",
      value: 2
    }
  ]);

  const handleChangeCheckboxes = (onChange: any, values: IServicesStatuses, key: string, e: boolean) => {
    onChange({...values, [key]: e})
  }

  const handleClose = () => {
    setEditedData({})
    onClose()
  }

  const saveChange = () => {
    const updatedList = [ ...statusesData ].map((
        item,
        idx
      ) =>
        item.id === editedData?.id ? {
          ...item,
          mail_text: watch().mail_text,
          push_text: watch().push_text,
          supervisor_notification_mail: watch().checkboxes.supervisor_notification_mail,
          supervisor_notification_push: watch().checkboxes.supervisor_notification_push,
          executors_notification_push: watch().checkboxes.executors_notification_mail,
          executors_notification_mail: watch().checkboxes.executors_notification_push,
          conciliator_notification_mail: watch().checkboxes.conciliator_notification_mail,
          conciliator_notification_push: watch().checkboxes.conciliator_notification_push
        } : item
    )

    setStatusesData(updatedList)
    setEditedData({})
    onClose()
  }

  return (
    <DialogContainer
      isOpen={ isOpen }
      closeModal={ handleClose }
      classNameModal='services-create-modal services-create-modal-statuses'
      isShowCloseBtn={ false }
    >
      <div className='services-create-modal__header'>
        <h1 className='services-create-modal__header__title'>Уведомления</h1>

        <div className='services-create-modal__header__close' onClick={ handleClose }>
          <CloseIcon color='#000' />
        </div>
      </div>

      <div className='services-create-modal__content'>
        <form className="services-create-form">
          <Controller
            name="mail_text"
            control={ control }
            rules={ {
              required: {
                value: true,
                message: ""
              }
            } }
            render={ ({field: {value, onChange}}) => (
              <TextField
                value={ value }
                className=""
                title="Уведомление на почту"
                placeholder='Введите текст'
                name="title"
                onChange={ onChange }
              />
            ) }
          />

          <Controller
            name="push_text"
            control={ control }
            rules={ {
              required: {
                value: true,
                message: ""
              }
            } }
            render={ ({field: {value, onChange}}) => (
              <TextField
                value={ value }
                className=""
                title="Уведомление в Push"
                placeholder='Введите текст'
                name="title"
                onChange={ onChange }
              />
            ) }
          />

          <span className='services-create-form__line'></span>

          <Controller
            name='checkboxes'
            control={ control }
            rules={ {
              required: {
                value: true,
                message: ""
              }
            } }
            render={ ({field: {value, onChange}}) => {
              return (
                (
                  <SecondToggleBlock
                    desc='Отправить уведомление руководителю'
                    isCheckedFirst={ value?.supervisor_notification_push }
                    isCheckedSecond={ value?.supervisor_notification_mail}
                    handleChangeFirst={ (e) => handleChangeCheckboxes(onChange, value, 'supervisor_notification_push', e)}
                    handleChangeSecond={ (e) => handleChangeCheckboxes(onChange, value, 'supervisor_notification_mail', e) }
                    iconFirst={ <ServicesFileIcon /> }
                    iconSecond={ <ServicesEmailIcon /> }
                  />
                )
              )
            } }
          />

          <Controller
            name='checkboxes'
            control={ control }
            rules={ {
              required: {
                value: true,
                message: ""
              }
            } }
            render={ ({field: {value, onChange}}) => {
              return (
                (
                  <SecondToggleBlock
                    desc='Отправить уведомление исполнителям'
                    isCheckedFirst={ value?.executors_notification_push }
                    isCheckedSecond={ value?.executors_notification_mail}
                    handleChangeFirst={ (e) => handleChangeCheckboxes(onChange, value, 'executors_notification_push', e)}
                    handleChangeSecond={ (e) => handleChangeCheckboxes(onChange, value, 'executors_notification_mail', e) }
                    iconFirst={ <ServicesFileIcon /> }
                    iconSecond={ <ServicesEmailIcon /> }
                  />
                )
              )
            } }
          />

          <Controller
            name='checkboxes'
            control={ control }
            rules={ {
              required: {
                value: true,
                message: ""
              }
            } }
            render={ ({field: {value, onChange}}) => {
              return (
                (
                  <SecondToggleBlock
                    desc='Отправить уведомление согласователям'
                    isCheckedFirst={ value?.conciliator_notification_push }
                    isCheckedSecond={ value?.conciliator_notification_mail}
                    handleChangeFirst={ (e) => handleChangeCheckboxes(onChange, value, 'conciliator_notification_push', e)}
                    handleChangeSecond={ (e) => handleChangeCheckboxes(onChange, value, 'conciliator_notification_mail', e) }
                    iconFirst={ <ServicesFileIcon /> }
                    iconSecond={ <ServicesEmailIcon /> }
                  />
                )
              )
            } }
          />
        </form>
      </div>

      <div className='services-create-modal__buttons'>
        <div className='services-create-modal__buttons__item services-create-modal__buttons__add'
             onClick={saveChange}
        >
          Сохранить
        </div>
      </div>
    </DialogContainer>
  )
}