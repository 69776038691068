import moment from "moment";

import Button from "../../../components/ui/form/Buttons/Button";

import noImageBanner from "../../../img/noimage.png";
import { CoinCompetitionIcon, EyeIcon } from "../../../img";
import { ShareIcon } from "../../../img/icons/ShareIcon";
import { ThreePeopleBorderIcon } from "../../../img/icons/employees/ThreePeopleBorderIcon";
import { ClockIcon } from "../../../img/icons/ClockIcon";
import { CreateContestFormData } from "./index";

interface ContestCreateEditPreviewProps {
    data: CreateContestFormData;
    handleSubmit?: () => void;
    isHideBtn?: boolean;
}
const ContestCreateEditPreview = (
    {
        data,
        handleSubmit,
        isHideBtn
    }: ContestCreateEditPreviewProps
) => {
    const statusList = [
        {
            key: "active",
            value: "Активный"
        },
        {
            key: "finish",
            value: "Завершенный"
        },
        {
            key: "hide",
            value: "Скрытый"
        }
    ];

    const statusFind = statusList.find(item => item.key === (data.status || ""));

    return (
        <div className="contests__create__preview-items">
            <div className="contests__create__preview-img">
                <img src={data.image || noImageBanner} />

                {data.status && (
                    <div className={`feed-news-card__active ${statusFind?.key}`}>
                        <div className={`${statusFind?.key}`}></div>

                        <p>{statusFind?.value || "Неопределен"}</p>
                    </div>
                )}
            </div>

            <div className="contests__create__preview-item">
                <div className="contests__create__preview-item__header">
                    <div className={"d-flex align-items-center gap-3"}>
                        <h3>{data.name || "Название"}</h3>

                        <Button
                            text={<ShareIcon />}
                            className={"btn btn-icon"}
                            onClick={() => {
                            }}
                        />
                    </div>

                    {data.category && (
                        <div
                            className="contests__create__preview-item__header-category"
                        >
                            <div
                                className={"contests__create__preview-item__header-category-item"}>
                                {data.category?.label}
                            </div>
                        </div>
                    )}
                </div>

                <div className="contests__create__preview-item__content">
                    <p className={"contests__create__preview-item__content__text"}>{data.desc}</p>

                    {(!!data.points_participation || !!data.points_win) && (
                        <div
                            className="contests__create__preview-item__awards">
                            <h4>Награды</h4>

                            <div className="d-flex gap-3">
                                <div className="w-50 block">
                                    <p className={"title"}>За участие</p>

                                    <div className={"d-flex align-items-center gap-3"}>
                                        <CoinCompetitionIcon />

                                        <p>{data.points_participation || 0}</p>
                                    </div>
                                </div>

                                <div className="w-50 block">
                                    <p className={"title"}>За победу</p>

                                    <div className={"d-flex align-items-center gap-3"}>
                                        <CoinCompetitionIcon />

                                        <p>{data.points_win || 0}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}

                    <div className="contests__create__preview-item__footer">
                        <div className={"date"}>
                            <ClockIcon />

                            <p>До {data.date_end ? moment(data.date_end).format('DD.MM.yyyy') : "..."}</p>
                        </div>

                        <div className={"view"}>
                            <div className={"d-flex"}>
                                <EyeIcon />

                                <p>{data.views || 0}</p>
                            </div>

                            <div className={"d-flex"}>
                                <ThreePeopleBorderIcon />

                                <p>{data.peoples || 0}</p>
                            </div>
                        </div>
                    </div>

                    {!isHideBtn && (
                        <div className="contests__create__preview-item__btns">
                            <Button
                                text={"Подать заявку"}
                                className={"btn btn-primary w-100"}
                                onClick={() => handleSubmit && handleSubmit()}
                            />
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};

export default ContestCreateEditPreview;