export const StatusIconSuccess = () => {
  return (
    <svg width='24' height='24' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='m7 12.5 3 3 7-7'
        stroke='currentColor'
        strokeWidth='2'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M12 22c5.523 0 10-4.477 10-10S17.523 2 12 2 2 6.477 2 12s4.477 10 10 10Z'
        stroke='currentColor'
        strokeWidth='2'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
};
