export const SliderBlogIcon = () => {
    return (
        <svg width="25" height="24" viewBox="0 0 25 24" fill="none"
             xmlns="http://www.w3.org/2000/svg">
            <path d="M6.5 6H18.5" stroke="currentColor" strokeWidth="2" strokeLinecap="round"
                  strokeLinejoin="round" />
            <path d="M6.5 10H18.5" stroke="currentColor" strokeWidth="2" strokeLinecap="round"
                  strokeLinejoin="round" />
            <path d="M13.5 14H18.5" stroke="currentColor" strokeWidth="2" strokeLinecap="round"
                  strokeLinejoin="round" />
            <path d="M13.5 18H18.5" stroke="currentColor" strokeWidth="2" strokeLinecap="round"
                  strokeLinejoin="round" />
            <path
                d="M2.5 21.4V2.6C2.5 2.26863 2.76863 2 3.1 2H21.9C22.2314 2 22.5 2.26863 22.5 2.6V21.4C22.5 21.7314 22.2314 22 21.9 22H3.1C2.76863 22 2.5 21.7314 2.5 21.4Z"
                stroke="currentColor" strokeWidth="2" strokeLinecap="round"
                strokeLinejoin="round" />
            <path d="M6.5 18V14H9.5V18H6.5Z" stroke="currentColor" strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round" />
        </svg>
    );
};