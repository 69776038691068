import { IApiHandlerParams } from "../../interface";
import api from "../../index";
import { IEmployees } from "./interface";

export const $getEmployees = (
    handler?: IApiHandlerParams
): Promise<any> => api
    .get( `api/v1/booking/vacations/users`, {
        mock: require('./mocks/employees.favorites.json'),
        handler,
    })